import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

import { getPendingEditRequests } from "store/application/EditProfile";
import { useAppDispatch, useAppSelector } from "store";
import EditProfileFormView from "../EditProfileFormView";
import EditProfileImagesView from "../EditProfileImagesView";

const PageContent = () => {
  const dispatch = useAppDispatch();
  const [pendingEditRequests, setPendingEditRequests] = useState([]);
  const { authAvatar, authProfile } = useAppSelector(
    (state) => state.application,
  );

  useEffect(() => {
    dispatch(getPendingEditRequests()).then((response) => {
      const payload = response.payload as Record<string, any>;
      const editRequests = payload.data || [];
      setPendingEditRequests(editRequests);
    });
  }, []);

  if (authProfile === null) {
    return (
      <Grid container direction="row">
        <Grid item>Error Occurred, Try Again...!</Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="column" alignItems="stretch" bgcolor="#F2F2F2">
      {pendingEditRequests.length !== 0 && (
        <Grid
          item
          paddingX={1}
          paddingY={1}
          margin={1}
          bgcolor="#ffb74d"
          borderRadius={2}
        >
          <Typography variant="caption">
            Some Profile Changes (eg: photoes) will need to be reviewed before
            they can be updated. These items will be disabled until the changes
            are reviewed and applied. Please allow at least 24 hours for changes
            to be accepted.
          </Typography>
        </Grid>
      )}
      <Grid item>
        <EditProfileFormView
          avatar={authAvatar || undefined}
          profile={authProfile}
        />
      </Grid>
      {authProfile !== null && (
        <Grid item marginTop={5}>
          <EditProfileImagesView profile={authProfile} />
        </Grid>
      )}
    </Grid>
  );
};

export default PageContent;
