/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from "store";
import { toast } from "react-toastify";

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const { getState } = store;
    const { auth } = getState();
    const { token } = auth;

    config.headers = {
      ...config.headers,
      Accept: "application/json",
    };

    if (token && token !== "") {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    if (error.message === "Network Error") {
      toast.error("A network error has occurred. Please try again!");
    }
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.message === "Network Error") {
      toast.error("A network error has occurred. Please try again!");
    }
    return Promise.reject(error);
  },
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const responseFull = <T>(response: AxiosResponse<T>) => response;

export const request = {
  get: <T>(url: string, headers?: any) =>
    instance.get<T>(url, headers).then(responseBody),

  getCustom: <T>(url: string, headers?: any) =>
    instance.get<T>(url, headers).then(responseFull),

  post: <T>(
    url: string,
    body: {},
    config?: AxiosRequestConfig<any> | undefined,
  ) => instance.post<T>(url, body, config).then(responseBody),

  postCustom: <T>(
    url: string,
    body: {},
    config?: AxiosRequestConfig<any> | undefined,
  ) => instance.post<T>(url, body, config).then(responseFull),

  put: <T>(url: string, body: {}) =>
    instance.put<T>(url, body).then(responseBody),
};
