import { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Select,
  PlacesAutocomplete,
  BreadcrumbItem,
} from "components";
import { CreateFlowScreen, GoogleMapsAPIKey } from "models/enum";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { setCurrentScreen, setProfileDetails } from "store/features/CreateFlow";
import agent from "services";
import { getAllCountries, getDetailedCountries } from "store/MasterData";
import { BiHome } from "react-icons/bi";
import "./index.scss";

export const PersonalLocation = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    residentCountryError: false,
    residentOtherCountryError: false,
    residentCityError: false,
    residentCountryErrorMsg: "",
    residentOtherCountryErrorMsg: "",
    residentCityErrorMsg: "",
  });

  const profile = useAppSelector(
    (state: RootState) => state.createFlow.profile,
  );
  const locationData = useAppSelector(
    (state: RootState) => state.masterData.location,
  );

  const handleOnSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: string,
  ) => {
    dispatch(setProfileDetails({ [prop]: event.target.value }));
    dispatch(
      setProfileDetails({
        resident_city: "",
        resident_city_lat: 0,
        resident_city_lon: 0,
      }),
    );
  };

  const handleCity = (data: any) => {
    const d = data;
    console.log(data);
    console.log(d.region);

    dispatch(
      setProfileDetails({
        resident_city: data.locationName,
        resident_city_lat: data.geoData.lat,
        resident_city_lon: data.geoData.lng,
        region_id: data.region,
      }),
    );
  };

  const handleBack = () => {
    dispatch(setCurrentScreen(CreateFlowScreen.HABBITS));
  };

  const validate = () => {
    const status = {
      residentCountryError: false,
      residentOtherCountryError: false,
      residentCityError: false,
      residentCountryErrorMsg: "",
      residentOtherCountryErrorMsg: "",
      residentCityErrorMsg: "",
    };

    if (profile.resident_country === "") {
      status.residentCountryError = true;
      status.residentCountryErrorMsg = "Please select your resident country";
    } else {
      status.residentCountryError = false;
      status.residentCountryErrorMsg = "";
    }

    if (
      profile.resident_country === "Other" &&
      profile.other_resident_country === ""
    ) {
      status.residentOtherCountryError = true;
      status.residentOtherCountryErrorMsg =
        "Please select your resident country";
    } else {
      status.residentOtherCountryError = false;
      status.residentOtherCountryErrorMsg = "";
    }

    if (
      profile.resident_city === "" &&
      profile.resident_city_lat === 0 &&
      profile.resident_city_lon === 0
    ) {
      status.residentCityError = true;
      status.residentCityErrorMsg = "Please select your resident city";
    } else {
      status.residentCityError = false;
      status.residentCityErrorMsg = "";
    }

    if (
      status.residentCountryError ||
      status.residentOtherCountryError ||
      status.residentCityError
    ) {
      setErrors(status);
      return false;
    }
    setErrors(status);
    return true;
  };

  const handleNext = async () => {
    if (validate()) {
      setIsLoading(true);
      const saveTempUserData = {
        first_name: profile.first_name,
        last_name: profile.last_name,
        gender: profile.gender,
        dob: profile.dob,
        height: profile.height,
        ethnicity_id: profile.ethnicity_id,
        religion_id: profile.religion_id,
        education_level_id: profile.education_level_id,
        profession_id: profile.profession_id,
        profession_other: profile.profession_other,
        drinking: profile.drinking,
        smoking: profile.smoking,
        food_preference: profile.food_preference,
        resident_city: profile.resident_city,
        resident_city_lat: profile.resident_city_lat,
        resident_city_lon: profile.resident_city_lon,
        resident_country:
          profile.resident_country === "ZZ"
            ? profile.other_resident_country
            : profile.resident_country,
        region_id: profile.region_id,
      };

      dispatch(
        setProfileDetails({
          resident_country:
            profile.resident_country === "ZZ"
              ? profile.other_resident_country
              : profile.resident_country,
        }),
      );

      await agent.CreateFlow.updateTempProfile(saveTempUserData)
        .then(() => {
          setIsLoading(false);
          dispatch(setCurrentScreen(CreateFlowScreen.QUESTION));
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getDetailedCountries())
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          if (profile.resident_country) {
            const checkCountry = response.payload.filter(
              (country: any) => country.country.id === profile.resident_country,
            );

            if (checkCountry.length === 0) {
              const selectedCountry = profile.resident_country;
              dispatch(
                setProfileDetails({
                  resident_country: "ZZ",
                  other_resident_country: selectedCountry,
                }),
              );
            }
          }
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="login-signup-screen flow-step">
      <div className="create-flow-content">
        <div className="breadcrum-container">
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem icon={<BiHome />} />
          <BreadcrumbItem />
          <BreadcrumbItem />
        </div>
        <Typography variant="h1" style={{ marginBottom: "2rem" }}>
          Where do you <br /> live?
        </Typography>
        <div className="inputs-wrapper">
          <Select
            label="Resident Country"
            id="resident_country"
            name="resident_country"
            value={profile.resident_country}
            onChange={(event) => handleOnSelect(event, "resident_country")}
            isError={errors.residentCountryError}
            errorMessage={errors.residentCountryErrorMsg}
          >
            <option value="" selected>
              Please select
            </option>
            {/* {locationData.detailedCountryList.map((country) => (
              <option key={country.country.id} value={country.country.id}>
                {country.country.name}
              </option>
            ))} */}
            {locationData.allCountryList.map((country) => (
              <option key={country.id} value={country.id}>
                {country.name}
              </option>
            ))}
          </Select>

          {profile.resident_country === "ZZ" && (
            <Select
              label="Resident Country - Other"
              id="other_resident_country"
              name="other_resident_country"
              value={profile.other_resident_country}
              onChange={(event) =>
                handleOnSelect(event, "other_resident_country")
              }
              isError={errors.residentOtherCountryError}
              errorMessage={errors.residentOtherCountryErrorMsg}
            >
              <option value="" selected>
                Please select
              </option>
              {locationData.allCountryList.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </Select>
          )}

          <PlacesAutocomplete
            label="Resident City"
            apiKey={GoogleMapsAPIKey.API_KEY}
            value={profile.resident_city}
            handleOnChange={(value) => handleCity(value)}
            autocompletionRequest={{
              componentRestrictions: {
                country: [
                  `${
                    profile.resident_country === "ZZ"
                      ? profile.other_resident_country
                      : profile.resident_country
                  }`,
                ],
              },
            }}
          />
        </div>
      </div>

      <div className="action-button">
        <Button text="Next" onClick={handleNext} loading={isLoading} />
        <Button text="Back" buttonType="text" onClick={handleBack} />
      </div>
    </div>
  );
};
