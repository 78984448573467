import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./index.scss";

interface IProps {
  country: string;
  value: string;
  onChange: Function;
}

export const CountryCodeInput = ({ country, value, onChange }: IProps) => {
  return (
    <PhoneInput
      country={country}
      value={value}
      onChange={(phone, countryData) => onChange({ phone })}
    />
  );
};
