import React from "react";
import { BsFillCameraFill, BsFillXCircleFill } from "react-icons/bs";
import "./index.scss";

interface IProps {
  pickedImage: any;
  errorText: string;
  onChange: (value: any) => void;
  removeSelectedImage: () => void;
}

export const PhotoSelect = ({
  pickedImage,
  errorText,
  onChange,
  removeSelectedImage,
}: IProps) => {
  return (
    <div className="photo-select-container">
      <div className="image-upload">
        {pickedImage ? (
          <>
            {errorText && (
              <div className="photo-error-wrapper">
                <span className="photo-error">{errorText}</span>
              </div>
            )}
            <img src={URL.createObjectURL(pickedImage)} alt="Thumb" />
            <button
              type="button"
              className="img-remove-btn"
              onClick={removeSelectedImage}
            >
              <BsFillXCircleFill />
            </button>
          </>
        ) : (
          <label htmlFor="img-input">
            <BsFillCameraFill />
            <input
              id="img-input"
              accept="image/*"
              type="file"
              className="custom-file-input"
              onChange={onChange}
            />
          </label>
        )}
      </div>
    </div>
  );
};
