import { Divider, Grid, Typography } from "@mui/material";
import { last } from "lodash";

import { Match } from "models/match";
import { Profile } from "models/profile";
import moment from "moment";

const TIME_FORMAT = "DD MMM YYYY";

type Props = {
  items: Match.Timeline[];
  sender: Profile.PublicProfile;
};

const EVENT_BORDER_COLOR: { [key: string]: string } = {
  LIKED: "#9575CD",
  MATCHED: "#448AFF",
};

const Timeline = ({ items, sender }: Props) => {
  const getTimelineEvent = (item: Match.Timeline) => {
    const { event, actionBy } = item;

    switch (event) {
      case "LIKED": {
        return actionBy === "SELF"
          ? `You liked ${sender.firstName}`
          : `${sender.firstName} liked you`;
      }
      case "MATCHED": {
        return actionBy === "SELF"
          ? `You matched with ${sender.firstName}`
          : `${sender.firstName} matched with you`;
      }
      default: {
        return "";
      }
    }
  };

  return (
    <Grid container direction="column" paddingX={1} paddingY={2}>
      {items.map((item, index) => (
        <>
          <Grid
            item
            key={item.createdAt}
            borderLeft={5}
            borderColor={EVENT_BORDER_COLOR[item.event]}
            paddingLeft={1}
            paddingY={1}
            marginY={1}
          >
            <Typography
              variant="body1"
              fontWeight={300}
              fontSize={14}
              color="#000000"
            >
              {moment(item.createdAt).format(TIME_FORMAT)}
            </Typography>
            <Typography fontWeight={400} fontSize={16} color="#000000">
              {getTimelineEvent(item)}
            </Typography>
          </Grid>
          {index !== items.length - 1 && (
            <Grid item>
              <Divider />
            </Grid>
          )}
        </>
      ))}
    </Grid>
  );
};

export default Timeline;
