import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useAppSelector, useAppDispatch } from "store";
import { fetchAuthAvatar, fetchAuthProfile } from "store/application";
import ModalNavigationScreen from "../../component/Screen/ModalNavigationScreen";
import PageHeader from "./PageHeader";
import PageSkeleton from "./views/PageSkeleton";
import PageContent from "./views/PageContent";

const EditProfile = () => {
  const dispatch = useAppDispatch();
  const { authProfile: authprofile, authAvatar } = useAppSelector(
    (state) => state.application,
  );

  useEffect(() => {
    if (authprofile === null) {
      dispatch(fetchAuthProfile()).then(() => {
        dispatch(fetchAuthAvatar());
      });
    }
  }, []);

  return (
    <ModalNavigationScreen paddingX={0} paddingY={0}>
      <Grid container direction="column" alignItems="stretch" height={1}>
        {/* Page Header */}
        <Grid item>
          <PageHeader />
        </Grid>
        {/* Page Content */}
        <Grid
          item
          paddingTop={5}
          paddingBottom={2}
          sx={{ overflowX: "hidden", overflowY: "scroll" }}
        >
          {authprofile === null ? <PageSkeleton /> : <PageContent />}
        </Grid>
      </Grid>
    </ModalNavigationScreen>
  );
};

export default EditProfile;
