import { useState, useEffect } from "react";
import moment from "moment";
import { Button, Typography, Select, BreadcrumbItem } from "components";
import DatePicker from "components/atoms/DatePicker";
import { CreateFlowScreen } from "models/enum";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { setCurrentScreen, setProfileDetails } from "store/features/CreateFlow";
import { heightsArr } from "constants/heightsArr";
import agent from "services";
import { BiUser } from "react-icons/bi";
import "./index.scss";

const initialDOBVal = {
  day: "",
  month: "",
  year: "",
};

export const PersonalDetailsTwo = () => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(
    (state: RootState) => state.createFlow.profile,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [birthday, setBirthday] = useState(profile.dob || "");
  const [height, setHeight] = useState(heightsArr[0].value.toString());
  const [errors, setErrors] = useState({
    dayError: false,
    monthError: false,
    yearError: false,
    heightError: false,
  });

  const upperDate = moment().subtract(60, "years").format("YYYY-MM-DD");
  const lowerDate = moment().subtract(18, "years").format("YYYY-MM-DD");

  const handleBack = () => {
    dispatch(setCurrentScreen(CreateFlowScreen.ADD_NAME));
  };

  const validate = () => {
    const status = {
      dayError: false,
      monthError: false,
      yearError: false,
      heightError: false,
    };

    return true;
  };

  const handleNext = async () => {
    if (validate()) {
      setIsLoading(true);
      const saveTempUserData = {
        // ...profile,
        first_name: profile.first_name,
        last_name: profile.last_name,
        gender: profile.gender,
        dob: birthday,
        height: parseInt(height, 10),
      };
      dispatch(
        setProfileDetails({
          dob: birthday,
          height: parseInt(height, 10),
        }),
      );
      await agent.CreateFlow.updateTempProfile(saveTempUserData)
        .then(() => {
          setIsLoading(false);
          dispatch(setCurrentScreen(CreateFlowScreen.BACKGROUND));
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    console.log(">>>> profile >>>> ", profile);
    console.log(">>>> profile.dob >>>> ", profile.dob);
    setBirthday(profile.dob);
    setHeight(profile.height.toString());
  }, [profile]);

  return (
    <div className="login-signup-screen flow-step">
      <div className="create-flow-content">
        <div className="breadcrum-container">
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem icon={<BiUser />} />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
        </div>
        <Typography variant="h1" style={{ marginBottom: "2rem" }}>
          Enter your dob <br /> and height
        </Typography>
        <div>
          <div style={{ marginBottom: "6px" }}>Date of Birth</div>
          <div id="dob">
            <DatePicker
              startDate={upperDate}
              endDate={lowerDate}
              value={birthday}
              onChange={(selectedValue) => {
                setBirthday(selectedValue);
              }}
            />
          </div>
        </div>

        <Select
          label="Height"
          id="height"
          name="height"
          // value={profile.height.toString()}
          value={height.toString()}
          onChange={(e) => {
            setHeight(e.target.value);
          }}
          isError={errors.heightError}
        >
          {heightsArr.map((element) => (
            <option key={element.value} value={element.value.toString()}>
              {element.name}
            </option>
          ))}
        </Select>
      </div>

      <div className="action-button">
        <Button text="Next" onClick={handleNext} loading={isLoading} />
        <Button text="Back" buttonType="text" onClick={handleBack} />
      </div>
    </div>
  );
};
