import { Grid, Paper, Typography } from "@mui/material";
import WineBarIcon from "@mui/icons-material/WineBar";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Profile } from "models/profile";

type ProfileProps = {
  profile: Profile.Profile;
};

const OtherInfo = ({ profile }: ProfileProps) => {
  return (
    <Paper elevation={3}>
      <Grid container sx={{ padding: "12px" }}>
        <Grid item xs={6}>
          <WineBarIcon sx={{ fontSize: 20, marginRight: 3 }} />
          {/* {authprofile?.drinking &&
              authprofile.drinking.charAt(0) +
                authprofile.drinking.slice(1).toLowerCase()} */}
          {profile.drinking.charAt(0) + profile.drinking.slice(1).toLowerCase()}
        </Grid>
        <Grid item xs={6}>
          <SmokingRoomsIcon sx={{ fontSize: 20, marginRight: 3 }} />
          {profile.smoking.charAt(0) + profile.smoking.slice(1).toLowerCase()}
        </Grid>
        <Grid item xs={6}>
          <RestaurantIcon sx={{ fontSize: 20, marginRight: 3 }} />
          {profile.foodPreference.charAt(0) +
            profile.foodPreference.slice(1).toLowerCase()}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OtherInfo;
