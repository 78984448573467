import { Grid, Typography } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { setShowNavigation } from "store/application";
import MenuDrawer from "./MenuDrawer";

type BottomNavigationScreenProps = {
  name: string;
  children: React.ReactNode;
};

const BottomNavigationScreen = ({
  name,
  children,
}: BottomNavigationScreenProps) => {
  const disptach = useAppDispatch();
  const { authProfile, showWebView } = useAppSelector(
    (state) => state.application,
  );
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    disptach(setShowNavigation(true));
  }, []);

  return (
    <Grid container direction="column" height={1} paddingTop={2} paddingX={1}>
      <Grid item container direction="row" alignItems="center" columnGap={1}>
        {!showWebView && (
          <Grid item>
            <Menu
              fontSize="medium"
              sx={{ color: "#333333" }}
              onClick={(e) => {
                e.preventDefault();
                setShowMenu(true);
              }}
            />
          </Grid>
        )}
        <Grid item xs={10}>
          <Typography variant="h4" fontWeight="600" color="#333333">
            {name}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs>
        {children}
      </Grid>
      {authProfile !== null && !showWebView && (
        <Grid item>
          <MenuDrawer showMenu={showMenu} setShowMenu={setShowMenu} />
        </Grid>
      )}
    </Grid>
  );
};

export default BottomNavigationScreen;
