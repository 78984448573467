import { Grid, Typography } from "@mui/material";

import { Match } from "models/match";
import { Profile } from "models/profile";
import moment from "moment";

const TIME_FORMAT = "DD MMM YYYY";

type Props = {
  items: Match.Timeline[];
  sender: Profile.PublicProfile;
};

const EVENT_BORDER_COLOR: { [key: string]: string } = {
  LIKED: "#9575CD",
};

const Timeline = ({ items, sender }: Props) => {
  const getTimelineEvent = (item: Match.Timeline) => {
    const { event } = item;
    switch (event) {
      case "LIKED": {
        return `${sender.firstName} liked you`;
      }
      default: {
        return "";
      }
    }
  };

  return (
    <Grid container direction="column" paddingX={1} paddingY={2}>
      {items.map((item) => (
        <Grid
          item
          key={item.createdAt}
          borderLeft={5}
          borderColor={EVENT_BORDER_COLOR[item.event]}
          paddingLeft={1}
        >
          <Typography>{moment(item.createdAt).format(TIME_FORMAT)}</Typography>
          <Typography>{getTimelineEvent(item)}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default Timeline;
