import { useCallback, useEffect, useState, MouseEventHandler } from "react";
import { Grid, Typography, Avatar, Skeleton } from "@mui/material";
import moment from "moment";

import { Match } from "models/match";
import {
  getMatchThumbnail,
  getPublicProfileThumbnail,
} from "services/api/mobile/match";

type Props = {
  interest: Match.Match;
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
};

const InterestCardDetailed = ({ interest, onClick }: Props) => {
  const [avatar, setAvatar] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchMatchAvatar = useCallback(async () => {
    const response =
      (await getPublicProfileThumbnail({
        profileId: interest.profile.id,
      })) || undefined;
    return response;
  }, [interest]);

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (loading) {
      fetchMatchAvatar()
        .then((image) => setAvatar(image))
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  }, [loading]);

  const generateInforationRow = (
    row: "fullName" | "peronalInfo" | "likedTimestamp",
  ) => {
    const { profile, timeline } = interest;
    switch (row) {
      case "fullName": {
        return `${profile.firstName} ${profile.lastName}`;
      }
      case "peronalInfo": {
        const { age, residentCity, profession, height } = profile;
        const particles = [age, residentCity, profession.name, height];
        return particles.join(" • ");
      }
      case "likedTimestamp": {
        const likedTimeline = timeline.find((obj) => obj.event === "LIKED");
        const formattedTime =
          likedTimeline !== undefined
            ? moment(likedTimeline.createdAt).format("DD MMMM YYYY")
            : "";
        return `Liked you on ${formattedTime}`;
      }
      default: {
        return "";
      }
    }
  };

  return (
    <Grid
      container
      direction="column"
      bgcolor="#EDEDED"
      borderRadius={5}
      flexGrow={1}
      sx={{ borderColor: "#EDEDED", borderWidth: 1.5, borderStyle: "solid" }}
      onClick={(e) => {
        if (onClick !== undefined) {
          onClick(e);
        }
      }}
    >
      <Grid
        item
        xs={5}
        sm={5}
        md={6}
        lg={7}
        borderRadius={2}
        height={1}
        flexWrap="wrap"
      >
        {loading ? (
          <Skeleton width="100%" height={180} />
        ) : (
          <img
            src={avatar}
            alt="main-interest-view"
            style={{
              maxInlineSize: "100%",
              width: "100%",
              height: 180,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              objectFit: "fill",
            }}
          />
        )}
      </Grid>

      <Grid item xs={3} sm={3} md={2} lg={2} paddingX={1} height={1} width={1}>
        <Typography variant="h6">
          {generateInforationRow("fullName")}
        </Typography>
        <Typography variant="subtitle1">
          {generateInforationRow("peronalInfo")}
        </Typography>
        <Typography variant="caption">
          {generateInforationRow("likedTimestamp")}
        </Typography>
      </Grid>

      <Grid
        item
        xs={3}
        sm={3}
        md={3}
        lg={3}
        paddingX={1}
        height={1}
        paddingY={1}
      >
        <Grid
          container
          direction="column"
          alignItems="stretch"
          justifyContent="flex-end"
          alignSelf="flex-end"
        >
          <Grid item width={1}>
            <Typography
              variant="body2"
              width={1}
              fontWeight={400}
              bgcolor="white"
              color="#333333"
              paddingY={0.5}
              paddingX={1}
              fontSize={12}
              sx={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
            >
              {interest.question}
            </Typography>
          </Grid>
          <Grid item width={1}>
            <Typography
              variant="body2"
              width={1}
              bgcolor="#448AFF"
              color="#FFFFFF"
              fontWeight={500}
              paddingY={0.5}
              paddingX={1}
              fontSize={12}
              sx={{ borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}
            >
              {interest.questionResponse}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InterestCardDetailed;
