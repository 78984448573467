import { Grid, Paper, Typography } from "@mui/material";

import GoBackLink from "components/templates/Home/component/Screen/GoBackLink";

const PageHeader = () => {
  return (
    <Paper elevation={0}>
      <Grid container direction="column">
        <Grid item xs={4}>
          <GoBackLink name="Home" to="/app" />
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="h4"
            fontWeight="600"
            color="#333333"
            paddingX={2}
            paddingY={1}
          >
            Edit Profile
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PageHeader;
