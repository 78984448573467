import { Grid, Paper } from "@mui/material";
import { isEmpty } from "lodash";
import React, {
  useEffect,
  useRef,
  useState,
  UIEvent as ReactUIEvent,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";

import { Match } from "models/match";
import { fetchMatches } from "store/application";
import { markMessagesSeenAPI } from "services/api/mobile/messages";
import GoBackLink from "../../component/Screen/GoBackLink";
import ModalNavigationScreen from "../../component/Screen/ModalNavigationScreen";
import MessageTextInput from "./components/MessageTextInput";
import PersonalInfoHeader from "./components/PersonalInfoHeader";
import MessageView from "./components/MessageView";

const MessageThreadPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const messageViewRef = useRef<HTMLDivElement | null>(null);

  const { matches } = useAppSelector((state) => state.application);
  const [interestId, setInterestId] = useState<string | null>(null);
  const [match, setMatch] = useState<Match.Match | null>(null);

  const markMessagesSeen = useCallback(async () => {
    if (match?.id !== undefined) {
      const response = await markMessagesSeenAPI({ interestId: match?.id });
      if (!response.success) {
        console.log(">>>>> error handling");
      }
    }
  }, [interestId]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _interestId = searchParams.get("interestId");
    if (_interestId == null) {
      navigate("/app/messages");
      return;
    }
    setInterestId(_interestId);
  }, []);

  useEffect(() => {
    if (isEmpty(matches)) {
      dispatch(fetchMatches());
      return;
    }
    const filteredMatch = matches.find((element) => {
      return element.id === interestId;
    });
    if (filteredMatch !== undefined) {
      setMatch(filteredMatch as Match.Match);
      const { latestChat } = filteredMatch;
      if (latestChat !== undefined && latestChat.seen === false) {
        markMessagesSeen();
      }
    }
  }, [interestId, matches]);

  return (
    <ModalNavigationScreen paddingX={0} paddingY={0}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        height={1}
      >
        <Grid item padding={0.1}>
          <Paper elevation={3}>
            <Grid container direction="column">
              <Grid item>
                <GoBackLink name="Messages" to="/app/messages/" />
              </Grid>
              <Grid item paddingX={2} paddingY={1}>
                {match !== null && <PersonalInfoHeader interest={match} />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {match !== null && (
          <MessageView ref={messageViewRef} interest={match} />
        )}
        {interestId !== null && (
          <Grid item>
            <Paper elevation={5} sx={{ paddingY: 2 }}>
              <MessageTextInput
                interestId={interestId}
                messageViewRef={messageViewRef}
              />
            </Paper>
          </Grid>
        )}
      </Grid>
    </ModalNavigationScreen>
  );
};

export default MessageThreadPage;
