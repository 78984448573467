import { useCallback, useEffect, useState, forwardRef, RefObject } from "react";
import {
  Grid,
  CircularProgress,
  Paper,
  Typography,
  Divider,
  Chip,
} from "@mui/material";
import AppConfig from "config/application";
import { Match } from "models/match";
import { useAppDispatch, useAppSelector } from "store";
import {
  fetchChatMessages,
  refreshChatMessages,
  setHasNewMessages,
} from "store/application/MessageThread";
import DailyMessageView from "./components/DailyMessageView";
import MatchQuestionAnswer from "./components/MatchQuestionAnswer";

type MessageViewProps = {
  interest: Match.Match;
};

const MessageView = forwardRef<HTMLDivElement, MessageViewProps>(
  ({ interest }, ref) => {
    const dispatch = useAppDispatch();
    const { chatMessages, loadedLastPage, hasNewMessages } = useAppSelector(
      (state) => state.messageThread,
    );
    const [initialized, setInitialized] = useState<boolean>(false);
    const [loadingMore, setLoadingMore] = useState<boolean>(false);

    const initailizMessageView = useCallback(async () => {
      await dispatch(
        fetchChatMessages({ interestId: interest.id, latestPage: false }),
      )
        .then(() => {
          setInitialized(true);
        })
        .finally(() => {
          if (ref !== null) {
            const scrollRef = ref as RefObject<HTMLDivElement>;
            scrollRef.current?.scrollTo({
              behavior: "auto",
              top: scrollRef.current.scrollHeight,
            });
          }
        });
    }, [interest]);

    const onScrollTopEnd = useCallback(async () => {
      setLoadingMore(true);
      dispatch(
        fetchChatMessages({ interestId: interest.id, latestPage: false }),
      ).finally(() => {
        setLoadingMore(false);
      });
    }, []);

    const scrollEventHandler = () => {
      if (hasNewMessages) {
        dispatch(setHasNewMessages(false));
      }
      const rootContainerRef = ref as RefObject<HTMLDivElement>;
      const rootContainer = rootContainerRef.current;
      if (rootContainer !== null && rootContainer.scrollHeight === 0) {
        onScrollTopEnd();
      }
    };

    useEffect(() => {
      if (!initialized) {
        initailizMessageView();
      }
    }, []);

    useEffect(() => {
      let rootContainer: HTMLDivElement | null;
      let refreshContentInterval: NodeJS.Timer;
      if (initialized) {
        const rootContainerRef = ref as RefObject<HTMLDivElement>;
        rootContainer = rootContainerRef.current;
        if (rootContainer !== null) {
          rootContainer.scrollTo({
            behavior: "auto",
            top: rootContainer?.scrollHeight,
          });
          rootContainer.addEventListener("scroll", scrollEventHandler);
          refreshContentInterval = setInterval(() => {
            dispatch(refreshChatMessages({ interestId: interest.id }));
          }, AppConfig.messageThreadRefreshTimeout);
        }

        return () => {
          rootContainer?.removeEventListener("scroll", () => {});
          clearInterval(refreshContentInterval);
        };
      }
    }, [initialized]);

    return (
      <>
        <Grid
          item
          xs
          container
          direction="row"
          bgcolor="white"
          sx={{ overflowX: "hidden", overflowY: "scroll" }}
          ref={ref}
        >
          {loadingMore && (
            <Grid item xs={12} paddingTop={1}>
              <Grid container direction="row" justifyContent="center">
                <Grid item>
                  <CircularProgress size={20} sx={{ color: "#9575CD" }} />
                </Grid>
              </Grid>
            </Grid>
          )}
          {loadedLastPage && (
            <Grid item xs={12}>
              <MatchQuestionAnswer interest={interest} />
            </Grid>
          )}
          {Object.entries(chatMessages).map(([date, messages]) => (
            <Grid item xs={12}>
              <DailyMessageView
                key={`message-date-${date}`}
                date={date}
                messages={messages}
              />
            </Grid>
          ))}
        </Grid>
        {hasNewMessages && (
          <Paper>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              paddingY={1}
            >
              <Grid item xs>
                <Divider
                  color="blue"
                  sx={{
                    "&::before, &::after": {
                      borderColor: "#9575CD",
                    },
                  }}
                >
                  <Chip
                    label="new messages"
                    variant="outlined"
                    sx={{ color: "#9575CD", borderColor: "#9575CD" }}
                    onClick={(e) => {
                      e.preventDefault();
                      const rootContainerRef = ref as RefObject<HTMLDivElement>;
                      const rootContainer = rootContainerRef.current;
                      if (rootContainer !== null) {
                        rootContainer.scrollTo({
                          behavior: "auto",
                          top: rootContainer.scrollHeight,
                        });
                        dispatch(setHasNewMessages(false));
                      }
                    }}
                  />
                </Divider>
              </Grid>
            </Grid>
          </Paper>
        )}
      </>
    );
  },
);

export default MessageView;
