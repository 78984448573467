import {
  Grid,
  Paper,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Fab,
} from "@mui/material";
import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Cake,
  Person,
  ImportContacts,
  LineWeight,
  School,
  Work,
  LocalBar,
  SmokingRooms,
  RestaurantMenu,
  Favorite,
  Close,
} from "@mui/icons-material";
import { capitalize, isEmpty } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { Match } from "models/match";
import { Profile } from "models/profile";
import { setAppAlert } from "store/application";
import {
  acceptInterestAPI,
  getPublicProfileThumbnail,
  rejectInterestAPI,
} from "services/api/mobile/match";
import { fetchInterests } from "store/application/Interests";
import ModalNavigationScreen from "../../component/Screen/ModalNavigationScreen";
import GoBackLink from "../../component/Screen/GoBackLink";
import Timeline from "./components/Timeline";
import GridDataView, { DataView } from "./components/GridDataView";
import QuestionAnswer from "./components/QuestionAnswer";

const InterestPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { interestId } = useParams();
  const { interests } = useAppSelector((state) => state.interests);
  const [interest, setInterest] = useState<Match.Match | null>(null);
  const [avatar, setAvatar] = useState<string | undefined>(undefined);

  const generateDataView = (
    profile: Profile.PublicProfile,
    section: "personalInfo" | "career" | "foodPreference",
  ): DataView => {
    switch (section) {
      case "personalInfo": {
        return [
          { icon: <Cake />, label: profile.age.toString() },
          { icon: <Person />, label: profile.ethnicity.name },
          { icon: <LineWeight />, label: profile.height.toString() },
          { icon: <ImportContacts />, label: profile.religion.name },
        ];
      }
      case "career": {
        return [
          { icon: <School />, label: profile.educationLevel.name },
          { icon: <Work />, label: profile.profession.name },
        ];
      }
      case "foodPreference": {
        return [
          { icon: <LocalBar />, label: capitalize(profile.drinking) },
          { icon: <SmokingRooms />, label: capitalize(profile.smoking) },
          {
            icon: <RestaurantMenu />,
            label: capitalize(profile.foodPreference),
          },
        ];
      }
      default:
        return [];
    }
  };

  const generateInformaton = (
    field: "fullName" | "residency" | "personalSummery",
  ) => {
    if (isEmpty(interest)) {
      return "";
    }
    const { profile } = interest;
    switch (field) {
      case "fullName": {
        return `${profile.firstName} ${profile.lastName}`;
      }
      case "residency": {
        return `${profile.residentCity}, ${profile.residentCountry.name}`;
      }
      case "personalSummery": {
        const { age, profession, height } = profile;
        return [age, profession.name, height].join(" • ");
      }
      default: {
        return "";
      }
    }
  };

  const fetchInterestAvatar = useCallback(async () => {
    if (interest === null) {
      return;
    }
    const { id: profileId } = interest.profile;
    const image = (await getPublicProfileThumbnail({ profileId })) || undefined;
    return image;
  }, [interest]);

  const onRejectInterest: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    if (interest === null) {
      return;
    }
    const response = await rejectInterestAPI(interest.id);
    if (response.success) {
      dispatch(fetchInterests());
      navigate("/app/likes");
    } else {
      dispatch(
        setAppAlert({
          severity: "error",
          message: "Error occurred, Try again",
        }),
      );
    }
  };

  const onAcceptInterest: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    if (interest === null) {
      return;
    }
    const response = await acceptInterestAPI(interest.profile.id);
    if (response.success) {
      dispatch(fetchInterests());
      navigate("/app/likes");
    } else {
      dispatch(
        setAppAlert({
          severity: "error",
          message: "Unable to accept interest, Try agian",
        }),
      );
    }
  };

  useEffect(() => {
    if (isEmpty(interests)) {
      dispatch(fetchInterests());
      return;
    }
    const interestObj = interests.find((current) => current.id === interestId);
    if (!isEmpty(interestObj)) {
      setInterest(interestObj);
    }
  }, [interests]);

  useEffect(() => {
    if (interest === null) {
      return;
    }
    fetchInterestAvatar()
      .then((image) => setAvatar(image))
      .catch((e) => console.error(e));
  }, [interest]);

  return (
    <ModalNavigationScreen paddingX={0} paddingY={0}>
      <Grid container direction="column" bgcolor="#E5E5E5" height={1}>
        <Grid item>
          <Paper variant="elevation" elevation={3}>
            <Grid container direction="column" paddingBottom={1}>
              <Grid item>
                <GoBackLink name="Likes" to="/app/likes" />
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Typography variant="h5" paddingX={1}>
                    {generateInformaton("fullName")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  alignItems="end"
                  justifyContent="end"
                >
                  <Grid item>
                    <MoreVertIcon />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          item
          xs
          container
          direction="row"
          sx={{ overflowX: "hidden", overflowY: "scroll" }}
          paddingX={2}
        >
          <Grid item xs={12}>
            <Card sx={{ maxWidth: "100%", boxShadow: 0 }}>
              <CardMedia
                image={avatar}
                sx={{ height: 140 }}
                title="interest-avatar"
              />
              <CardContent>
                <Typography variant="body1">
                  {generateInformaton("personalSummery")}
                </Typography>
                <Typography variant="caption">
                  {generateInformaton("residency")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {interest?.timeline !== undefined && (
            <Grid item xs={12}>
              <Timeline sender={interest.profile} items={interest?.timeline} />
            </Grid>
          )}
          {interest?.profile !== undefined && (
            <>
              <Grid item xs={12}>
                <GridDataView
                  items={generateDataView(interest.profile, "personalInfo")}
                />
              </Grid>
              <Grid item xs={12}>
                <GridDataView
                  items={generateDataView(interest.profile, "career")}
                />
              </Grid>
              <Grid item xs={12}>
                <GridDataView
                  items={generateDataView(interest.profile, "foodPreference")}
                />
              </Grid>
              <Grid item marginY={1} xs={12}>
                <QuestionAnswer
                  question={interest.question}
                  answer={interest.questionResponse}
                />
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            columnGap={2}
            padding={2}
          >
            <Fab onClick={onRejectInterest}>
              <Close fontSize="large" />
            </Fab>
            <Fab onClick={onAcceptInterest}>
              <Favorite fontSize="large" sx={{ color: "#F33A6A" }} />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    </ModalNavigationScreen>
  );
};

export default InterestPage;
