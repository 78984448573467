import { useCallback, useEffect, useState } from "react";
import { Grid, Avatar, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { isEmpty, isNull } from "lodash";
import { useNavigate } from "react-router-dom";

import type { Match } from "models/match";
import { formatHumanReadbleTime } from "components/templates/Home/util/formatter";
import { getMatchThumbnail } from "services/api/mobile/match";

type ContactListItemProps = {
  match: Match.Match;
};

const ContactListItem = ({ match }: ContactListItemProps) => {
  const navigate = useNavigate();
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [avatar, setAvatar] = useState("");
  const [avatarDownloaded, setAvatarDownloaded] = useState(false);

  const downloadAvatorImage = useCallback(async () => {
    try {
      setAvatarDownloaded(true);
      const response = await getMatchThumbnail({
        matchId: match.id,
        imageId: match.thumbnail,
        thumbnail: true,
      });
      if (!isNull(response)) {
        setAvatar(response);
      }
    } catch (e) {
      console.log(">>>> error >>> ", e);
    }
  }, [match, avatarDownloaded]);

  useEffect(() => {
    if (isEmpty(match)) {
      return;
    }
    const { latestChat, questionResponse } = match;
    setHasNewMessage(latestChat?.seen === false);
    setMessage(latestChat?.message || questionResponse);
    setAvatarDownloaded(true);
  }, []);

  useEffect(() => {
    if (avatarDownloaded) {
      downloadAvatorImage();
    }
  }, [avatarDownloaded]);

  const { profile } = match;

  return (
    <Grid
      container
      direction="row"
      paddingY={1}
      columnSpacing={2}
      borderBottom={1}
      borderColor="#D4D4D4"
      onClick={(e) => {
        e.preventDefault();
        const searchParams = new URLSearchParams();
        searchParams.append("interestId", match.id);
        const path = `thread?${searchParams.toString()}`;
        navigate(path);
      }}
    >
      <Grid item xs={2}>
        <Grid container alignItems="center" justifyContent="left" height={1}>
          <Avatar
            variant="circular"
            src={avatar}
            sx={{ width: 1, height: 1 }}
          />
        </Grid>
      </Grid>
      <Grid item xs={7}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="#333333" fontWeight={500}>
              {`${profile.firstName} ${profile.lastName}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="#333333"
              fontWeight={hasNewMessage ? 500 : 300}
              textOverflow="ellipsis"
              noWrap
              width={1}
            >
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} container direction="column" alignItems="flex-end">
        <Grid item>
          <Typography
            variant="caption"
            fontSize={10}
            color="#333333"
            fontWeight={400}
          >
            {formatHumanReadbleTime(match.sortTimestamp)}
          </Typography>
        </Grid>
        <Grid item paddingTop={1}>
          {hasNewMessage && (
            <FiberManualRecordIcon sx={{ fontSize: 15 }} color="error" />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactListItem;
