/* eslint-disable no-return-assign */

import React from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

interface LatLng {
  lat: number;
  lng: number;
}

interface AutocompletionRequest {
  bounds?: [LatLng, LatLng];
  componentRestrictions?: { country: string | string[] };
  location?: LatLng;
  offset?: number;
  radius?: number;
  types?: string[];
}

interface IProps {
  apiKey: string;
  autocompletionRequest?: AutocompletionRequest;
  value?: any;
  handleOnChange: (val: any) => void;
  label?: string;
}

export const PlacesAutocomplete = ({
  apiKey,
  autocompletionRequest,
  value,
  handleOnChange,
  label,
}: IProps) => {
  const handleLocationData = async (data: any) => {
    const locationData = {
      locationName: data.value.structured_formatting.main_text,
      region: "",
      geoData: {},
    };

    await geocodeByAddress(data.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        return (locationData.geoData = { lat, lng });
      });
    await geocodeByPlaceId(data.value.place_id)
      .then((results) => {
        return (locationData.region =
          results[0].address_components[2].long_name);
      })
      .catch((error) => console.error(error));
    handleOnChange(locationData);
  };

  return (
    <div className="google-places-autocomplete-container">
      {
        // eslint-disable-next-line
        <label>{label}</label>
      }
      <GooglePlacesAutocomplete
        apiKey={apiKey}
        autocompletionRequest={autocompletionRequest}
        selectProps={{
          placeholder: value,
          value,
          onChange: handleLocationData,
          styles: {
            control: (provided: any) => ({
              ...provided,
              backgroundColor: "#EDEDED",
              border: 0,
            }),

            dropdownIndicator: (provided: any) => ({
              ...provided,
              color: "#343a40",
            }),

            indicatorSeparator: () => ({
              display: "none",
            }),

            input: (provided: any) => ({
              ...provided,
              width: "100%",
              color: "black",
              fontSize: 16,
              marginVertical: 6,
              padding: "10.5px 0",
              borderRadius: 4,
              backgroundColor: "#EDEDED",
            }),
          },
        }}
      />
    </div>
  );
};
