import React, { useCallback } from "react";
import { BiCheckCircle, BiErrorCircle } from "react-icons/bi";
import { Typography } from "../Typography";
import "./index.scss";

interface IProps {
  variant: "success" | "error";
  message: string;
  styles?: React.CSSProperties;
}

export const AlertMessage = ({ variant, message, styles }: IProps) => {
  const setIcon = useCallback(() => {
    switch (variant) {
      case "success":
        return <BiErrorCircle />;
      case "error":
        return <BiCheckCircle />;
      default:
    }
  }, [variant]);

  return (
    <div
      className={`alert-message-container variant-${variant}`}
      style={styles}
    >
      <div className="alert-message-icon">{setIcon()}</div>
      <Typography variant="baseRegular">{message}</Typography>
    </div>
  );
};

AlertMessage.defaultProps = {
  styles: undefined,
};
