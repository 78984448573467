/* eslint-disable class-methods-use-this */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import applyCaseMiddleware from "axios-case-converter";
import queryString from "query-string";

type Token = {
  created_at: string;
  description: string;
  id: string;
  is_new_user: boolean;
} | null;

type KeyValueMap = { [key: string]: any };
type QueryParams = {
  [key: string]: string;
};

type RequestBody = string | KeyValueMap | FormData | undefined;
type ContentType =
  | "application/x-www-form-urlencoded"
  | "application/json"
  | "multipart/form-data";

type RequestHeaders = {
  [key: string]: string;
};

interface HTTPRequest {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  path: string;
  apiVersion: "v1";
  authenticated: boolean;
  body?: RequestBody;
  contentType?: ContentType;
  headers?: RequestHeaders;
  queryParams?: QueryParams;
  reponseType?: "json" | "blob";
}

interface RequestWithBody extends HTTPRequest {
  requestBody: RequestBody;
  contentType?:
    | "application/x-www-form-urlencoded"
    | "application/json"
    | "multipart/form-data";
}

interface RequestWithBody {
  body: RequestBody;
  contentType?:
    | "application/x-www-form-urlencoded"
    | "application/json"
    | "multipart/form-data";
}

export type ApiResponse = {
  success: boolean;
  data: any;
  status: number;
  statusText: string;
};

export type ApiError = {
  status: number;
  statusText: string;
};

class ApiProxy {
  private static singleton: ApiProxy;

  private baseURL;

  private axiosObj;

  private authToken;

  private constructor() {
    this.baseURL = `${process.env.REACT_APP_BASE_URL}`;
    this.axiosObj = applyCaseMiddleware(
      axios.create({
        baseURL: this.baseURL,
      }),
    );
    this.authToken = JSON.parse(
      localStorage.getItem("token") || "null",
    ) as Token;
  }

  static getInstance(): ApiProxy {
    const instance = this.singleton || new ApiProxy();
    if (instance.authToken === null) {
      instance.authToken = JSON.parse(localStorage.getItem("token") || "null");
    }
    return instance;
  }

  private sanitizeRequest(request: HTTPRequest) {
    request.method = request.method || "GET";
    request.headers = request.headers || {};
    request.queryParams = request.queryParams || {};

    if (request.body !== undefined) {
      if (request.body instanceof FormData) {
        request.contentType = "multipart/form-data";
      } else if (request.contentType === "application/x-www-form-urlencoded") {
        request.body = queryString.stringify(request.body as KeyValueMap);
      } else {
        request.contentType = "application/json";
      }
    }
  }

  private handleAuthrorization(request: HTTPRequest) {
    request.headers = request.headers || {};
    if (request.authenticated) {
      const { headers: requestHeaders } = request;
      const token = JSON.parse(localStorage.getItem("token") || "null");
      if (token !== null) {
        const authToken = token as Token;
        requestHeaders.Authorization = `Bearer ${authToken}`;
      }
    }
  }

  private generateResponseBody = (
    request: HTTPRequest,
    response: AxiosResponse,
  ): AxiosResponse | string => {
    if (request.reponseType === "blob") {
      return URL.createObjectURL(response.data);
    }
    return response.data;
  };

  public static async doRequest(request: HTTPRequest) {
    const singleton = ApiProxy.getInstance();
    const delegate = singleton.axiosObj;
    singleton.sanitizeRequest(request);
    singleton.handleAuthrorization(request);

    let response: ApiResponse;
    try {
      const apiResult = await delegate.request({
        baseURL: `${process.env.REACT_APP_BASE_URL}`,
        url: `${request.apiVersion}/${request.path}`,
        method: request.method,
        headers: request.headers,
        params: request.queryParams,
        data: request.body,
        responseType: request.reponseType || "json",
      });
      response = {
        success: true,
        data: singleton.generateResponseBody(request, apiResult),
        status: apiResult.status,
        statusText: apiResult.statusText,
      };
    } catch (e) {
      console.error("adaper exeception ", e);
      response = {
        success: false,
        data: [],
        status: 500,
        statusText: "Testing hello",
      };
    }
    return response;
  }
}

export default ApiProxy;
