import adapter from "services/api/mobile/proxy";

export const getRemoteMatches = async () => {
  const response = await adapter.doRequest({
    method: "GET",
    apiVersion: "v1",
    path: "matches",
    authenticated: true,
  });
  return response;
};

export const getMatchThumbnail = async ({
  matchId,
  imageId,
  thumbnail,
}: {
  matchId: string;
  imageId: string;
  thumbnail: boolean;
}) => {
  const response = await adapter.doRequest({
    method: "GET",
    apiVersion: "v1",
    path: `matches/${matchId}/images/${imageId}`,
    queryParams: {
      thumbnail: String(thumbnail),
    },
    authenticated: true,
    reponseType: "blob",
  });
  return response.success ? response.data : null;
};

export const getPublicProfileThumbnail = async ({
  profileId,
}: {
  profileId: string;
}) => {
  const response = await adapter.doRequest({
    method: "GET",
    apiVersion: "v1",
    path: `profiles/${profileId}/public_image`,
    authenticated: true,
    reponseType: "blob",
  });
  return response.success ? response.data : null;
};

export const getMatchAPI = async (id: string) => {
  const response = await adapter.doRequest({
    method: "GET",
    apiVersion: "v1",
    path: `matches/${id}`,
    authenticated: true,
  });
  return response;
};

export const getInterestsAPI = async () => {
  const response = await adapter.doRequest({
    method: "GET",
    apiVersion: "v1",
    path: "interests",
    authenticated: true,
  });
  return response;
};

export const rejectInterestAPI = async (interestId: string) => {
  const response = await adapter.doRequest({
    method: "POST",
    apiVersion: "v1",
    path: `interests/${interestId}/ignore`,
    authenticated: true,
    body: {},
    contentType: "application/x-www-form-urlencoded",
  });
  return response;
};

export const acceptInterestAPI = async (profileId: string) => {
  const response = await adapter.doRequest({
    method: "POST",
    apiVersion: "v1",
    path: `interests`,
    authenticated: true,
    body: {
      profile_id: profileId,
    },
    contentType: "application/x-www-form-urlencoded",
  });
  return response;
};
