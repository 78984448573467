import { useEffect, useState } from "react";
import { Button, Typography, Select, Input, BreadcrumbItem } from "components";
import { CreateFlowScreen } from "models/enum";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { setCurrentScreen, setProfileDetails } from "store/features/CreateFlow";
import agent from "services";
import { getMasterData } from "store/MasterData";
import { FaGraduationCap } from "react-icons/fa";
import "./index.scss";

export const PersonalEducation = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    educationError: false,
    professionError: false,
    professionOtherError: false,
    educationErrorMsg: "",
    professionErrorMsg: "",
    professionOtherErrorMsg: "",
  });

  const profile = useAppSelector(
    (state: RootState) => state.createFlow.profile,
  );
  const masterData = useAppSelector(
    (state: RootState) => state.masterData.masterData,
  );

  const handleOnSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: string,
  ) => {
    dispatch(setProfileDetails({ [prop]: parseInt(event.target.value, 10) }));
  };

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: string,
  ) => {
    if (prop === "profession_other" && event.target.value) {
      const regex = /^[A-Za-z]+$/;
      if (!regex.test(event.target.value)) {
        return;
      }
    }
    dispatch(setProfileDetails({ [prop]: event.target.value }));
  };

  const handleBack = () => {
    dispatch(setCurrentScreen(CreateFlowScreen.BACKGROUND));
  };

  const validate = () => {
    const status = {
      educationError: false,
      professionError: false,
      professionOtherError: false,
      educationErrorMsg: "",
      professionErrorMsg: "",
      professionOtherErrorMsg: "",
    };

    if (profile.education_level_id === 0) {
      status.educationError = true;
      status.educationErrorMsg = "Please select your education";
    } else {
      status.educationError = false;
      status.educationErrorMsg = "";
    }

    if (profile.profession_id === 0) {
      status.professionError = true;
      status.professionErrorMsg = "Please select your profession";
    } else {
      status.professionError = false;
      status.professionErrorMsg = "";
    }

    if (profile.profession_id === 1 && profile.profession_other === "") {
      status.professionOtherError = true;
      status.professionOtherErrorMsg = "Please type your profession";
    } else {
      status.professionOtherError = false;
      status.professionOtherErrorMsg = "";
    }

    if (
      status.educationError ||
      status.professionError ||
      status.professionOtherError
    ) {
      setErrors(status);
      return false;
    }
    setErrors(status);
    return true;
  };

  const handleNext = async () => {
    if (validate()) {
      setIsLoading(true);
      const saveTempUserData = {
        first_name: profile.first_name,
        last_name: profile.last_name,
        gender: profile.gender,
        dob: profile.dob,
        height: profile.height,
        ethnicity_id: profile.ethnicity_id,
        religion_id: profile.religion_id,
        education_level_id: profile.education_level_id,
        profession_id: profile.profession_id,
        profession_other:
          profile.profession_id === 1 ? profile.profession_other : "",
      };

      await agent.CreateFlow.updateTempProfile(saveTempUserData)
        .then(() => {
          setIsLoading(false);
          dispatch(setCurrentScreen(CreateFlowScreen.HABBITS));
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    dispatch(getMasterData());
  }, []);

  return (
    <div className="login-signup-screen flow-step">
      <div className="create-flow-content">
        <div className="breadcrum-container">
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem icon={<FaGraduationCap />} />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
        </div>
        <Typography variant="h1" style={{ marginBottom: "2rem" }}>
          What’s your <br /> background?
        </Typography>
        <div className="inputs-wrapper">
          <Select
            label="Education"
            id="education"
            name="education"
            value={profile.education_level_id?.toString()}
            onChange={(event) => handleOnSelect(event, "education_level_id")}
            isError={errors.educationError}
            errorMessage={errors.educationErrorMsg}
          >
            <option value="0" selected>
              Please select
            </option>
            {masterData?.education_levels
              ?.slice()
              .sort((a, b) => a.sort_order - b.sort_order)
              .map((education) => (
                <option key={education.id} value={education.id}>
                  {education.name}
                </option>
              ))}
          </Select>

          <Select
            label="Profession"
            id="profession"
            name="profession"
            value={profile.profession_id?.toString()}
            onChange={(event) => handleOnSelect(event, "profession_id")}
            isError={errors.professionError}
            errorMessage={errors.professionErrorMsg}
          >
            <option value="0" selected>
              Please select
            </option>
            {masterData?.professions
              ?.slice()
              .sort((a, b) => {
                if (a.name === "Other") return 1;
                if (b.name === "Other") return -1;
                return a.name.localeCompare(b.name);
              })
              .map((profession) => (
                <option key={profession.id} value={profession.id}>
                  {profession.name}
                </option>
              ))}
          </Select>

          {profile.profession_id === 1 && (
            <Input
              label="Profession - Other"
              name="profession_other"
              type="text"
              value={profile.profession_other}
              onChange={(event) => handleOnChange(event, "profession_other")}
              isError={errors.professionOtherError}
              errorMessage={errors.professionOtherErrorMsg}
            />
          )}
        </div>
      </div>

      <div className="action-button">
        <Button text="Next" onClick={handleNext} loading={isLoading} />
        <Button text="Back" buttonType="text" onClick={handleBack} />
      </div>
    </div>
  );
};
