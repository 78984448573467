import { Grid, Typography, Select, MenuItem } from "@mui/material";

type Option = {
  id: number | string;
  name: string;
  sortOrder?: number;
};
type Props = {
  label: string;
  items: Option[];
  value: string | number;
  onChange?: (value: string | number) => void;
};

const Dropdown = ({ label, items, value, onChange }: Props) => {
  return (
    <Grid container direction="column" marginY={0.5}>
      <Grid item>
        <Typography
          variant="body1"
          color="#333333"
          fontWeight={600}
          fontSize={14}
          paddingY={0.5}
        >
          {label}
        </Typography>
        <Select
          fullWidth
          value={value}
          onChange={(e) => {
            e.preventDefault();
            if (onChange !== undefined) {
              onChange(e.target.value);
            }
          }}
          sx={{ backgroundColor: "#EDEDED" }}
        >
          {items.map((item) => (
            <MenuItem key={`select-option-${item.id}`} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

export default Dropdown;
