import { useState } from "react";
import {
  Button,
  Typography,
  PhotoSelect,
  BreadcrumbItem,
  AlertMessage,
} from "components";
import { CreateFlowScreen } from "models/enum";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { setCurrentScreen } from "store/features/CreateFlow";
import agent from "services";
import { BsFillCameraFill } from "react-icons/bs";
import "./index.scss";
import { fetchAuthProfile } from "store/application";

export const PersonalPhotos = () => {
  const dispatch = useAppDispatch();
  const [pickedImagePathOne, setPickedImagePathOne] = useState<any>({
    imageData: "",
    imageId: "",
    error: "",
  });
  const [pickedImagePathTwo, setPickedImagePathTwo] = useState<any>({
    imageData: "",
    imageId: "",
    error: "",
  });
  const [pickedImagePathThree, setPickedImagePathThree] = useState<any>({
    imageData: "",
    imageId: "",
    error: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    photoError: false,
    photoErrorMsg: "",
  });

  const profile = useAppSelector(
    (state: RootState) => state.createFlow.profile,
  );

  const handleBack = () => {
    dispatch(setCurrentScreen(CreateFlowScreen.QUESTION));
  };

  const uploadImage = async (imageData: any, imagePath: number) => {
    const formData = new FormData();
    formData.append("file", imageData);

    await agent.CreateFlow.genericFileUpload(formData)
      .then((response) => {
        switch (imagePath) {
          case 1:
            setPickedImagePathOne({
              ...pickedImagePathOne,
              imageData,
              imageId: response.data.name,
            });
            break;
          case 2:
            setPickedImagePathTwo({
              ...pickedImagePathTwo,
              imageData,
              imageId: response.data.name,
            });
            break;
          case 3:
            setPickedImagePathThree({
              ...pickedImagePathThree,
              imageData,
              imageId: response.data.name,
            });
            break;

          default:
        }
      })
      .catch((error) => {
        console.log(error);

        if (error.response.data.code === 1006) {
          switch (imagePath) {
            case 1:
              setPickedImagePathOne({
                ...pickedImagePathOne,
                imageData,
                error: "Unsupported file format. Please use another image",
              });
              break;
            case 2:
              setPickedImagePathTwo({
                ...pickedImagePathTwo,
                imageData,
                error: "Unsupported file format. Please use another image",
              });
              break;
            case 3:
              setPickedImagePathThree({
                ...pickedImagePathThree,
                imageData,
                error: "Unsupported file format. Please use another image",
              });
              break;

            default:
          }
        }
      });
    return "";
  };

  const validate = () => {
    const status = {
      photoError: false,
      photoErrorMsg: "",
    };

    if (
      pickedImagePathOne.imageId === "" ||
      pickedImagePathTwo.imageId === ""
    ) {
      status.photoError = true;
      status.photoErrorMsg = "Please add a photo";
    } else {
      status.photoError = false;
      status.photoErrorMsg = "";
    }

    if (status.photoError) {
      setErrors(status);
      return false;
    }
    setErrors(status);
    return true;
  };

  const handleNext = async () => {
    if (validate()) {
      setIsLoading(true);

      const selectedImages = [];
      if (pickedImagePathOne.imageId) {
        selectedImages.push(pickedImagePathOne.imageId);
      }
      if (pickedImagePathTwo.imageId) {
        selectedImages.push(pickedImagePathTwo.imageId);
      }
      if (pickedImagePathThree.imageId) {
        selectedImages.push(pickedImagePathThree.imageId);
      }

      const saveTempUserData = {
        first_name: profile.first_name,
        last_name: profile.last_name,
        gender: profile.gender.toUpperCase(),
        dob: profile.dob,
        height: profile.height,
        ethnicity_id: profile.ethnicity_id,
        religion_id: profile.religion_id,
        education_level_id: profile.education_level_id,
        profession_id: profile.profession_id,
        profession_other: profile.profession_other,
        drinking: profile.drinking.toUpperCase(),
        smoking: profile.smoking.toUpperCase(),
        food_preference: profile.food_preference,
        resident_city: profile.resident_city,
        resident_city_lat: profile.resident_city_lat,
        resident_city_lon: profile.resident_city_lon,
        resident_country: profile.resident_country,
        match_question: profile.match_question,
        images: selectedImages,
        region_id: "LK", // TODO: remove hard-coded value
      };

      await agent.CreateFlow.updateTempProfile(saveTempUserData)
        .then(async () => {
          const apiResponse = await agent.CreateFlow.createProfile(
            saveTempUserData,
          );
          if (apiResponse.success) {
            dispatch(setCurrentScreen(CreateFlowScreen.PROFILE_REVIEW));
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(">>>> error >>>> ", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="login-signup-screen flow-step">
      <div className="create-flow-content">
        <div className="breadcrum-container">
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem icon={<BsFillCameraFill />} />
        </div>
        <Typography variant="h1" style={{ marginBottom: "2rem" }}>
          Add some photos to your <br /> profile
        </Typography>

        <div className="upload-wrapper">
          <PhotoSelect
            pickedImage={pickedImagePathOne.imageData}
            errorText={pickedImagePathOne.error}
            onChange={(event) => {
              setPickedImagePathOne({
                ...pickedImagePathOne,
                imageData: event.target.files[0],
              });
              uploadImage(event.target.files[0], 1);
            }}
            removeSelectedImage={() => {
              setPickedImagePathOne({
                imageData: "",
                imageId: "",
              });
            }}
          />
          <PhotoSelect
            pickedImage={pickedImagePathTwo.imageData}
            errorText={pickedImagePathTwo.error}
            onChange={(event) => {
              setPickedImagePathTwo({
                ...pickedImagePathTwo,
                imageData: event.target.files[0],
              });
              uploadImage(event.target.files[0], 2);
            }}
            removeSelectedImage={() =>
              setPickedImagePathTwo({
                imageData: "",
                imageId: "",
              })
            }
          />
          <PhotoSelect
            pickedImage={pickedImagePathThree.imageData}
            errorText={pickedImagePathThree.error}
            onChange={(event) => {
              setPickedImagePathThree({
                ...pickedImagePathThree,
                imageData: event.target.files[0],
              });
              uploadImage(event.target.files[0], 3);
            }}
            removeSelectedImage={() =>
              setPickedImagePathThree({
                imageData: "",
                imageId: "",
              })
            }
          />
        </div>

        {errors.photoError && (
          <AlertMessage
            variant="error"
            message={errors.photoErrorMsg}
            styles={{ marginBottom: "1rem" }}
          />
        )}

        <Typography variant="baseRegular">
          Photos will not be shared with other users until you match.
        </Typography>
      </div>

      <div className="action-button">
        <Button
          text="Create Profile"
          onClick={handleNext}
          loading={isLoading}
        />
        <Button text="Back" buttonType="text" onClick={handleBack} />
      </div>
    </div>
  );
};
