import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "models/enum";
import { Account, Checkout, LoadingStatuses, Profile } from "models/models";
import agent from "services";

export const getUserAccount = createAsyncThunk(
  "user/getUserAccount",
  async (args, thunkAPI) => {
    try {
      const response = await agent.Payment.getUserAccount();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getUserProfile = createAsyncThunk(
  "user/getUserProfile",
  async (args, thunkAPI) => {
    try {
      const response = await agent.Payment.getUserProfile();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getCheckoutDetails = createAsyncThunk(
  "user/getUserCheckoutSession",
  async (month: string | number, thunkAPI) => {
    try {
      const response = await agent.Payment.checkoutSession(month);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

interface UsersState {
  account: Account;
  profile: Profile;
  checkoutData: Checkout;
  loadingAccount: LoadingStatuses;
  loadingProfile: LoadingStatuses;
  loadingCheckout: LoadingStatuses;
  months: string;
}

const initialState = {
  account: {},
  profile: {},
  checkoutData: {},
  loadingAccount: LoadingStatus.IDLE,
  loadingProfile: LoadingStatus.IDLE,
  loadingCheckout: LoadingStatus.IDLE,
  months: "1",
} as UsersState;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSelectedMonths: (state, { payload }) => {
      state.months = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserAccount.pending, (state) => {
      state.loadingAccount = "pending";
    });
    builder.addCase(getUserAccount.fulfilled, (state, action) => {
      state.loadingAccount = "succeeded";
      state.account = action.payload;
    });
    builder.addCase(getUserAccount.rejected, (state) => {
      state.loadingAccount = "failed";
    });

    builder.addCase(getUserProfile.pending, (state) => {
      state.loadingProfile = "pending";
    });
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.loadingProfile = "succeeded";
      state.profile = action.payload;
    });
    builder.addCase(getUserProfile.rejected, (state) => {
      state.loadingProfile = "failed";
    });

    builder.addCase(getCheckoutDetails.pending, (state) => {
      state.loadingCheckout = "pending";
    });
    builder.addCase(getCheckoutDetails.fulfilled, (state, { payload }) => {
      state.loadingCheckout = "succeeded";
      state.checkoutData = payload;
    });
    builder.addCase(getCheckoutDetails.rejected, (state) => {
      state.loadingCheckout = "failed";
    });
  },
});

const { actions, reducer } = userSlice;
export const { setSelectedMonths } = actions;
export default reducer;
