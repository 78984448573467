import { useEffect, useState, useCallback } from "react";
import { Grid, Skeleton, Typography } from "@mui/material";

import { Match } from "models/match";
import { getPublicProfileThumbnail } from "services/api/mobile/match";

type Props = {
  interest: Match.Match;
};

const InterestCardSummery = ({ interest }: Props) => {
  const [avatar, setAvatar] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchMatchAvatar = useCallback(async () => {
    const response =
      (await getPublicProfileThumbnail({
        profileId: interest.profile.id,
      })) || undefined;
    return response;
  }, [interest]);

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (loading) {
      fetchMatchAvatar()
        .then((image) => setAvatar(image))
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    }
  }, [loading]);
  const generateFullName = () => {
    const { profile } = interest;
    return `${profile.firstName} ${profile.lastName}`;
  };
  return (
    <Grid
      container
      direction="column"
      bgcolor="#EDEDED"
      height={1}
      maxHeight={1}
      justifyItems="flex-start"
      borderRadius={2}
      columns={5}
    >
      <Grid item xs={3} height={1}>
        {loading ? (
          <Skeleton width="100%" height={80} />
        ) : (
          <img
            src={avatar}
            alt="main-interest-view"
            style={{
              maxInlineSize: "100%",
              inlineSize: "auto",
              width: "100%",
              height: 80,
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
            }}
          />
        )}
      </Grid>

      <Grid item xs={1} paddingX={1}>
        <Typography variant="body1">{generateFullName()}</Typography>
      </Grid>
    </Grid>
  );
};

export default InterestCardSummery;
