const platforms = ["macos", "ios", "android", "linux", "windows"] as const;
export type Platform = (typeof platforms)[number];

export const detectOS = (): Platform | null => {
  const { userAgent, platform } = window.navigator;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];

  let detectedOS: Platform | null = null;

  switch (true) {
    case macosPlatforms.includes(platform):
      detectedOS = "macos";
      break;
    case iosPlatforms.includes(platform):
      detectedOS = "ios";
      break;
    case windowsPlatforms.includes(platform):
      detectedOS = "windows";
      break;
    case /Android/.test(userAgent):
      detectedOS = "android";
      break;
    case /Linux/.test(platform):
      detectedOS = "linux";
      break;
    default:
      detectedOS = null;
  }

  return detectedOS;
};
