import { Button, Grid, Typography } from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";
import React, { SyntheticEvent } from "react";
import { isUndefined } from "lodash";
import { useNavigate } from "react-router-dom";

type GoBackLinkProps = {
  name: string;
  to: string;
};

const GoBackLink = ({ name, to }: GoBackLinkProps) => {
  const navigate = useNavigate();

  const onClickGoBack = (event: SyntheticEvent | undefined) => {
    if (isUndefined(event)) {
      return;
    }
    event.preventDefault();
    navigate(to);
  };

  return (
    <Button
      variant="text"
      size="large"
      startIcon={<ArrowBackIosNew />}
      onClick={onClickGoBack}
      sx={{ textTransform: "capitalize" }}
    >
      {name}
    </Button>
  );
};

export default GoBackLink;
