import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import ActionableImage from "components/templates/Home/component/ActionableImage";
import FormResponder from "components/templates/Home/component/Button/FormResponder";
import { MAX_PROFILE_IMAGES } from "config/profile";
import { Profile } from "models/profile";
import { useAppDispatch } from "store";
import { fetchAuthAvatar } from "store/application";
import { getAuthAvatarAPI } from "services/api/mobile/auth";
import {
  addImageModification,
  submitForReview,
} from "store/application/EditProfile";

type Props = {
  profile: Profile.Profile;
};

type Modifcation = {
  slotId: number;
  file: File | string;
  action: "delete" | "upload";
};

const defaultImageSlots = Array.from(Array(MAX_PROFILE_IMAGES).fill(null));

const EditProfileImagesView = ({ profile }: Props) => {
  const dispatch = useAppDispatch();
  const [imageSlots, setImageSlots] =
    useState<Array<string | null>>(defaultImageSlots);

  const onSelectHandler = (slotId: number, file: File) => {
    dispatch(addImageModification({ slotId, file, action: "upload" }));
  };

  const onDeleteHandler = (slotId: number, file: string) => {
    dispatch(addImageModification({ slotId, file, action: "delete" }));
  };

  const initialize = () => {
    const { profileImages } = profile;
    const processedSlots = Array.from(imageSlots);
    let index = 0;
    while (index < profileImages.length) {
      const profileImage = profileImages[index];
      const { id } = profileImage;
      processedSlots[index] = id;
      index += 1;
    }
    setImageSlots(processedSlots);
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <Grid
      container
      direction="row"
      bgcolor="white"
      rowSpacing={1}
      paddingX={1}
      paddingY={2}
    >
      <Grid item xs={12}>
        <Typography variant="h6" fontSize={16}>
          Changes that need to be reviewed
        </Typography>
      </Grid>
      <Grid item xs={12} container direction="row">
        <Typography variant="body1" fontWeight={300} fontSize={14}>
          Photos will not be shared with other users until you match. We will
          review your photos and approve them within 24 hours.
        </Typography>
        <Grid item xs={12} paddingY={1}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={2}
            padding={1}
          >
            {imageSlots.map((image, idx) => (
              <Grid key={`photo-upload-${Math.random()}`} item xs={4} width={1}>
                <ActionableImage
                  slotId={idx}
                  image={image}
                  handleDownload={async (
                    fileName,
                    setImage,
                    setDownloading,
                  ) => {
                    getAuthAvatarAPI(fileName).then((data) => {
                      setImage(data || undefined);
                      setDownloading(false);
                    });
                  }}
                  onSelect={onSelectHandler}
                  onDelete={onDeleteHandler}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} paddingY={3} paddingX={2}>
          <FormResponder
            postiveRespond={{
              label: "Submit For Review",
              handler: () => {
                dispatch(submitForReview());
              },
            }}
            negativeRespond={{
              label: "Discard Changes",
              handler: () => {
                initialize();
              },
            }}
          />
        </Grid>
        <Typography variant="body1" fontWeight={300} fontSize={12}>
          We do not allow the birthdate or resident country to be changed on
          verified profiles. Please contact support@_.com if you need to change
          them.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EditProfileImagesView;
