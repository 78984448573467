import { Grid, BottomNavigation, BottomNavigationAction } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MessageIcon from "@mui/icons-material/Message";
import { NaviagationScreen, Actions } from "store/application";

import React, { SyntheticEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { isEmpty } from "lodash";
import { Home } from "components/pages";

const Navigation = () => {
  const dispatch = useAppDispatch();
  const { navigations, mainScreen } = useAppSelector(
    (state) => state.application,
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const getCurrentValue = (): number => {
    const [appPath, content] = pathname
      .split("/")
      .filter((str) => !isEmpty(str));
    switch (content) {
      case "likes":
        return 1;
      case "messages":
        return 2;
      default:
        return 0;
    }
  };
  const [value, setValue] = useState(getCurrentValue());

  const handleNavigationChange = (event: SyntheticEvent, page: string) => {
    event.preventDefault();
    const { setMainScreen } = Actions;
    dispatch(setMainScreen(page));
    navigate(page);
  };

  return (
    <BottomNavigation
      showLabels={false}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      sx={{
        "& .Mui-selected, .Mui-selected > svg": {
          color: "#9575CD",
        },
        height: "100%",
      }}
    >
      <BottomNavigationAction
        id="home"
        label="Home"
        icon={<HomeIcon />}
        onClick={(e) => handleNavigationChange(e, "/app")}
      />
      <BottomNavigationAction
        id="likes"
        label="Likes"
        icon={<FavoriteIcon />}
        onClick={(e) => handleNavigationChange(e, "likes")}
      />
      <BottomNavigationAction
        id="messages"
        label="Matches"
        icon={<MessageIcon />}
        onClick={(e) => handleNavigationChange(e, "messages")}
      />
    </BottomNavigation>
  );
};

export default Navigation;
