import adapter from "services/api/mobile/proxy";

const QUERY_DEFAULT_LIMIT = 10;
const QUERY_DEFAULT_PAGE = 0;

export const getChatMessagesAPI = async (
  interestId: string,
  pageNo: number,
  limit: number,
) => {
  const response = await adapter.doRequest({
    authenticated: true,
    apiVersion: "v1",
    method: "GET",
    path: "messages",
    queryParams: {
      interestId,
      pageNum: pageNo.toString(),
      pageSize: limit.toString(),
    },
  });
  return response.data;
};

export const postNewMessageAPI = async (
  interestId: string,
  message: string,
) => {
  const response = await adapter.doRequest({
    authenticated: true,
    apiVersion: "v1",
    method: "POST",
    path: "messages",
    body: { interest_id: interestId, message },
    contentType: "application/x-www-form-urlencoded",
  });
  return response;
};

export const markMessagesSeenAPI = async ({
  interestId,
}: {
  interestId: string;
}) => {
  const response = await adapter.doRequest({
    authenticated: true,
    apiVersion: "v1",
    method: "POST",
    path: "messages/seen",
    body: { interest_id: interestId },
    contentType: "application/x-www-form-urlencoded",
  });
  return response;
};
