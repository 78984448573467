import adapter from "services/api/mobile/proxy";

export const getAuthProfile = async () => {
  const response = await adapter.doRequest({
    method: "GET",
    apiVersion: "v1",
    path: "profile",
    authenticated: true,
  });
  return response;
};

export const getAuthAvatarAPI = async (image: string) => {
  const response = await adapter.doRequest({
    method: "GET",
    apiVersion: "v1",
    path: `profile/images/${image}`,
    authenticated: true,
    reponseType: "blob",
  });
  return response.success ? response.data : null;
};

export const submitProfileReviewAPI = async () => {
  const response = await adapter.doRequest({
    method: "POST",
    apiVersion: "v1",
    path: `profile/submit_review`,
    authenticated: true,
    reponseType: "json",
  });
  return response;
};
