import { Grid } from "@mui/material";
import { MouseEventHandler } from "react";

import { Match } from "models/match";
import InterestCardSummery from "./summery";
import InterestCardDetailed from "./detailed";

type InterestCardProps = {
  type: "summery" | "detailed";
  interest: Match.Match;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
};

const InterestCard = ({ type, interest, onClick }: InterestCardProps) => {
  const getVaraintByType = () => {
    switch (type) {
      case "summery":
        return <InterestCardSummery interest={interest} />;
      default:
        return <InterestCardDetailed interest={interest} onClick={onClick} />;
    }
  };

  return (
    <Grid
      container
      direction="column"
      width={1}
      bgcolor="#EDEDED"
      justifyContent="center"
      sx={{ borderRadius: 3 }}
    >
      <Grid item>{getVaraintByType()}</Grid>
    </Grid>
  );
};

export default InterestCard;
