/* eslint-disable import/order */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HiMenuAlt3, HiOutlineXCircle } from "react-icons/hi";
import { Typography } from "components";
import Logo from "../../../assets/images/Logo-White.png";
import "./index.scss";
import { Button } from "@mui/material";

const activeStyle = {
  borderBottom: "3px solid rgb(112 32 253)",
  fontWeight: 500,
};

export const NavBar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  return (
    <div className="navbar-container">
      <div className="container">
        <nav className="navbar" onClick={(e) => e.stopPropagation()}>
          <div className="nav-container">
            <div className="nav-logo">
              <NavLink to="/" className="hytch-logo">
                <img src={Logo} alt="hytch logo" />
              </NavLink>
            </div>

            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <Button
                  variant="contained"
                  onClick={() => {
                    window.open(process.env.REACT_APP_WEB_APP_URL, "_blank");
                  }}
                  sx={{
                    backgroundColor: "#6f0082",
                    paddingX: 4,
                    borderRadius: 5,
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#6f0082",
                    },
                    "&:disabled": {
                      backgroundColor: "#6f0082",
                    },
                  }}
                >
                  Sign Up
                </Button>
                {/* </NavLink> */}
              </li>
            </ul>
            <div className="nav-icon" onClick={handleClick}>
              {click ? <HiOutlineXCircle /> : <HiMenuAlt3 />}
            </div>
          </div>
        </nav>
        <div
          className={click ? "main-container" : ""}
          onClick={() => Close()}
        />
      </div>
    </div>
  );
};
