import moment from "moment";

export const formatHumanReadbleTime = (input: string): string => {
  const currentDay = moment();
  const parsedDateTime = moment(input);

  let diff = currentDay.diff(parsedDateTime, "months");
  if (diff > 0) {
    return parsedDateTime.format("YYYY MMM D");
  }

  diff = currentDay.diff(parsedDateTime, "day");
  if (diff > 0) {
    return `${diff} days ago`;
  }

  diff = currentDay.diff(parsedDateTime, "hours");
  if (diff > 0) {
    return `${diff} hours ago`;
  }

  diff = currentDay.diff(parsedDateTime, "minutes");
  if (diff > 0) {
    return `${diff} minutes ago`;
  }

  diff = currentDay.diff(parsedDateTime, "seconds");
  if (diff > 0) {
    return `${diff} seconds ago`;
  }

  return "just now";
};

export const formatDateDurationToReadable = (
  inputMoment: moment.Moment,
): string => {
  console.log(
    ">>>>>>>> inputMoment >>>> ",
    inputMoment.calendar({
      lastWeek: "YYYY MMMM DD",
      lastDay: "Yeasterday",
      sameDay: "Today",
      sameElse: "YYYY MMMM DD",
      nextDay: "Tommorow",
      nextWeek: " YYYY MMMM DD",
    }),
  );
  return inputMoment.calendar({
    lastWeek: "YYYY MMMM DD",
    lastDay: "[Yeasterday]",
    sameDay: "[Today]",
    sameElse: "YYYY MMMM DD",
    nextDay: "[Tommorow]",
    nextWeek: " YYYY MMMM DD",
  });
};

export const convertCmToFeetAndInches = (cm: number): string => {
  const inches = cm / 2.54;
  const feet = Math.floor(inches / 12);
  const remainingInches = Math.round(inches % 12);
  return `${feet}' ${remainingInches}"`;
};
