import { Profile } from "models/profile";

type ProfileStatusMetaType = {
  [key in Profile.ProfileStatus]: {
    label: string;
    tag: { bgColor: string; fontColor: string };
  };
};

export const ProfileStatusMeta: ProfileStatusMetaType = {
  CREATED: {
    label: "Created",
    tag: { fontColor: "#FFFFFF", bgColor: "#39B5E0" },
  },
  IN_REVIEW: {
    label: "In Review",
    tag: { fontColor: "#20262E", bgColor: "#FFE162" },
  },
  REVIEW_REJECTED: {
    label: "Review Rejected",
    tag: { fontColor: "#FFFFFF", bgColor: "#E21818" },
  },
  LIVE: {
    label: "Live",
    tag: { fontColor: "#FFFFFF", bgColor: "#27AE60" },
  },
  DISABLED: {
    label: "Disabled",
    tag: { fontColor: "#FFFFFF", bgColor: "#EB1D36" },
  },
  EXPIRED: {
    label: "Expired",
    tag: { fontColor: "#FFFFFF", bgColor: "#EB1D36" },
  },
  PAUSED: {
    label: "Paused",
    tag: { fontColor: "#FFFFFF", bgColor: "#27AE60" },
  },
};

export const MAX_PROFILE_IMAGES = 6;
