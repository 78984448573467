import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadFileAPI } from "services/api/mobile/generics";
import {
  deleteProfileImagesAPI,
  getPendingEditRequestsAPI,
  postEditProfileRequest,
} from "services/api/mobile/profile";
import { RootState } from "store";
import { setAppAlert } from "..";

type ImageModification = {
  file: File | string;
  action: "upload" | "delete";
  slotId: number;
};

type State = {
  imageModifications: ImageModification[];
};

const initialState: State = {
  imageModifications: [],
};

export const submitForReview = createAsyncThunk(
  "EditProfile/submitForReview",
  async (arg, { dispatch, getState }) => {
    const { imageModifications } = (getState() as RootState).editProfile;
    const uploads: string[] = [];
    imageModifications.forEach(async (modification) => {
      const { action, file } = modification;
      if (action === "upload") {
        if (file instanceof File) {
          const response = await uploadFileAPI(file);
          if (response.success) {
            uploads.push(response.data.name);
          }
        }
      }
      if (action === "delete") {
        if (typeof file === "string") {
          const response = await deleteProfileImagesAPI(file);
        }
      }
    });
    const response = await postEditProfileRequest({ images: uploads });
    if (!response.success) {
      dispatch(
        setAppAlert({
          severity: "error",
          message: "Error Occurred, Try Again",
          duration: 5000,
        }),
      );
    }
  },
);

export const getPendingEditRequests = createAsyncThunk(
  "EditProfile/getPendingEditRequests",
  async () => {
    const apiResponse = await getPendingEditRequestsAPI();
    return apiResponse;
  },
);

const EditProfile = createSlice({
  name: "EditProfile",
  initialState,
  reducers: {
    addImageModification: (
      state,
      { type, payload }: { type: string; payload: ImageModification },
    ) => {
      state.imageModifications = [...state.imageModifications, payload];
    },
  },
});

export default EditProfile.reducer;
export const { addImageModification } = EditProfile.actions;
