/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GenericData } from "models/genericdata";
import { getGenericListAPI, uploadFileAPI } from "services/api/mobile/generics";
import {
  DrinkingPreferences,
  FoodPreferences,
  SmokingPreferences,
} from "config/generics";

type GenericStates = {
  educationLevels: GenericData.EducationLevel[];
  ethnicities: GenericData.Ethnicity[];
  professions: GenericData.Profession[];
  religions: GenericData.Religion[];
  drinkingPreferences: GenericData.GenericPreference[];
  smokingPreferences: GenericData.GenericPreference[];
  foodPreferences: GenericData.GenericPreference[];
};
const initialState: GenericStates = {
  educationLevels: [],
  ethnicities: [],
  professions: [],
  religions: [],
  drinkingPreferences: [],
  foodPreferences: [],
  smokingPreferences: [],
};

export const fetchGenericLists = createAsyncThunk(
  "application/fetchGenericLists",
  async (arg, { dispatch }) => {
    const apiResponse = await getGenericListAPI();
    if (!apiResponse.success) {
      console.log("generic list error!");
      return;
    }
    // console.log("generic list", apiResponse.data);
    const generics = apiResponse.data as GenericData.GenericListResponse;
    dispatch(setEducationLevels(generics.educationLevels));
    dispatch(setEthnecities(generics.ethnicities));
    dispatch(setProfessions(generics.professions));
    dispatch(setReligions(generics.religions));
    dispatch(setDrinkingPreferences(DrinkingPreferences));
    dispatch(setFoodPreferences(FoodPreferences));
    dispatch(setSmokingPreferences(SmokingPreferences));
  },
);

export const genericUploadfiles = createAsyncThunk(
  "application/genericUploadfiles",
  async (file: File) => {
    const apiResponse = await uploadFileAPI(file);
    return apiResponse;
  },
);

const genericSlice = createSlice({
  name: "generics",
  initialState,
  reducers: {
    setEducationLevels: (state, action) => {
      state.educationLevels = action.payload;
    },
    setEthnecities: (state, action) => {
      state.ethnicities = action.payload;
    },
    setProfessions: (state, action) => {
      state.professions = action.payload;
    },
    setReligions: (state, action) => {
      state.religions = action.payload;
    },
    setDrinkingPreferences: (state, action) => {
      state.drinkingPreferences = action.payload;
    },
    setSmokingPreferences: (state, action) => {
      state.smokingPreferences = action.payload;
    },
    setFoodPreferences: (state, action) => {
      state.foodPreferences = action.payload;
    },
  },
});

const {
  setEducationLevels,
  setEthnecities,
  setProfessions,
  setReligions,
  setDrinkingPreferences,
  setFoodPreferences,
  setSmokingPreferences,
} = genericSlice.actions;

export default genericSlice.reducer;
