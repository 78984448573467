export enum AuthStatus {
  idle = "idle",
}

export enum LoadingStatus {
  IDLE = "idle",
  PENDING = "pending",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

export enum CreateFlowScreen {
  LOGIN_SIGNIN = "LoginSignup",
  OTP = "otp",
  WELCOME = "Welcome",
  ADD_NAME = "AddName",
  ADD_BIRTHDAY = "AddBirthday",
  BACKGROUND = "PersonalBackground",
  EDUCATION = "PersonalEducation",
  HABBITS = "PersonalHabbits",
  LOCATION = "PersonalLocation",
  QUESTION = "PersonalQuestion",
  PHOTOS = "PersonalPhotos",
  PROFILE_REVIEW = "ProfileReview",
  SUCCESS = "Success",
}

export enum GoogleMapsAPIKey {
  API_KEY = "AIzaSyC8EFXuIwNdJVSEzVOCIDJeCRM2Rhuj8VY",
}

export enum AlphabetKey {
  ALPHABET = "zQLfK7TNM6l8w4pOrnge5u2ZjdFcaxEk9RSW0Yvt3VmPZDisIhJ1qXCHGUyboB",
}
