import { Grid, Typography } from "@mui/material";

import Message from "models/message";
import moment from "moment";

type MessageProps = {
  message: Message.ChatMessage;
};

type MessageConfig = {
  [key in Message.Sender]: {
    bgcolor: string;
    justifyContent: string;
    color: string;
  };
};

const config: MessageConfig = {
  SELF: {
    bgcolor: "#9575CD",
    justifyContent: "end",
    color: "#FFFFFF",
  },
  OTHER_PARTY: {
    bgcolor: "#EDEDED",
    justifyContent: "start",
    color: "#333333",
  },
};

const TIME_FORMAT = "hh:mm a";

const MessageView = ({ message }: MessageProps) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent={config[message.sender].justifyContent}
      marginTop={0.5}
    >
      <Grid
        item
        bgcolor={config[message.sender].bgcolor}
        minWidth="40%"
        maxWidth="80%"
        borderRadius={1}
        paddingX={1}
        paddingTop={1}
      >
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body2" color={config[message.sender].color}>
              {message.message}
            </Typography>
          </Grid>
          <Grid item paddingBottom={0.5}>
            <Typography
              align="right"
              variant="body2"
              color={config[message.sender].color}
              fontSize={10}
            >
              {moment(message.createdAt).format(TIME_FORMAT)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MessageView;
