import Payment from "./payment";
import CreateFlow from "./createFlow";
import MasterData from "./masterData";

const agent = {
  Payment,
  CreateFlow,
  MasterData,
};

export default agent;
