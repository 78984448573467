import React from "react";
import { Spinner } from "components";
import "./index.scss";

const PageLoading = () => {
  return (
    <div className="page-loading-container">
      <Spinner />
      <h3>Please wait</h3>
    </div>
  );
};

export default PageLoading;
