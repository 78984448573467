import React from "react";

const Copyright = () => {
  return (
    <div className="copyright">
      <p>
        © 2022. Hytch Powered by{" "}
        <a
          href="https://www.serendib.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Serendib Systems
        </a>
        . All rights reserved.
      </p>
    </div>
  );
};

export default Copyright;
