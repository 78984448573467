import { ReactElement } from "react";
import { Grid } from "@mui/material";
import { Cake, Person, ImportContacts, LineWeight } from "@mui/icons-material";

import { Profile } from "models/profile";
import DataViewItem from "./atoms/DataViewItem";

type Props = {
  items: DataView;
};

type DataViewItemDef = {
  icon: ReactElement;
  label: string;
};

export type DataView = DataViewItemDef[];

const GridDataView = ({ items }: Props) => {
  return (
    <Grid
      container
      direction="row"
      bgcolor="#FFFFFF"
      paddingX={4}
      paddingY={2}
      borderRadius={2}
      marginY={1}
    >
      {items.map((item) => (
        <Grid item xs={6} key={item.label}>
          <DataViewItem icon={item.icon} label={item.label} />
        </Grid>
      ))}
    </Grid>
  );
};

export default GridDataView;
