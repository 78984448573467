import { GenericData } from "models/genericdata";

export const DrinkingPreferences: GenericData.GenericPreference[] = [
  { id: "YES", name: "Yes", sortOrder: 1 },
  { id: "NO", name: "No", sortOrder: 2 },
  { id: "OCCASIONALLY", name: "Sometimes", sortOrder: 3 },
];

export const SmokingPreferences: GenericData.GenericPreference[] = [
  { id: "YES", name: "Yes", sortOrder: 1 },
  { id: "NO", name: "No", sortOrder: 2 },
  { id: "OCCASIONALLY", name: "Sometimes", sortOrder: 3 },
];

export const FoodPreferences: GenericData.GenericPreference[] = [
  { id: "VEG", name: "Vegetarian", sortOrder: 1 },
  { id: "NONE", name: "Non - Vegetarian", sortOrder: 3 },
  { id: "VEGAN", name: "Vegan", sortOrder: 2 },
  { id: "PESC", name: "Pescatarian", sortOrder: 1 },
];

const [HEIGHT_MIN_VALUE, HEIGHT_MAX_VALUE] = [122, 229];

export const convertCentimetersToFeetAndInches = (centimeters: number) => {
  const cmToFeet = (cmValue: number) => cmValue / 30;

  const feets = Math.trunc(cmToFeet(centimeters));
  const difference = centimeters % 30;
  const inches = Math.round(difference * 0.3937);

  return { feets, inches };
};

const generateHeightValues = () => {
  const values = [];
  let currentCentimeter = HEIGHT_MIN_VALUE;
  let sortOrder = 1;
  while (currentCentimeter <= HEIGHT_MAX_VALUE) {
    const convertion = convertCentimetersToFeetAndInches(currentCentimeter);
    const { feets, inches } = convertion;
    const label = `${feets}ft ${inches} in (${currentCentimeter} cm)`;
    sortOrder += 1;
    values.push({
      id: currentCentimeter,
      name: label,
      sort_order: sortOrder,
    });
    currentCentimeter += 1;
  }
  return values;
};

export const heightValues = generateHeightValues();
