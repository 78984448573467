import { useAppDispatch } from "store";
import { CSSProperties } from "react";
import { AppBar, Toolbar, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import HytchLogo from "assets/images/Logo.png";
import { logoutAuth } from "store/application";

const styles: { [component: string]: React.CSSProperties } = {
  appBar: {
    backgroundColor: "#FAF9F6",
    height: "100%",
    justifyContent: "center",
  },
  hytchLogo: {
    maxInlineSize: "100%",
    inlineSize: "auto",
    width: "6%",
    height: "auto",
    cursor: "pointer",
  },
  logoutBtn: {
    backgroundColor: "#9575CD",
  },
};

const AppTopBar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        display: {
          xs: "none",
          sm: "none",
          md: "flex",
        },
        ...styles.appBar,
      }}
    >
      <Toolbar variant="regular">
        <Box display="flex" flexGrow={1}>
          <img
            src={HytchLogo}
            alt="hytch-logo"
            style={styles.hytchLogo}
            onClick={() => {
              window.open("https://www.hytch.app/", "_blank");
            }}
          />
        </Box>
        <Button
          variant="contained"
          size="small"
          sx={styles.logoutBtn}
          onClick={(e) => {
            e.preventDefault();
            dispatch(logoutAuth()).then(() => {
              navigate("/");
            });
          }}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default AppTopBar;
