/* eslint-disable no-return-assign */
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Home,
  Payment,
  Register,
  BankTransfer,
  PrivacyPolicy,
  Terms,
  PageNotFound,
  HomeLayout,
} from "components";
import { persistor } from "store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import BaseHome from "components/templates/Home/pages/BaseHome";
import LikePage from "components/templates/Home/pages/Likes";
import MessagePage from "components/templates/Home/pages/Messages";
import Downloads from "components/pages/DownloadApp";
import MessageThreadPage from "components/templates/Home/pages/MessageThread";
import Profile from "components/templates/Home/pages/Profile";
import InterestPage from "components/templates/Home/pages/Interest";
import MatchProfile from "components/templates/Home/pages/MatchedProfile";
import EditProfile from "components/templates/Home/pages/EditProfile";
import ServiceUnavailablePage from "components/pages/ServiceUnavailable";

function App() {
  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault();
      persistor.purge();
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="service-unavailable"
            element={<ServiceUnavailablePage />}
          />
          <Route path="payment" element={<Payment />} />
          <Route path="payment/bank-transfer" element={<BankTransfer />} />
          <Route path="register" element={<Register />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="app" element={<HomeLayout />}>
            <Route index element={<Profile />} />
            <Route path="likes" element={<LikePage />} />
            <Route path="likes/:interestId" element={<InterestPage />} />
            <Route path="messages" element={<MessagePage />} />
            <Route path="messages/thread" element={<MessageThreadPage />} />
            <Route path="profile" element={<MatchProfile />} />
            <Route path="edit-profile" element={<EditProfile />} />
          </Route>
          <Route path="download" element={<Downloads />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default App;
