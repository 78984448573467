import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface UsersState {
  token: string;
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState = {
  token: "",
  loading: "idle",
} as UsersState;

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      state.token = payload;
    },
  },
});

const { actions, reducer } = authSlice;
export const { setToken } = actions;
export default reducer;
