import { useEffect } from "react";
import { NavBar, Typography } from "components";
import Copyright from "../Home/Copyright";
import "./index.scss";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <div className="container privacy-policy-container">
        <Typography
          variant="h1"
          style={{ marginBottom: "1rem", textAlign: "center" }}
        >
          PRIVACY POLICY
        </Typography>
        <Typography
          variant="h4"
          style={{ marginTop: 0, marginBottom: "3rem", textAlign: "center" }}
        >
          Last updated date: 16 November, 2023
        </Typography>

        <p className="pp-about">
          The mobile app, Hytch [the “app”/ “Hytch”] is owned by Serendib
          Systems Pty Ltd of Australia which, together with its affiliate
          partners [“We”, “Us”, or “Our”] and third-party licensors provide a
          mobile application where you as a user [“You”] may create your profile
          containing your own personal information and data (or that of
          consenting third parties) for purposes of seeking a suitable partner
          for marriage.
        </p>

        <ol>
          <li className="p-title">
            <h2>Our Privacy Policy statement explains the following:</h2>
          </li>
          <ol type="a">
            <li>
              what personal information and data may be provided by You as a
              user of the app,
            </li>
            <li>
              what information and data do we gather or collect from visitors
              to, and users (including You) of, the app by the use of cookies
              and other technologies, and
            </li>
            <li>
              how we store, use, share, disclose, and protect such information
              and data.
            </li>
          </ol>
          <li className="p-title">
            <h2>We Respect Your Privacy</h2>
          </li>
          <ol type="a">
            <li>
              When You use Hytch, You trust us with your information. We are
              committed to keeping that trust. That starts with helping You
              understand Our privacy practices.
            </li>
            <li>
              We adhere to the Australian National Privacy Principles
              established by the Privacy Act 1988. This policy sets out how we
              collect and treat your personal information.
            </li>
          </ol>
          <li className="p-title">
            <h2>What Information Do We Collect?</h2>
          </li>
          <ol type="a">
            <li>
              Information is automatically collected from You when you access
              and use the app and its related services and when you enter and/or
              upload information on the app. This information pertains to your
              location, your usage of our services, your transaction history on
              our app, Information about your device/s and your communication
              data.
            </li>
            <li>
              Personal information and data provided by You including but not
              limited to your name, date of birth, height etc.
            </li>
          </ol>
          <li className="p-title">
            <h2>Your Consent and Authorization</h2>
          </li>
          <ol type="a">
            <li>
              By accessing and using Hytch you consent and authorize us to
              collect, use, share, disclose, and publish your personal
              information and data in accordance with Our Privacy Policy.
            </li>
            <li>
              Information collected will be retained by us for such a period of
              time as we are permitted under law and or relevant regulations (45
              days), notwithstanding that You have ceased to be a user of Hytch
              and its services.
            </li>
          </ol>
          <li className="p-title">
            <h2>How We Use Your Information</h2>
          </li>
          <p>
            We may use the information that We collect in the following manner:
          </p>
          <ol type="a">
            <li>
              to serve You better in various ways including (i) Providing
              information and updates about Our services and new opportunities
              (ii) improving Our response to Users’ service requests and Our
              services to be more aligned with the needs of Our users.
            </li>
            <li>
              for purposes of research, marketing, product development and
              planning in order to improve the services provided by Hytch in a
              manner that meets your expectations of a customized and effective
              service.
            </li>
          </ol>
          <li className="p-title">
            <h2>Information Sharing and Disclosure</h2>
          </li>
          <ol type="a">
            <li>
              We do not sell, trade, or transfer your personal information to
              outside parties. This does not include other parties who assist Us
              in operating Hytch, carrying out Our business, or serving Our
              users. Our user information is shared with such parties under an
              agreement to keep such information confidential.
            </li>
            <li>
              We may disclose and provide your information including your
              personal information in order to comply with the law or any
              regulations, to enforce Our app policies, and or to safeguard and
              protect Our rights or the rights of others including property
              rights and public safety.
            </li>
            <li>
              We may provide information on visitors to Our app, which does not
              however disclose their identity, to third parties for marketing,
              advertising or other uses.
            </li>
          </ol>
          <li className="p-title">
            <h2>Security of your Personal Information</h2>
          </li>
          <ol type="a">
            <li>
              Your personal information is protected behind secured networks and
              is only accessible by authorized persons who have special access
              rights to such systems, and who are required to keep such
              information confidential.
            </li>
            <li>
              All transactions are processed through a gateway provider and are
              not stored or processed on our servers.
            </li>
            <li>
              Necessary security measures are in place and get activated
              whenever a user submits or accesses their information on the app.
              This is to safeguard the personal information of users of Hytch
              against unauthorized access.
            </li>
          </ol>
          <li className="p-title">
            <h2>Third-Party Products & Services</h2>
          </li>
          <p>
            Hytch may include the products and services of third parties. The
            websites/apps of these third parties will have their own separate
            and independent privacy policies. We have no responsibility or
            liability for the content and activities of these linked apps.
          </p>
          <li className="p-title">
            <h2>Age Group of Users</h2>
          </li>
          <p>
            You are permitted to use Hytch and its services only if You satisfy
            the eligibility criteria set out in Our Terms and Conditions. If You
            are a Minor and or a person under 18 years of age you are prohibited
            from using Hytch and its services.
          </p>
          <li className="p-title">
            <h2>Amendments to the Privacy Policy</h2>
          </li>
          <p>
            This Privacy Policy may be modified and updated from time to time at
            our sole discretion and without notice to you. The amended Privacy
            Policy will be effective immediately upon posting on Hytch. Your
            continued access and use of the app after the date of posting shall
            be deemed to constitute your acceptance of the amended Privacy
            Policy.
          </p>
          <li className="p-title">
            <h2>How to Contact Us</h2>
          </li>
          <p>
            If you have any questions about this Privacy Policy or the services
            provided by using Hytch, please contact us directly via{" "}
            <a href="mailto:support@hytch.app">support@hytch.app</a>
          </p>
        </ol>
      </div>
      <Copyright />
    </>
  );
};
