import { useCallback, useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import {
  Cake,
  Person,
  ImportContacts,
  LineWeight,
  School,
  Work,
  LocalBar,
  SmokingRooms,
  RestaurantMenu,
  Favorite,
  Close,
  MoreVert,
} from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { capitalize } from "lodash";

import { useAppDispatch } from "store";
import { Match } from "models/match";
import { Profile } from "models/profile";
import { setAppAlert } from "store/application";
import { getMatchAPI } from "services/api/mobile/match";
import ModalNavigationScreen from "../../component/Screen/ModalNavigationScreen";
import GoBackLink from "../../component/Screen/GoBackLink";
import GridDataView, { DataView } from "./components/GridDataView";
import QuestionAnswer from "./components/QuestionAnswer";
import Timeline from "./components/Timeline";
import ProfileImagesView from "./components/ProfileImagesView";

const MatchProfile = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [interestId, setInterestId] = useState<string | null>(null);
  const [match, setMatch] = useState<Match.Match | null>(null);

  const generateDataView = (
    profile: Profile.PublicProfile,
    section: "personalInfo" | "career" | "foodPreference",
  ): DataView => {
    switch (section) {
      case "personalInfo": {
        return [
          { icon: <Cake />, label: profile.age.toString() },
          { icon: <Person />, label: profile.ethnicity.name },
          { icon: <LineWeight />, label: profile.height.toString() },
          { icon: <ImportContacts />, label: profile.religion.name },
        ];
      }
      case "career": {
        return [
          { icon: <School />, label: profile.educationLevel.name },
          { icon: <Work />, label: profile.profession.name },
        ];
      }
      case "foodPreference": {
        return [
          { icon: <LocalBar />, label: capitalize(profile.drinking) },
          { icon: <SmokingRooms />, label: capitalize(profile.smoking) },
          {
            icon: <RestaurantMenu />,
            label: capitalize(profile.foodPreference),
          },
        ];
      }
      default:
        return [];
    }
  };

  const fetchInterest = useCallback(async () => {
    if (interestId === null) {
      return;
    }
    const response = await getMatchAPI(interestId);
    if (!response.success) {
      dispatch(
        setAppAlert({
          severity: "error",
          message: "unable to fetch match profile",
        }),
      );
      return;
    }
    setMatch(response.data);
  }, [interestId]);

  useEffect(() => {
    if (searchParams.has("interestId")) {
      setInterestId(searchParams.get("interestId"));
    }
  }, []);

  useEffect(() => {
    if (interestId !== null) {
      fetchInterest();
    }
  }, [interestId]);

  return (
    <ModalNavigationScreen paddingX={0} paddingY={0}>
      <Grid container direction="column" bgcolor="#E5E5E5" height={1}>
        <Grid item>
          <Paper variant="elevation" elevation={3}>
            <Grid container direction="column" paddingBottom={1}>
              <Grid item>
                <GoBackLink
                  name="Messages"
                  to={`/app/messages/thread?${searchParams.toString()}`}
                />
              </Grid>
              <Grid item container direction="row" paddingX={1}>
                {/* {match !== null && ( */}
                <Grid item xs={6}>
                  {match !== null && (
                    <Typography variant="h5">
                      {`${match.profile.firstName} ${match.profile.lastName}`}
                    </Typography>
                  )}
                </Grid>
                {/* )} */}
                <Grid
                  item
                  xs={6}
                  container
                  direction="row"
                  alignItems="end"
                  justifyContent="end"
                >
                  <Grid item>
                    <MoreVert />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {match !== null && (
          <Grid
            xs
            item
            container
            direction="row"
            paddingX={2}
            sx={{ overflowX: "hidden", overflowY: "scroll" }}
          >
            <Grid item xs={12}>
              <ProfileImagesView profile={match.profile} matchId={match.id} />
            </Grid>
            <Grid item xs={12}>
              <Timeline items={match.timeline} sender={match.profile} />
            </Grid>
            <Grid item xs={12}>
              <GridDataView
                items={generateDataView(match.profile, "personalInfo")}
              />
            </Grid>
            <Grid item xs={12}>
              <GridDataView items={generateDataView(match.profile, "career")} />
            </Grid>
            <Grid item xs={12}>
              <GridDataView
                items={generateDataView(match.profile, "foodPreference")}
              />
            </Grid>
            <Grid item xs={12} paddingY={1}>
              <QuestionAnswer
                question={match.question}
                answer={match.questionResponse}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </ModalNavigationScreen>
  );
};

export default MatchProfile;
