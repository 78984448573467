import adapter from "services/api/mobile/proxy";

export const getGenericListAPI = async () => {
  const response = await adapter.doRequest({
    method: "GET",
    apiVersion: "v1",
    path: "generic/lists",
    authenticated: true,
  });
  return response;
};

export const uploadFileAPI = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await adapter.doRequest({
    method: "POST",
    apiVersion: "v1",
    path: "generic/file_upload",
    body: formData,
    authenticated: true,
  });
  return response;
};
