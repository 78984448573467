/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import Message from "models/message";
import { RootState } from "store";
import {
  getChatMessagesAPI,
  postNewMessageAPI,
} from "services/api/mobile/messages";
import {
  difference,
  differenceBy,
  flattenDeep,
  groupBy,
  isEmpty,
  orderBy,
  uniq,
  uniqBy,
} from "lodash";
import { ApiResponse } from "services/api/mobile/proxy";

const DATE_FORMAT = "YYYY-MM-DD";
const QUERY_DEFAULT_LIMIT = 10;

type MessageThreadProps = {
  chatMessages: Message.ChatMessagesMap;
  currentPage: number | null;
  totalPages: number;
  totalMessages: number;
  loadedLastPage: boolean;
  currentMessage: string;
  hasNewMessages: boolean;
};

const initialState: MessageThreadProps = {
  chatMessages: {},
  currentPage: null,
  totalPages: 0,
  totalMessages: 0,
  loadedLastPage: false,
  currentMessage: "",
  hasNewMessages: false,
};

export const fetchChatMessages = createAsyncThunk(
  "messageThread/fetchChatmessages",
  async (
    { interestId, latestPage }: { interestId: string; latestPage: boolean },
    { getState },
  ) => {
    const { currentPage, chatMessages } = (getState() as RootState)
      .messageThread;
    // const nextPage = currentPage !== null ? currentPage + 1 : 0;
    let nextPage;
    if (latestPage) {
      nextPage = 0;
    } else {
      nextPage = currentPage !== null ? currentPage + 1 : 0;
    }
    const response: Message.GetChatMessagesPayload = await getChatMessagesAPI(
      interestId,
      nextPage,
      QUERY_DEFAULT_LIMIT,
    );
    let tempMessages = uniqBy(
      [...flattenDeep(Object.values(chatMessages)), ...response.results],
      "id",
    );
    tempMessages = orderBy(tempMessages, ["createdAt"], ["asc"]);
    const processedOutput = groupBy(tempMessages, (msg) => {
      return moment(msg.createdAt).format(DATE_FORMAT);
    });
    return {
      delta: processedOutput,
      currentPage:
        response.results.length === response.pageSize
          ? response.pageNum
          : currentPage,
      totalPages: response.totalPages,
      totalMessages: response.totalResults,
      loadedLastPage: response.pageNum + 1 === response.totalPages,
    };
  },
);

export const refreshChatMessages = createAsyncThunk(
  "messageThread/refreshChatMessages",
  async ({ interestId }: { interestId: string }, { getState }) => {
    const rootState = getState() as RootState;
    const { chatMessages } = rootState.messageThread;
    const response: Message.GetChatMessagesPayload = await getChatMessagesAPI(
      interestId,
      0,
      QUERY_DEFAULT_LIMIT,
    );
    const existingMessages = flattenDeep(Object.values(chatMessages));
    const newMessages = differenceBy(response.results, existingMessages, "id");
    if (isEmpty(newMessages)) {
      return {
        chatMessages,
        totalPages: response.totalPages,
        totalMessages: response.totalResults,
        hasNewMessages: false,
      };
    }
    const allMessages = orderBy(
      [...existingMessages, ...newMessages],
      ["createdAt"],
      ["asc"],
    );
    const processedOutput: { [key: string]: Message.ChatMessage[] } = groupBy(
      allMessages,
      (msg) => {
        return moment(msg.createdAt).format(DATE_FORMAT);
      },
    );
    return {
      chatMessages: processedOutput,
      totalPages: response.totalPages,
      totalMessages: response.totalResults,
      hasNewMessages: true,
    };
  },
);

export const sendNewMessage = createAsyncThunk(
  "messageThread/sendNewMessage",
  async ({
    interestId,
    message,
  }: {
    interestId: string;
    message: string;
  }): Promise<ApiResponse> => {
    const response = await postNewMessageAPI(interestId, message);
    return response;
  },
);

const MessageThreadSlice = createSlice({
  name: "messageThread",
  initialState,
  reducers: {
    setCurrentMessage: (state, action) => {
      state.currentMessage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = 0;
    },
    setHasNewMessages: (state, action) => {
      state.hasNewMessages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChatMessages.fulfilled, (state, action) => {
      const { payload } = action;
      state.currentPage = payload.currentPage;
      state.totalPages = payload.totalPages;
      state.totalMessages = payload.totalMessages;
      state.loadedLastPage = payload.loadedLastPage;
      state.chatMessages = payload.delta;
    });
    builder.addCase(fetchChatMessages.rejected, (state, action) => {
      console.log(">>>>>> error occurred: fetchChatMessages >>>> handle later");
    });
    builder.addCase(refreshChatMessages.fulfilled, (state, action) => {
      const { payload } = action;
      state.hasNewMessages = state.hasNewMessages || payload.hasNewMessages;
      if (payload.hasNewMessages) {
        state.chatMessages = payload.chatMessages;
        state.totalMessages = payload.totalMessages;
        state.totalPages = payload.totalPages;
      }
    });
  },
});

export const { setCurrentMessage, setCurrentPage, setHasNewMessages } =
  MessageThreadSlice.actions;
export default MessageThreadSlice.reducer;
