import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import applicationSlice from "store/application";
import MessageThreadReducer from "store/application/MessageThread";
import authSlice from "./auth";
import userSlice from "./features/User";
import createFlowSlice from "./features/CreateFlow";
import MasterDataSlice from "./MasterData";
import InterestReducer from "./application/Interests";
import GenericReducer from "./application/generics";
import EditProfileReducer from "./application/EditProfile";

const authPersistConfig = {
  key: "auth",
  storage,
};

const createFowPersistConfig = {
  key: "createFlow",
  storage,
};

const authPersistedReducer = persistReducer(authPersistConfig, authSlice);
const createFlowPersistedReducer = persistReducer(
  createFowPersistConfig,
  createFlowSlice,
);

export const store = configureStore({
  reducer: {
    auth: authPersistedReducer,
    application: applicationSlice,
    messageThread: MessageThreadReducer,
    interests: InterestReducer,
    user: userSlice,
    createFlow: createFlowPersistedReducer,
    masterData: MasterDataSlice,
    genericData: GenericReducer,
    editProfile: EditProfileReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
