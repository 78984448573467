import moment from "moment";
import { Grid, Typography } from "@mui/material";

import Message from "models/message";
import { formatDateDurationToReadable } from "components/templates/Home/util/formatter";
import ChatMessage from "../Message";

type DailyMessageViewProps = {
  date: string;
  messages: Array<Message.ChatMessage>;
};

const DailyMessageView = ({ date, messages }: DailyMessageViewProps) => {
  const dateMoment = moment(date);
  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      paddingX={2}
      paddingTop={1}
      width={1}
      marginTop={2}
    >
      <Grid item marginBottom={1.5}>
        <Grid container>
          <Typography
            variant="caption"
            color="#333333"
            fontWeight={300}
            align="center"
            width={1}
          >
            {formatDateDurationToReadable(dateMoment)}
          </Typography>
        </Grid>
      </Grid>
      {messages.map((msg) => (
        <Grid item>
          <ChatMessage key={msg.id} message={msg} />
        </Grid>
      ))}
    </Grid>
  );
};

export default DailyMessageView;
