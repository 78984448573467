import { useEffect, useState } from "react";
import moment from "moment";
import { isEmpty, range } from "lodash";
import { Select } from "components";

const DATE_PATTERN = "YYYY-MM-DD";

const monthDefs: SelectableMonth[] = moment.monthsShort().map((item, idx) => {
  return { label: item, value: idx + 1 };
});

type Props = {
  startDate: string;
  endDate: string;
  value: string;
  onChange: (selectedValue: string) => void;
};

type SelectableMonth = {
  value: number;
  label: string;
};

const DatePicker = ({ startDate, endDate, value, onChange }: Props) => {
  const startMoment = moment(startDate, DATE_PATTERN);
  const endMoment = moment(endDate, DATE_PATTERN);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(0);
  const [selectableYears, setSelectableYears] = useState<number[]>([]);
  const [selectableMonths, setSelectableMonth] = useState<SelectableMonth[]>(
    [],
  );
  const [selectableDays, setSelectableDays] = useState<number[]>([]);

  const generateSelectableMonths = () => {
    if (year === startMoment.year()) {
      setSelectableMonth(monthDefs.slice(startMoment.month()));
      return;
    }
    if (year === endMoment.year()) {
      const temp: SelectableMonth[] = [];
      monthDefs.forEach((def) => {
        if (def.value <= endMoment.month() + 1) {
          temp.push(def);
        }
      });
      setSelectableMonth(temp);
      return;
    }
    setSelectableMonth(monthDefs);
  };

  const generateSelectableDays = () => {
    const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
    if (year === startMoment.year() && month === startMoment.month() + 1) {
      setSelectableDays(range(startMoment.date(), daysInMonth + 1));
      return;
    }
    if (year === endMoment.year() && month === endMoment.month() + 1) {
      setSelectableDays(range(1, endMoment.date()));
      return;
    }
    setSelectableDays(range(1, daysInMonth + 1));
  };

  useEffect(() => {
    const startYear = startMoment.year();
    const endYear = endMoment.year();
    setSelectableYears(range(startYear, endYear + 1));

    if (isEmpty(value)) {
      setYear(startYear);
      setMonth(startMoment.month());
      setDay(startMoment.date());
    } else {
      const valueDate = moment(value, DATE_PATTERN);
      setYear(valueDate.year());
      setMonth(valueDate.month() + 1);
      setDay(valueDate.date());
    }
  }, []);

  useEffect(() => {
    generateSelectableMonths();
  }, [year]);

  useEffect(() => {
    generateSelectableDays();
  }, [year, month]);

  useEffect(() => {
    const output = moment(`${year}-${month}-${day}`).format(DATE_PATTERN);
    onChange(output);
  }, [year, month, day]);

  return (
    <div className="datepicker-wrapper">
      <Select
        label=""
        id="day"
        name="day"
        value={day.toString()}
        onChange={(event) => {
          setDay(parseInt(event.target.value, 10));
        }}
        isError={false}
      >
        {selectableDays.map((option) => (
          <option key={option.toString()} value={option.toString()}>
            {option}
          </option>
        ))}
      </Select>

      <Select
        label=""
        id="month"
        name="month"
        value={month.toString()}
        onChange={(event) => {
          setMonth(parseInt(event.target.value, 10));
        }}
        isError={false}
      >
        {selectableMonths.map((option) => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>

      <Select
        label=""
        id="year"
        name="year"
        value={year.toString()}
        onChange={(event) => {
          setYear(parseInt(event.target.value, 10));
        }}
        isError={false}
      >
        {selectableYears.map((option) => (
          <option key={option.toString()} value={option}>
            {option.toString()}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default DatePicker;
