import {
  CameraAlt,
  CloseFullscreen,
  HdrAuto,
  Close,
} from "@mui/icons-material";
import { CircularProgress, Fab, Grid, IconButton } from "@mui/material";
import { fontSize } from "@mui/system";
import { useEffect, useState } from "react";

type Props = {
  slotId: number;
  image: string | null;
  handleDownload?: (
    image: string,
    setValue: React.Dispatch<React.SetStateAction<string | undefined>>,
    setDownload: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;
  onSelect?: (slotId: number, file: File) => void;
  onDelete?: (slotId: number, file: string) => void;
};

const ActionableImage = ({
  slotId,
  image,
  handleDownload,
  onSelect,
  onDelete,
}: Props) => {
  const [data, setData] = useState<string | undefined>(undefined);
  const [downloading, setDownloading] = useState(false);
  const [uploadable, setUploadable] = useState(false);

  useEffect(() => {
    if (image !== null && handleDownload !== undefined && !downloading) {
      setDownloading(true);
    } else {
      setUploadable(true);
    }
  }, []);

  useEffect(() => {
    if (image !== null && handleDownload !== undefined && downloading) {
      console.log(">>> downloading >>>> ", image);
      handleDownload(image, setData, setDownloading);
    }
  }, [downloading]);

  return (
    <Grid
      container
      direction="column"
      height={100}
      bgcolor="white"
      justifyContent="center"
      alignItems="center"
      border={data !== undefined ? 0 : 1}
      sx={
        data === undefined
          ? {
              borderStyle: "dashed",
              borderColor: "#828282",
              borderRadius: 3,
            }
          : {}
      }
    >
      {data === undefined ? (
        <Grid item>
          {downloading ? (
            <CircularProgress size={20} sx={{ color: "#9575CD" }} />
          ) : (
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <CameraAlt sx={{ color: "#9575CD" }} />
              <input
                type="file"
                hidden
                onChange={(e) => {
                  if (onSelect !== undefined && e.target.files !== null) {
                    const file = e.target.files[0];
                    setData(URL.createObjectURL(file));
                    onSelect(slotId, file);
                  }
                }}
              />
            </IconButton>
          )}
        </Grid>
      ) : (
        <Grid item container direction="column" spacing={0}>
          <Grid item xs={12}>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                setData(undefined);
                if (onDelete !== undefined && image !== null) {
                  onDelete(slotId, image);
                }
              }}
              sx={{
                backgroundColor: "#9575CD",
                marginLeft: 10,
                marginBottom: -4,
              }}
              size="small"
            >
              <Close
                sx={{
                  fontSize: 14,
                  color: "white",
                }}
              />
            </IconButton>
            <img
              src={data}
              alt="slot"
              style={{
                height: 100,
                width: 100,
                borderRadius: 10,
                border: 1,
                padding: 1,
                objectFit: "cover",
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ActionableImage;
