import { Chip, Grid, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";

type Option = {
  id: number | string;
  name: string;
  sortOrder?: number;
};
type Props = {
  label: string;
  items: Option[];
  value: string | number;
  onChange?: (value: string | number) => void;
};

const ChipSelector = ({ label, items, value, onChange }: Props) => {
  return (
    <Grid container direction="column" marginY={0.5}>
      <Grid item>
        <Typography
          variant="body1"
          color="#333333"
          fontWeight={600}
          fontSize={14}
          paddingY={0.5}
        >
          {label}
        </Typography>
        <Grid item container direction="row" spacing={2}>
          {items.map((item) => (
            <Grid key={`chip-selector-${item.id}`} item>
              <Chip
                icon={item.id === value ? <Check /> : undefined}
                label={item.name}
                onClick={(e) => {
                  e.preventDefault();
                  if (onChange !== undefined) {
                    onChange(item.id);
                  }
                }}
                sx={{
                  color: "#333333",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChipSelector;
