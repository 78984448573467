/* eslint-disable operator-assignment, radix */
import { request } from "services/api";
import qs from "qs";
import { AlphabetKey } from "models/enum";
import ApiProxy from "services/api/mobile/proxy";

const CreateFlow = {
  phoneNumber: (number: string) => {
    const { ALPHABET } = AlphabetKey;

    let bb = Math.round(new Date().getTime() / 1000);
    const tso = bb;
    let aa = "";
    while (bb > 0) {
      const tss = ALPHABET[bb % ALPHABET.length].toString();
      aa = aa + tss;
      // @ts-ignore
      bb = parseInt(bb / ALPHABET.length);
    }

    const phoneNumber = qs.stringify({
      number,
    });

    return request.post<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/generic/phonenumbers`,
      phoneNumber,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-timestamp": tso,
          "x-client-id": aa,
        },
      },
    );
  },

  verifiedPhoneNumber: (phoneId: string, otpNumber: string) => {
    const otpCode = qs.stringify({
      code: otpNumber,
    });

    return request.post<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/generic/phonenumbers/${phoneId}/verify`,
      otpCode,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );
  },

  genericFileUpload: (uploadData: any) => {
    return request.postCustom<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/generic/file_upload`,
      uploadData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  },

  userLoginOrRegister: (userData: { phone_id: string; code: string }) => {
    return request.post<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/login`,
      qs.stringify(userData),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );
  },

  updateTempProfile: (profileData: any) => {
    return request.post<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/temp_profile`,
      JSON.stringify(profileData),
    );
  },

  userAccount: (token?: string) => {
    return request.get<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/account`,
      token && {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  savedTempProfileData: (token?: string) => {
    return request.get<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/temp_profile`,
      token && {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  checkUserExists: (phoneNo: string) =>
    request.getCustom<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/check_user?number=${phoneNo}`,
    ),

  createProfile: async (tempProfile: Record<string, any>) => {
    const apiResponse = await ApiProxy.doRequest({
      method: "POST",
      apiVersion: "v1",
      path: "profile",
      authenticated: true,
      body: tempProfile,
      contentType: "application/json",
    });
    return apiResponse;
  },
};

export default CreateFlow;
