import { Button, Typography } from "components";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();

  return (
    <div className="register-container section-common">
      <Typography variant="h1" style={{ marginBottom: "4rem" }}>
        Ready to start your journey?
      </Typography>
      <Button
        text="Create an Account"
        onClick={() => {
          window.open(process.env.REACT_APP_WEB_APP_URL, "_blank");
        }}
        shape="square"
      />
    </div>
  );
};

export default Register;
