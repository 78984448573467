import { useEffect, useState } from "react";
import { Grid, Avatar, Divider } from "@mui/material";

import { useAppDispatch, useAppSelector } from "store";
import { Profile } from "models/profile";
import { updateProfile, fetchAuthProfile } from "store/application";
import TextInput from "components/templates/Home/component/FormControl/TextInput";
import Dropdown from "components/templates/Home/component/FormControl/Dropdown";
import ChipSelector from "components/templates/Home/component/FormControl/ChipSelector";
import FormResponder from "components/templates/Home/component/Button/FormResponder";

type Props = {
  profile: Profile.Profile;
  avatar: string | undefined;
};

const EditProfileFormView = ({ profile, avatar }: Props) => {
  const dispatch = useAppDispatch();
  const { authAvatar } = useAppSelector((state) => state.application);
  const {
    ethnicities,
    religions,
    educationLevels,
    professions,
    drinkingPreferences,
    foodPreferences,
    smokingPreferences,
  } = useAppSelector((state) => state.genericData);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [height, setHeight] = useState(0);
  const [ethnicity, setEthnicity] = useState("");
  const [religion, setReligion] = useState("");
  const [eduction, setEducation] = useState(0);
  const [profession, setProfession] = useState(0);
  const [drinking, setDrinking] = useState("");
  const [smoking, setSmoking] = useState("");
  const [foodpreference, setFoodPreference] = useState("");
  const [professionOther, setProfessionOther] = useState("");

  const makeStatesToDefaults = () => {
    setFirstName(profile.firstName);
    setLastName(profile.lastName);
    setEthnicity(profile.ethnicity.id);
    setReligion(profile.religion.id);
    setEducation(profile.educationLevel.id);
    setProfession(profile.profession.id);
    setDrinking(profile.drinking);
    setSmoking(profile.smoking);
    setFoodPreference(profile.foodPreference);
  };

  const handleSave = () => {
    const payload = {
      firstName,
      lastName,
      height,
      ethnicityId: ethnicity,
      religionId: religion,
      educationLevelId: eduction,
      professionId: profession,
      professionOther: profession === 1 ? professionOther : "",
      drinking,
      smoking,
      foodPreference: foodpreference,
    };
    dispatch(updateProfile(payload)).then(() => {
      dispatch(fetchAuthProfile());
    });
  };

  useEffect(() => {
    makeStatesToDefaults();
  }, []);

  return (
    <Grid
      container
      direction="column"
      bgcolor="#FFFFFF"
      justifyItems="stretch"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        paddingBottom={3}
      >
        <Grid item>
          <Avatar
            sx={{ width: 150, height: 150 }}
            variant="circular"
            src={authAvatar || undefined}
          />
        </Grid>
      </Grid>
      <Divider sx={{ backgroundColor: "#D4D4D4", width: 0.9, height: 0.5 }} />
      <Grid item paddingY={2} width={1} paddingX={2}>
        <Grid container direction="column">
          <TextInput
            label="First Name"
            value={firstName}
            onChange={setFirstName}
          />
          <TextInput
            label="Last Name"
            value={lastName}
            onChange={setLastName}
          />
          <Dropdown
            label="Ethnicity"
            items={ethnicities}
            value={ethnicity}
            onChange={(value) => {
              if (typeof value === "string") {
                setEthnicity(value);
              }
            }}
          />
          <Dropdown
            label="Religion"
            items={religions}
            value={religion}
            onChange={(value) => {
              if (typeof value === "string") {
                setReligion(value);
              }
            }}
          />
          <Dropdown
            label="Education"
            items={educationLevels}
            value={eduction}
            onChange={(value) => {
              if (typeof value === "number") {
                setEducation(value);
              }
            }}
          />
          <Dropdown
            label="Profession"
            items={professions}
            value={profession}
            onChange={(value) => {
              if (typeof value === "number") {
                setProfession(value);
              }
            }}
          />
          {profession === 1 && (
            <TextInput
              label="Profession Other"
              value={professionOther}
              onChange={(value) => {
                const regex = /^[A-Za-z]+$/;
                if (value.length !== 0 && !regex.test(value.toString())) {
                  return;
                }
                setProfessionOther(value);
              }}
            />
          )}
          <ChipSelector
            label="Drinking"
            items={drinkingPreferences}
            value={drinking}
            onChange={(value) => {
              if (typeof value === "string") {
                setDrinking(value);
              }
            }}
          />
          <ChipSelector
            label="Smoking"
            items={smokingPreferences}
            value={smoking}
            onChange={(value) => {
              if (typeof value === "string") {
                setSmoking(value);
              }
            }}
          />
          <ChipSelector
            label="Food Preferences"
            items={foodPreferences}
            value={foodpreference}
            onChange={(value) => {
              if (typeof value === "string") {
                setFoodPreference(value);
              }
            }}
          />
          <Grid container direction="column">
            <Grid item marginY={2}>
              <FormResponder
                postiveRespond={{
                  label: "Save Changes",
                  handler: () => {
                    handleSave();
                  },
                }}
                negativeRespond={{
                  label: "Discard Changes",
                  handler: () => {
                    makeStatesToDefaults();
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditProfileFormView;
