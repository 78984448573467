import React from "react";
import classNames from "classnames";
import { GoPrimitiveDot } from "react-icons/go";
import "./index.scss";

interface IProps {
  active?: boolean;
  icon?: any;
}

export const BreadcrumbItem = ({ active, icon }: IProps) => {
  return (
    <div className="breadcrum-item">
      {icon ? (
        <div className="breadcrum-icon">{icon}</div>
      ) : (
        <GoPrimitiveDot
          className={classNames("breadcrum-dot", active ? "bi-dot-active" : "")}
        />
      )}
    </div>
  );
};
