import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Match } from "models/match";
import { getInterestsAPI } from "services/api/mobile/match";
import { setAppAlert } from "store/application";

type InterestState = {
  interests: Array<Match.Match>;
};
const initialState: InterestState = {
  interests: [],
};

export const fetchInterests = createAsyncThunk(
  "Interests/fetchInterests",
  async (arg, { dispatch }) => {
    const response = await getInterestsAPI();
    if (!response.success) {
      dispatch(
        setAppAlert({ severity: "error", message: "Unable to fetch matches" }),
      );
      return [];
    }
    return response.data;
  },
);

const InterestSlice = createSlice({
  name: "Interests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInterests.fulfilled, (state, action) => {
      state.interests = action.payload;
    });
  },
});

export default InterestSlice.reducer;
