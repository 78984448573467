import {
  Splash,
  LoginSignup,
  Otp,
  PersonalDetails,
  PersonalDetailsTwo,
  PersonalBackground,
  PersonalQuestion,
  PersonalLocation,
  ProfileReview,
  Success,
  NavBar,
} from "components";
import { PersonalEducation } from "components/templates/CreateFlow/PersonalEducation";
import { PersonalHabbits } from "components/templates/CreateFlow/PersonalHabbits";
import { PersonalPhotos } from "components/templates/CreateFlow/PersonalPhotos";
import { CreateFlowScreen } from "models/enum";
import { useCallback } from "react";
import { RootState, useAppSelector } from "store";
import "./index.scss";

export const Register = () => {
  const screen = useAppSelector(
    (state: RootState) => state.createFlow.currentScreen,
  );

  const screenRender = useCallback(() => {
    switch (screen) {
      case CreateFlowScreen.LOGIN_SIGNIN:
        return <LoginSignup />;

      case CreateFlowScreen.OTP:
        return <Otp />;

      case CreateFlowScreen.ADD_NAME:
        return <PersonalDetails />;

      case CreateFlowScreen.ADD_BIRTHDAY:
        return <PersonalDetailsTwo />;

      case CreateFlowScreen.BACKGROUND:
        return <PersonalBackground />;

      case CreateFlowScreen.EDUCATION:
        return <PersonalEducation />;

      case CreateFlowScreen.HABBITS:
        return <PersonalHabbits />;

      case CreateFlowScreen.LOCATION:
        return <PersonalLocation />;

      case CreateFlowScreen.QUESTION:
        return <PersonalQuestion />;

      case CreateFlowScreen.PHOTOS:
        return <PersonalPhotos />;

      case CreateFlowScreen.PROFILE_REVIEW:
        return <ProfileReview />;

      case CreateFlowScreen.SUCCESS:
        return <Success />;

      default:
        <Splash />;
    }
  }, [screen]);

  return (
    <>
      <NavBar />
      <div className="register-wrapper">
        <div className="register-inner">{screenRender()}</div>
      </div>
    </>
  );
};
