import {
  Button,
  Typography,
  CountryCodeInput,
  Input,
  BreadcrumbItem,
} from "components";
import { CreateFlowScreen, LoadingStatus } from "models/enum";
import { useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "store";
import {
  addPhoneNumber,
  setCurrentScreen,
  setLoginDetails,
} from "store/features/CreateFlow";
import { MdLocalPhone } from "react-icons/md";
import "./index.scss";

export const LoginSignup = () => {
  const dispatch = useAppDispatch();

  const [isError, setIsError] = useState(false);

  const loginDetails = useAppSelector(
    (state: RootState) => state.createFlow.loginDetails,
  );
  const loadingStatus = useAppSelector(
    (state: RootState) => state.createFlow.loading,
  );

  const handleNext = () => {
    const phoneno = /^\d{9}$/;
    if (loginDetails.phoneNumber.match(phoneno)) {
      dispatch(addPhoneNumber())
        .then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            dispatch(setCurrentScreen(CreateFlowScreen.OTP));
          }
        })
        .catch((error) => console.log(error));
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  const handleBack = () => {
    dispatch(setCurrentScreen(CreateFlowScreen.LOGIN_SIGNIN));
  };

  const handleOnCountryCodeChange = (value: any) => {
    dispatch(setLoginDetails({ countryCode: value.phone }));
  };

  const handlePhoneNumber = (event: any) => {
    dispatch(setLoginDetails({ phoneNumber: event.target.value }));
  };

  return (
    <div className="login-signup-screen flow-step">
      <div className="breadcrum-container">
        <BreadcrumbItem icon={<MdLocalPhone />} />
        <BreadcrumbItem />
        <BreadcrumbItem />
        <BreadcrumbItem />
        <BreadcrumbItem />
        <BreadcrumbItem />
        <BreadcrumbItem />
        <BreadcrumbItem />
        <BreadcrumbItem />
        <BreadcrumbItem />
      </div>

      <div className="create-flow-content">
        <Typography variant="h1" style={{ marginBottom: "2rem" }}>
          Enter your <br />
          phone number
        </Typography>

        <div className="phone-inputs">
          <CountryCodeInput
            country={loginDetails.countryCode}
            value={loginDetails.countryCode}
            onChange={(value: string) => handleOnCountryCodeChange(value)}
          />
          <Input
            name="phone-number"
            type="number"
            value={loginDetails.phoneNumber}
            onChange={(event) => handlePhoneNumber(event)}
            isError={isError}
            errorMessage="Please enter valid phone number"
          />
        </div>
      </div>

      <div className="action-button">
        <Button
          text="Next"
          onClick={handleNext}
          loading={loadingStatus === LoadingStatus.PENDING}
        />
        <Button text="Back" buttonType="text" onClick={handleBack} />
      </div>
    </div>
  );
};
