import { Button, Typography } from "components";
import { CgCheckO } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import "./index.scss";

export const Success = () => {
  const navigate = useNavigate();

  return (
    <div className="success-screen">
      <CgCheckO />
      <Typography
        variant="h1"
        style={{ marginBottom: "1rem", marginTop: "2rem" }}
      >
        Account Created
      </Typography>
      <Typography variant="h5">
        Your account has ben created. Please download the app.
      </Typography>

      <div className="success-footer">
        <Button text="Go to Home" onClick={() => navigate("/")} />
      </div>
    </div>
  );
};
