import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { fetchAuthProfile, submitForReview } from "store/application";
import { useNavigate } from "react-router-dom";
import { ApiResponse } from "services/api/mobile/proxy";
import ProfileView from "./ProfileView";

const Loading: React.FC = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      height={1}
    >
      <CircularProgress thickness={5} sx={{ color: "#ae6ae1" }} />
    </Grid>
  );
};

export const ProfileReview: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { authProfile } = useAppSelector((state) => state.application);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (authProfile === null) {
      dispatch(fetchAuthProfile());
    }
    setLoading(false);
  }, [authProfile]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      justifyContent="center"
      height={1}
      bgcolor="#EDEDED"
      paddingY={2}
      paddingX={2}
      sx={{ overflowY: "scroll" }}
    >
      <Grid item xs={12}>
        <Typography variant="h5" textAlign="center">
          Submit your details
        </Typography>
      </Grid>
      {authProfile !== null && (
        <>
          <Grid item xs={12} md={10} lg={10}>
            <ProfileView profile={authProfile} />
          </Grid>
          <Grid item xs={12} md={10} lg={10} container justifyContent="center">
            <Button
              variant="contained"
              sx={{ backgroundColor: "#ae6ae1", paddingY: 1, borderRadius: 5 }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(submitForReview()).then(({ payload: response }) => {
                  const apiResponse = response as ApiResponse;
                  if (apiResponse.success) {
                    navigate("/app");
                  }
                });
              }}
            >
              Submit for review
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};
