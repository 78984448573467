import { ReactNode, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "store";
import { Match } from "models/match";
import { fetchMatches } from "store/application";
import BottomNavigationScreen from "../../component/Screen/BottomNavigationScreen";
import ContactListItem from "./components/ContactListItem";
import ContactListItemSkeleton from "./components/ContactListItem/Skeleton";
import ProfileReviewSubmission from "../../views/ProfileReviewSubmission";

const NUM_OF_SKETETONS = 5;

const Skeletons = () => {
  return (
    <>
      {Array.from(Array(NUM_OF_SKETETONS).keys()).map((idx) => (
        <ContactListItemSkeleton key={`message-skeleton-${idx}`} />
      ))}
    </>
  );
};

const MatchList = ({ matches }: { matches: Match.Match[] }) => {
  return (
    <>
      {matches.map((match) => (
        <Grid item>
          <ContactListItem match={match} key={match.id} />
        </Grid>
      ))}
    </>
  );
};

const MessagePage = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { authProfile } = useAppSelector((state) => state.application);

  const { matches } = useAppSelector((state) => state.application);

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (!loading) {
      return;
    }
    dispatch(fetchMatches()).finally(() => {
      setLoading(false);
    });
  }, [loading]);

  if (loading) {
    return (
      <BottomNavigationScreen name="Matches">
        <Grid container direction="column" paddingY={1}>
          <Skeletons />
        </Grid>
      </BottomNavigationScreen>
    );
  }

  if (matches.length === 0) {
    return (
      <BottomNavigationScreen name="Matches">
        <Grid
          container
          direction="column"
          height={1}
          justifyContent="center"
          alignItems="center"
        >
          <Typography align="center">
            No messages at the moment. Your matches and messages will appear
            here.
          </Typography>
        </Grid>
      </BottomNavigationScreen>
    );
  }

  return (
    <BottomNavigationScreen name="Matches">
      <Grid container direction="column">
        <MatchList matches={matches} />
      </Grid>
    </BottomNavigationScreen>
  );
};

export default MessagePage;
