import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { isEmpty } from "lodash";
import {
  Button,
  Typography,
  Input,
  BreadcrumbItem,
  AlertMessage,
  Success,
} from "components";
import { CreateFlowScreen, LoadingStatus } from "models/enum";
import { RootState, useAppDispatch, useAppSelector } from "store";
import {
  addPhoneNumber,
  getTempProfile,
  setCurrentScreen,
  setLoginDetails,
  setProfileDetails,
} from "store/features/CreateFlow";
import { setToken } from "store/auth";
import agent from "services";
import { useNavigate } from "react-router-dom";
import "./index.scss";

export const Otp = () => {
  const initialCountDown = 35;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isUserAvailable, setIsUserAvailable] = useState<boolean | null>(null);
  const [isVerified, setIsVerified] = useState("");
  const [hasAccount, setHasAccount] = useState<boolean>(false);
  const [otpError, setOtpError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(initialCountDown);

  const loginDetails = useAppSelector(
    (state: RootState) => state.createFlow.loginDetails,
  );
  const loadingStatus = useAppSelector(
    (state: RootState) => state.createFlow.loading,
  );

  const handleNext = () => {
    dispatch(setCurrentScreen(CreateFlowScreen.ADD_NAME));
  };

  const handleBack = () => {
    dispatch(setCurrentScreen(CreateFlowScreen.LOGIN_SIGNIN));
  };

  const handleOtpChange = (otp: number) => {
    dispatch(setLoginDetails({ otp }));
  };

  const handleCheckUserExists = async () => {
    if (loginDetails.phoneNumber) {
      const phoneNo = `+${loginDetails.countryCode}${loginDetails.phoneNumber}`;
      await agent.CreateFlow.checkUserExists(phoneNo).then((response) => {
        if (response.status === 200) {
          setIsUserAvailable(true);
        } else if (response.status === 204) {
          setIsUserAvailable(false);
        }
      });
    } else {
      dispatch(setCurrentScreen(CreateFlowScreen.LOGIN_SIGNIN));
    }
  };

  const checkUserExists = async () => {
    const { countryCode, phoneNumber } = loginDetails;
    const fullPhoneNumber = `${countryCode}${phoneNumber}`;
    const response = await agent.CreateFlow.checkUserExists(fullPhoneNumber);
    if (response.status === 200) {
      return true;
    }
    return false;
  };

  const handleRetryOtp = async () => {
    dispatch(setLoginDetails({ otp: "" }));
    dispatch(addPhoneNumber());
    setTimeLeft(initialCountDown);
  };

  const navigateToNextPage = (tempProfile: { [key: string]: any }) => {
    const meta: { [key in CreateFlowScreen]: string[] } = {
      Welcome: [],
      LoginSignup: [],
      otp: [],
      ProfileReview: [],
      AddName: ["first_name", "last_name", "gender"],
      AddBirthday: ["dob", "height"],
      PersonalBackground: ["ethnicity_id", "religion_id"],
      PersonalEducation: ["education_level_id", "profession_id"],
      PersonalHabbits: ["food_preference", "smoking", "drinking"],
      PersonalLocation: [
        "resident_city",
        "resident_country",
        "resident_city_lat",
        "resident_city_lon",
      ],
      PersonalQuestion: ["match_question"],
      PersonalPhotos: ["images"],
      Success: [],
    };
    const ignoredPaths: CreateFlowScreen[] = [
      CreateFlowScreen.WELCOME,
      CreateFlowScreen.LOGIN_SIGNIN,
      CreateFlowScreen.SUCCESS,
    ];
    const metaEntries = Object.entries(meta);
    let current = 0;
    while (current < metaEntries.length) {
      const currentEntry = metaEntries[current];
      const [screen, formFields] = currentEntry;
      if (!ignoredPaths.includes(screen as CreateFlowScreen)) {
        const formValues: Array<string | undefined> = formFields.map(
          (field) => tempProfile[field],
        );
        const shouldRedirect = formValues.some((value) => {
          if (typeof value === "number") {
            return false;
          }
          return isEmpty(value);
        });
        if (shouldRedirect) {
          setIsLoading(false);
          dispatch(setCurrentScreen(screen));
          return;
        }
      }
      current += 1;
    }
    setIsLoading(false);
    dispatch(setCurrentScreen(CreateFlowScreen.PHOTOS));
  };

  const verifyOTP = async () => {
    try {
      setIsLoading(true);
      if (loginDetails.otp === undefined || loginDetails.otp.length < 6) {
        return;
      }
      const response = await agent.CreateFlow.verifiedPhoneNumber(
        loginDetails.phoneID,
        loginDetails.otp,
      );
      dispatch(setLoginDetails({ verifiedPhoneID: response.id }));
      const loginResponse = await agent.CreateFlow.userLoginOrRegister({
        phone_id: response.id,
        code: "DEBUG",
      });
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id: accessToken } = loginResponse;
      localStorage.setItem("token", JSON.stringify(accessToken));
      dispatch(setToken(accessToken));
      const isNewUser = !(await checkUserExists());
      if (isNewUser) {
        dispatch(setCurrentScreen(CreateFlowScreen.ADD_NAME));
        return;
      }
      const userAccount = await agent.CreateFlow.userAccount(accessToken);
      localStorage.setItem("authAccount", JSON.stringify(userAccount));

      if (userAccount.profile_created) {
        if (userAccount.profile_status === "CREATED") {
          dispatch(setCurrentScreen(CreateFlowScreen.PROFILE_REVIEW));
          return;
        }
        navigate("/app");
      } else {
        try {
          const tempProfile = await agent.CreateFlow.savedTempProfileData(
            accessToken,
          );
          dispatch(setProfileDetails(tempProfile));
          navigateToNextPage(tempProfile);
        } catch (e) {
          dispatch(setCurrentScreen(CreateFlowScreen.ADD_NAME));
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("verify OTP error! ", e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(setLoginDetails({ otp: "" }));
    handleCheckUserExists();
  }, []);

  useEffect(() => {
    if (loginDetails.otp?.length === 6) {
      verifyOTP();
    } else {
      setOtpError("");
    }
  }, [loginDetails.otp]);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(0);
    }

    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const inputStyle = {
    width: "66px",
    height: "80px",
    backgroundColor: "#EDEDED",
    margin: "0 7px",
    fontSize: "1.6rem",
    border: "none",
    borderRadius: "6px",
  };

  const smallScreenStyle = {
    width: "46px",
    height: "60px",
  };

  return (
    <div className="login-signup-screen flow-step">
      <div className="create-flow-content">
        <div className="breadcrum-container">
          <BreadcrumbItem />
        </div>

        <Typography variant="h1">
          Enter your <br />
          verification code
        </Typography>

        <div className="otp-inputs">
          <div className="ref-code-wrapper">
            <Typography variant="baseRegular">Your phone number:</Typography>
            <Input
              name="ref-code"
              type="text"
              value={`+${loginDetails.countryCode}${loginDetails.phoneNumber}`}
              onChange={(event) => {}}
            />
          </div>

          <Typography variant="baseRegular">Enter OTP</Typography>
          <OtpInput
            value={loginDetails.otp}
            onChange={handleOtpChange}
            numInputs={6}
            isDisabled={isVerified !== ""}
            inputStyle={{
              ...inputStyle,
              ...(window.innerWidth <= 600 ? smallScreenStyle : {}),
            }}
          />
          <div className="retry-otp">
            {isVerified ? (
              <AlertMessage
                variant="success"
                message={isVerified}
                styles={{ marginBottom: "1rem" }}
              />
            ) : (
              <>
                {otpError && (
                  <AlertMessage
                    variant="error"
                    message={`${otpError}. Try again.`}
                    styles={{ marginBottom: "1rem" }}
                  />
                )}

                <Typography variant="baseRegular">
                  Didn’t recieve the code?
                </Typography>
                <Button
                  text={timeLeft === 0 ? "Retry" : `Retry in ${timeLeft}`}
                  buttonType="text"
                  onClick={handleRetryOtp}
                  disabled={timeLeft !== 0}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="action-button">
        {hasAccount ? (
          <Button text="Download App" onClick={() => navigate("/")} />
        ) : (
          <Button
            text="Next"
            onClick={handleNext}
            loading={loadingStatus === LoadingStatus.PENDING || isLoading}
            disabled={!isVerified}
          />
        )}
        <Button text="Back" buttonType="text" onClick={handleBack} />
      </div>
    </div>
  );
};
