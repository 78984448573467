import { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Textarea,
  BreadcrumbItem,
  Select,
} from "components";
import { CreateFlowScreen } from "models/enum";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { setCurrentScreen, setProfileDetails } from "store/features/CreateFlow";
import { getMatchQuestions } from "store/MasterData";
import agent from "services";
import { BsQuestionLg } from "react-icons/bs";
import "./index.scss";

export const PersonalQuestion = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    questionError: false,
    questionErrorMsg: "",
  });

  useEffect(() => {
    dispatch(getMatchQuestions());
  }, []);

  const profile = useAppSelector(
    (state: RootState) => state.createFlow.profile,
  );

  const matchQuestions = useAppSelector(
    (state: RootState) => state.masterData.matchQuestions,
  );

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: string,
  ) => {
    dispatch(setProfileDetails({ [prop]: event.target.value }));
  };

  const handleOnSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: string,
  ) => {
    dispatch(setProfileDetails({ [prop]: event.target.value }));
  };

  const handleBack = () => {
    dispatch(setCurrentScreen(CreateFlowScreen.LOCATION));
  };

  const validate = () => {
    const status = {
      questionError: false,
      questionErrorMsg: "",
    };

    if (profile.match_question === "") {
      status.questionError = true;
      status.questionErrorMsg = "Please enter your question";
    } else {
      status.questionError = false;
      status.questionErrorMsg = "";
    }

    if (status.questionError) {
      setErrors(status);
      return false;
    }
    setErrors(status);
    return true;
  };

  const handleNext = async () => {
    if (validate()) {
      setIsLoading(true);
      const saveTempUserData = {
        first_name: profile.first_name,
        last_name: profile.last_name,
        gender: profile.gender,
        dob: profile.dob,
        height: profile.height,
        ethnicity_id: profile.ethnicity_id,
        religion_id: profile.religion_id,
        education_level_id: profile.education_level_id,
        profession_id: profile.profession_id,
        profession_other: profile.profession_other,
        drinking: profile.drinking,
        smoking: profile.smoking,
        food_preference: profile.food_preference,
        resident_city: profile.resident_city,
        resident_city_lat: profile.resident_city_lat,
        resident_city_lon: profile.resident_city_lon,
        resident_country: profile.resident_country,
        region_id: profile.region_id,
        match_question: profile.match_question,
      };

      await agent.CreateFlow.updateTempProfile(saveTempUserData)
        .then(() => {
          setIsLoading(false);
          dispatch(setCurrentScreen(CreateFlowScreen.PHOTOS));
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="login-signup-screen flow-step">
      <div className="create-flow-content">
        <div className="breadcrum-container">
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem icon={<BsQuestionLg />} />
          <BreadcrumbItem />
        </div>
        <Typography variant="h1" style={{ marginBottom: "2rem" }}>
          Ask a question <br /> before you match
        </Typography>

        <Typography variant="baseRegular" style={{ marginBottom: "0.6rem" }}>
          Other users would need to answer your question before they can send
          you a request. Make sure it’s a fun question!
        </Typography>
        <div className="inputs-wrapper">
          <Select
            label=""
            id="match_question"
            name="match_question"
            value={profile.match_question}
            onChange={(event) => handleOnSelect(event, "match_question")}
            isError={errors.questionError}
            errorMessage={errors.questionErrorMsg}
          >
            <option value="" selected>
              Please select
            </option>
            {matchQuestions.map((question) => (
              <option value={question}>{question}</option>
            ))}
          </Select>
        </div>
      </div>

      <div className="action-button">
        <Button text="Next" onClick={handleNext} loading={isLoading} />
        <Button text="Back" buttonType="text" onClick={handleBack} />
      </div>
    </div>
  );
};
