import {
  Drawer,
  Grid,
  useMediaQuery,
  Avatar,
  Typography,
  Skeleton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PaymentIcon from "@mui/icons-material/Payment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { useViewPort } from "hooks";
import { useAppDispatch, useAppSelector } from "store";
import { fetchAuthAvatar, logoutAuth } from "store/application";
import { ProfileStatusMeta } from "config/profile";
import AppStoreBadge from "assets/images/appstore-download.png";
import PlayStoreBadge from "assets/images/playstore-download.png";

type Props = {
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const MenuDrawer = ({ showMenu, setShowMenu }: Props) => {
  const navigate = useNavigate();
  const screen = useViewPort();
  const dispatch = useAppDispatch();
  const { authProfile, authAvatar } = useAppSelector(
    (state) => state.application,
  );
  const [downloadAvatar, setDownloadAvatar] = useState(false);

  useEffect(() => {
    if (isEmpty(authAvatar)) {
      setDownloadAvatar(true);
    }
  }, []);

  useEffect(() => {
    if (downloadAvatar && isEmpty(authAvatar)) {
      dispatch(fetchAuthAvatar());
    }
  }, [downloadAvatar]);

  return (
    <Drawer
      open={showMenu}
      PaperProps={{
        sx: {
          width: screen.width * 0.8,
          backgroundColor: "#F2F2F2",
          height: screen.height,
        },
      }}
      ModalProps={{ keepMounted: false }}
    >
      <Grid
        container
        direction="column"
        bgcolor="#F2F2F2"
        height={1}
        paddingY={1}
        paddingX={2}
        justifyContent="space-between"
      >
        <Grid item container direction="column">
          <Grid item container direction="row" justifyContent="end">
            <Grid item>
              <Close
                onClick={(e) => {
                  e.preventDefault();
                  setShowMenu(false);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            justifyContent="stretch"
            alignItems="center"
          >
            <Grid item>
              <Avatar
                src={authAvatar || undefined}
                sx={{ width: screen.width * 0.25, height: screen.width * 0.25 }}
              />
            </Grid>
            <Grid item width={0.8}>
              {authProfile !== null ? (
                <Typography
                  variant="h6"
                  textAlign="center"
                >{`${authProfile.firstName} ${authProfile.lastName}`}</Typography>
              ) : (
                <Skeleton
                  variant="text"
                  width="100%"
                  height={screen.height * 0.05}
                />
              )}
            </Grid>
            {authProfile !== null && (
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                marginY={1}
                columnGap={1}
              >
                <Grid item>
                  <Typography
                    variant="caption"
                    bgcolor={ProfileStatusMeta[authProfile.status].tag.bgColor}
                    color={ProfileStatusMeta[authProfile.status].tag.fontColor}
                    paddingY={0.5}
                    paddingX={2}
                    borderRadius={2}
                    fontSize={12}
                  >
                    {ProfileStatusMeta[authProfile.status].label}
                  </Typography>
                </Grid>
                {authProfile.trialMode && (
                  <Grid item>
                    <Typography
                      variant="caption"
                      bgcolor="#007fbf"
                      color={
                        ProfileStatusMeta[authProfile.status].tag.fontColor
                      }
                      paddingY={0.5}
                      paddingX={1}
                      borderRadius={3}
                      fontSize={12}
                    >
                      Trail Mode
                    </Typography>
                  </Grid>
                )}
                {(authProfile.trialMode ||
                  authProfile.status === "EXPIRED") && (
                  <Grid
                    item
                    xs={12}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    paddingY={1}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<PaymentIcon />}
                        sx={{ backgroundColor: "#9575CD" }}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/payment");
                        }}
                      >
                        Pay Now
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="stretch"
          justifyContent="center"
        >
          <Grid item width={1}>
            <List>
              <ListItem disablePadding divider>
                <ListItemButton>
                  <ListItemIcon>
                    <ExitToAppIcon htmlColor="black" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Log out"
                    sx={{ color: "black", fontWeight: 600 }}
                    onClick={(e) => {
                      dispatch(logoutAuth()).then(() => {
                        navigate("/");
                      });
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
          <Grid item>
            <Typography variant="body1" fontWeight={300} color="#333333">
              Download the Hytch app from Google Playstore and Apple Appstore
              (coming soon)
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            paddingY={0.5}
            paddingX={0.5}
            columnGap={1}
          >
            <Grid item sm={6}>
              <img
                src={PlayStoreBadge}
                alt="app-store"
                height={screen.width * 0.1}
                width={screen.width * 0.3}
                style={{ objectFit: "contain" }}
              />
            </Grid>
            <Grid item sm={5}>
              <img
                src={AppStoreBadge}
                alt="playstore-store"
                height={screen.width * 0.1}
                width={screen.width * 0.3}
                style={{
                  objectFit: "cover",
                  opacity: 0.5,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default MenuDrawer;
