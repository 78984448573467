import moment from "moment";
import { Grid, Typography } from "@mui/material";
import { Match } from "models/match";
import { formatDateDurationToReadable } from "components/templates/Home/util/formatter";
import Message from "models/message";

type MatchQuestionAnswerProps = {
  interest: Match.Match;
};

const MatchQuestionAnswer = ({ interest }: MatchQuestionAnswerProps) => {
  const formatMatchedDate = (): string => {
    const matchedTimeline = interest.timeline.find(
      (timeline) => timeline.event === "MATCHED",
    );
    return matchedTimeline?.createdAt
      ? formatDateDurationToReadable(moment(matchedTimeline.createdAt))
      : "";
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      paddingY={1}
    >
      <Grid item xs={12} paddingY={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography
              variant="caption"
              align="center"
              color="#333333"
              fontWeight={300}
              width={1}
            >
              {formatMatchedDate()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Grid container direction="row" justifyContent="flex-start">
          <Grid
            item
            xs={11}
            bgcolor="#EDEDED"
            borderRadius={2}
            paddingBottom={1}
          >
            <Typography
              variant="body2"
              color="#333333"
              paddingX={2}
              paddingY={2}
            >
              {interest.question}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          marginTop={-2}
        >
          <Grid item xs={11} bgcolor="#448AFF" borderRadius={2}>
            <Typography
              variant="body2"
              color="#FFFFFF"
              paddingX={2}
              paddingY={2}
            >
              {interest.questionResponse}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MatchQuestionAnswer;
