/* eslint-disable react/prop-types */
import { Grid, Typography } from "@mui/material";
import {
  Cake,
  Person,
  ImportContacts,
  LineWeight,
  School,
  Work,
  LocalBar,
  SmokingRooms,
  RestaurantMenu,
} from "@mui/icons-material";
import { ReactElement } from "react";
import { Profile } from "models/profile";
import moment from "moment";
import { convertCentimetersToFeetAndInches } from "config/generics";
import { capitalize } from "lodash";

interface ListItem {
  Icon: ReactElement;
  label: string;
}

interface ListitemProps {
  item: ListItem;
}

interface DataGridProps {
  items: ListItem[];
}

const ProfileGridItem: React.FC<ListitemProps> = ({ item }) => {
  const { Icon, label } = item;
  return (
    <Grid container direction="row" paddingY={1} columnGap={1}>
      <Grid item>{Icon}</Grid>
      <Grid item>
        <Typography variant="body1">{label}</Typography>
      </Grid>
    </Grid>
  );
};

const ProfileDataGrid: React.FC<DataGridProps> = ({ items }) => {
  return (
    <Grid
      container
      direction="row"
      bgcolor="white"
      borderRadius={3}
      paddingY={1}
      paddingX={4}
    >
      {items.map((item) => (
        <Grid item xs={6} md={5} lg={5}>
          <ProfileGridItem key={`item-${item}`} item={item} />
        </Grid>
      ))}
    </Grid>
  );
};

interface ProfileSummeryProps {
  profile: Profile.Profile;
}

const ProfileSummery: React.FC<ProfileSummeryProps> = ({ profile }) => {
  const fullName = `${profile.firstName} ${profile.lastName}`;
  const heightObj = convertCentimetersToFeetAndInches(profile.height);
  const subrow1 = [
    profile.age.toString(),
    profile.profession.name,
    `${heightObj.feets}' ${heightObj.inches}"`,
  ].join(" • ");
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      bgcolor="white"
      alignItems="center"
      paddingY={1}
      borderRadius={3}
    >
      <Typography variant="body1" fontWeight={500} fontSize={24}>
        {fullName}
      </Typography>
      <Typography variant="body1" fontWeight={400} fontSize={14}>
        {subrow1}
      </Typography>
      <Typography variant="body1" fontWeight={400} fontSize={14}>
        {`${profile.residentCity}, ${profile.residentCountry.name}`}
      </Typography>
    </Grid>
  );
};

interface ProfileViewProps {
  profile: Profile.Profile;
}

const ProfileView: React.FC<ProfileViewProps> = ({ profile }) => {
  const currentMoment = moment();
  const age = currentMoment.diff(moment(profile.age, "YYYY-MM-DD"), "years");
  const heightObj = convertCentimetersToFeetAndInches(profile.height);

  const personalListItems: ListItem[] = [
    { Icon: <Cake />, label: profile.age.toString() },
    {
      Icon: <LineWeight />,
      label: `${heightObj.feets}ft ${heightObj.inches}in`,
    },
    { Icon: <Person />, label: profile.ethnicity.name },
    { Icon: <ImportContacts />, label: profile.religion.name },
  ];

  const preofessionListItems: ListItem[] = [
    { Icon: <School />, label: profile.educationLevel.name },
    { Icon: <Work />, label: profile.profession.name },
  ];

  const preferenceListItem: ListItem[] = [
    { Icon: <LocalBar />, label: capitalize(profile.drinking) },
    { Icon: <SmokingRooms />, label: capitalize(profile.smoking) },
    { Icon: <RestaurantMenu />, label: capitalize(profile.foodPreference) },
  ];

  return (
    <Grid container direction="column" rowGap={4}>
      <ProfileSummery profile={profile} />
      <ProfileDataGrid items={personalListItems} />
      <ProfileDataGrid items={preofessionListItems} />
      <ProfileDataGrid items={preferenceListItem} />
    </Grid>
  );
};

export default ProfileView;
