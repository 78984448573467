import {
  Grid,
  ImageList,
  ImageListItem,
  Skeleton,
  Typography,
} from "@mui/material";
import { Profile } from "models/profile";
import { useCallback, useEffect, useState } from "react";
import { getMatchThumbnail } from "services/api/mobile/match";

type Props = {
  profile: Profile.PublicProfile;
  matchId: string;
};

const ImageSkeletons = ({ count }: { count: number }) => {
  return (
    <Grid container direction="row" justifyContent="center" columnGap={1}>
      {Array.from(Array(count).keys()).map(() => (
        <Grid item xs={3}>
          <Skeleton variant="rectangular" width="100%" height={120} />
        </Grid>
      ))}
    </Grid>
  );
};

const ProfileImagesView = ({ profile, matchId }: Props) => {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<string[]>([]);

  const fetchProfileImages = useCallback(async () => {
    if (profile.images === undefined) {
      return;
    }
    const profileImages = profile.images.map(async (file) => {
      const response = await getMatchThumbnail({
        matchId,
        imageId: file,
        thumbnail: false,
      });
      return response;
    });
    Promise.all(profileImages)
      .then((data) => {
        setImages(data.filter((url) => url !== null) as string[]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [profile]);

  const generateInformaton = (row: "personalInfo" | "residency") => {
    switch (row) {
      case "personalInfo": {
        const { age, profession, height } = profile;
        return [age, profession.name, height].join(" • ");
      }
      case "residency": {
        const { residentCity, residentCountry } = profile;
        return `${residentCity} ${residentCountry.name}`;
      }
      default: {
        return "";
      }
    }
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    if (loading) {
      fetchProfileImages();
    }
  }, [loading]);

  return (
    <Grid
      container
      direction="row"
      bgcolor="#FFFFFF"
      paddingY={2}
      paddingX={1}
      borderRadius={2}
    >
      <Grid item xs={12}>
        {loading ? (
          <ImageSkeletons count={profile.images?.length || 1} />
        ) : (
          <ImageList cols={3}>
            {images.map((image) => (
              <ImageListItem key={image}>
                <img src={image} alt="profile-img" />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </Grid>
      <Grid item xs={12} container direction="column" paddingX={2} paddingY={1}>
        <Grid item>
          <Typography variant="body1">
            {generateInformaton("personalInfo")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" fontWeight={300}>
            {generateInformaton("residency")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileImagesView;
