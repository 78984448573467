import { Grid, Typography } from "@mui/material";
import { ReactElement } from "react";

type Props = {
  icon: ReactElement;
  label: string;
};

const DataViewItem = ({ icon, label }: Props) => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      paddingX={1}
      paddingY={1}
    >
      <Grid item>{icon}</Grid>
      <Grid item paddingLeft={1}>
        <Typography>{label}</Typography>
      </Grid>
    </Grid>
  );
};

export default DataViewItem;
