import { Grid, Typography } from "@mui/material";

type Props = {
  question: string;
  answer: string;
};

const QuestionAnswer = ({ question, answer }: Props) => {
  return (
    <Grid
      container
      direction="column"
      bgcolor="#FFFFFF"
      paddingX={4}
      paddingY={3}
      alignItems="stretch"
      borderRadius={2}
    >
      <Grid item>
        <Typography>{question}</Typography>
      </Grid>
      <Grid
        item
        bgcolor="#EDEDED"
        paddingX={2}
        paddingY={2}
        borderRadius={2}
        marginTop={2}
      >
        <Typography>{answer}</Typography>
      </Grid>
    </Grid>
  );
};

export default QuestionAnswer;
