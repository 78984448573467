/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Select,
  Input,
  Radio,
  BreadcrumbItem,
} from "components";
import { CreateFlowScreen } from "models/enum";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { setCurrentScreen, setProfileDetails } from "store/features/CreateFlow";
import agent from "services";
import { getMasterData } from "store/MasterData";
import { IoRestaurant } from "react-icons/io5";
import "./index.scss";

const foodPreferrenceMap = {};

export const PersonalHabbits = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    drinkingError: false,
    smokingError: false,
    foodPreferencesError: false,
    drinkingErrorMsg: "",
    smokingErrorMsg: "",
    foodPreferencesErrorMsg: "",
  });

  const profile = useAppSelector(
    (state: RootState) => state.createFlow.profile,
  );

  const handleOnSelect = (value: string, prop: string) => {
    dispatch(setProfileDetails({ [prop]: value }));
  };

  const handleBack = () => {
    dispatch(setCurrentScreen(CreateFlowScreen.EDUCATION));
  };

  const validate = () => {
    const status = {
      drinkingError: false,
      smokingError: false,
      foodPreferencesError: false,
      drinkingErrorMsg: "",
      smokingErrorMsg: "",
      foodPreferencesErrorMsg: "",
    };

    if (profile.drinking === "") {
      status.drinkingError = true;
      status.drinkingErrorMsg = "Are you drinking?";
    } else {
      status.drinkingError = false;
      status.drinkingErrorMsg = "";
    }

    if (profile.smoking === "") {
      status.smokingError = true;
      status.smokingErrorMsg = "Are you smoking?";
    } else {
      status.smokingError = false;
      status.smokingErrorMsg = "";
    }

    if (profile.food_preference === "") {
      status.foodPreferencesError = true;
      status.foodPreferencesErrorMsg = "Are you vegetarian or not?";
    } else {
      status.foodPreferencesError = false;
      status.foodPreferencesErrorMsg = "";
    }

    if (
      status.drinkingError ||
      status.smokingError ||
      status.foodPreferencesError
    ) {
      setErrors(status);
      return false;
    }
    setErrors(status);
    return true;
  };

  const handleNext = async () => {
    if (validate()) {
      setIsLoading(true);
      const saveTempUserData = {
        first_name: profile.first_name,
        last_name: profile.last_name,
        gender: profile.gender,
        dob: profile.dob,
        height: profile.height,
        ethnicity_id: profile.ethnicity_id,
        religion_id: profile.religion_id,
        education_level_id: profile.education_level_id,
        profession_id: profile.profession_id,
        profession_other: profile.profession_other,
        drinking: profile.drinking,
        smoking: profile.smoking,
        food_preference: profile.food_preference,
      };

      await agent.CreateFlow.updateTempProfile(saveTempUserData)
        .then(() => {
          setIsLoading(false);
          dispatch(setCurrentScreen(CreateFlowScreen.LOCATION));
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    dispatch(getMasterData());
  }, []);

  return (
    <div className="login-signup-screen flow-step">
      <div className="create-flow-content">
        <div className="breadcrum-container">
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem icon={<IoRestaurant />} />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
        </div>
        <Typography variant="h1" style={{ marginBottom: "2rem" }}>
          What are your <br /> habits?
        </Typography>

        <div className="habbits-item">
          <div className="item-label">Drinking</div>
          <div className="radio-inputs-wrapper">
            <Radio
              id="drinking_yes"
              label="Yes"
              name="drinking"
              value={profile.drinking}
              checked={profile.drinking === "Yes"}
              onChange={() => handleOnSelect("Yes", "drinking")}
            />
            <Radio
              id="drinking_no"
              label="No"
              name="drinking"
              value={profile.drinking}
              checked={profile.drinking === "No"}
              onChange={() => handleOnSelect("No", "drinking")}
            />
            <Radio
              id="drinking_occa"
              label="Occasionally"
              name="drinking"
              value={profile.drinking}
              checked={profile.drinking === "Occasionally"}
              onChange={() => handleOnSelect("Occasionally", "drinking")}
            />
          </div>
          {errors.drinkingError && (
            <Typography variant="baseRegular" color="danger">
              {errors.drinkingErrorMsg}
            </Typography>
          )}
        </div>

        <div className="habbits-item">
          <div className="item-label">Smoking</div>
          <div className="radio-inputs-wrapper">
            <Radio
              id="smoking_yes"
              label="Yes"
              name="smoking"
              value={profile.smoking}
              checked={profile.smoking === "Yes"}
              onChange={() => handleOnSelect("Yes", "smoking")}
            />
            <Radio
              id="smoking_no"
              label="No"
              name="smoking"
              value={profile.smoking}
              checked={profile.smoking === "No"}
              onChange={() => handleOnSelect("No", "smoking")}
            />
          </div>
          {errors.smokingError && (
            <Typography variant="baseRegular" color="danger">
              {errors.smokingErrorMsg}
            </Typography>
          )}
        </div>

        <div className="habbits-item">
          <div className="item-label">Food Preferences</div>
          <div className="radio-inputs-wrapper">
            <Radio
              id="food_veg"
              label="Vegetarian"
              name="food_preference"
              value={profile.food_preference}
              checked={profile.food_preference === "VEG"}
              onChange={() => handleOnSelect("VEG", "food_preference")}
            />
            <Radio
              id="food_non"
              label="Non - Vegetarian"
              name="food_preference"
              value={profile.food_preference}
              checked={profile.food_preference === "NONE"}
              onChange={() => handleOnSelect("NONE", "food_preference")}
            />
            <Radio
              id="food_vegan"
              label="Vegan"
              name="food_preference"
              value={profile.food_preference}
              checked={profile.food_preference === "VEGAN"}
              onChange={() => handleOnSelect("VEGAN", "food_preference")}
            />
            <Radio
              id="food_pesc"
              label="Pescatarian"
              name="food_preference"
              value={profile.food_preference}
              checked={profile.food_preference === "PESC"}
              onChange={() => handleOnSelect("PESC", "food_preference")}
            />
          </div>
          {errors.foodPreferencesError && (
            <Typography variant="baseRegular" color="danger">
              {errors.foodPreferencesErrorMsg}
            </Typography>
          )}
        </div>
      </div>

      <div className="action-button">
        <Button text="Next" onClick={handleNext} loading={isLoading} />
        <Button text="Back" buttonType="text" onClick={handleBack} />
      </div>
    </div>
  );
};
