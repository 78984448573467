import { NavBar } from "components";
import { Container, Typography, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PNF from "../../../assets/images/page-not-found.png";

export const PageNotFound = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <>
      <NavBar />
      <Container
        maxWidth="sm"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "80%",
        }}
      >
        <Typography variant="h4" mb={2}>
          Page Not Found
        </Typography>
        <img src={PNF} alt="page not found" className="pnf-icon" />
        <Typography variant="h6" mt={2} mb={2}>
          Sorry, the page you’re looking for is not available.
        </Typography>
        <Button
          variant="outlined"
          onClick={handleClick}
          style={{ borderColor: "#9575CD" }}
          // endIcon={<SendIcon />}
        >
          <Link style={{ textDecoration: "none" }} to="/">
            Homepage
          </Link>
        </Button>
      </Container>
    </>
  );
};
