import {
  ImageList,
  ImageListItem,
  CircularProgress,
  Grid,
} from "@mui/material";
import {} from "@mui/icons-material";
import { isEmpty } from "lodash";
import { Profile } from "models/profile";
import { useEffect, useState } from "react";
import { getAuthAvatarAPI } from "services/api/mobile/auth";

type ImageListProps = {
  profile: Profile.Profile;
};

type ProfileImageProps = {
  image: Profile.ProfileImage;
};

const ProfileImage = ({ image }: ProfileImageProps) => {
  const [imageData, setImageData] = useState<string | null>(null);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setDownloading(true);
  }, []);

  useEffect(() => {
    const downloadImage = async () => {
      const data = await getAuthAvatarAPI(image.id);
      setImageData(data);
      setDownloading(false);
    };
    if (downloading) {
      downloadImage();
    }
  }, [downloading]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      height={1}
    >
      <Grid item borderRadius={5}>
        {isEmpty(imageData) ? (
          <CircularProgress size={15} />
        ) : (
          <img
            alt={`${image.id}-img`}
            src={imageData || undefined}
            loading="lazy"
            style={{
              borderRadius: "3px",
              width: "100%",
              height: 120,
              maxHeight: "100%",
              minWidth: "100%",
              objectFit: "cover",
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

const ImageListComp = ({ profile }: ImageListProps) => {
  return (
    <ImageList
      cols={3}
      gap={4}
      rowHeight={140}
      style={{
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 5,
      }}
    >
      {profile.profileImages.map((profileImage) => (
        <ImageListItem key={profileImage.id}>
          <ProfileImage image={profileImage} />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default ImageListComp;
