import React from "react";
import { NavBar } from "components";
import Hero from "./Hero";
import Poruwa from "./Poruwa";
import Footer from "./Footer";
import Copyright from "./Copyright";
import Register from "./Register";
import "./index.scss";

export const Home = () => {
  return (
    <div className="home-container">
      <NavBar />
      <Hero />
      <Poruwa />
      <Register />
      <Footer />
      <Copyright />
    </div>
  );
};
