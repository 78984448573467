import HeroImage from "../../../../assets/images/hero-img.png";
import PlayImage from "../../../../assets/images/play_icon.png";
import AppleImage from "../../../../assets/images/apple_icon-w.png";

const Hero = () => {
  return (
    <main className="hero-container">
      <div className="container">
        <div className="hero_wrapper">
          <div className="hero_main_text">
            <h1 className="hero_title">
              <span className="hero_title-sec-1">Ready to meet</span>
              <span className="hero_title-sec-2">your match?</span>
            </h1>

            <div className="hero-para">
              {/* <p>hytch got you covered!</p> */}
              <p>
                Hytch lets you meet your perfect match <br />
                while keeping your privacy.
              </p>
            </div>

            {/* <h2 className="hero-hash">#datesafe</h2> */}

            <div className="hero_action_buttons">
              <a
                className="primary_button"
                href="https://play.google.com/store/apps/details?id=com.serendib.hytch"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="p_btn_inner">
                  <img src={PlayImage} alt="" />
                  <div>
                    <span>GET IT ON</span> Google Play
                  </div>
                </div>
              </a>

              {/* eslint-disable-next-line */}
              <a
                className="primary_button"
                href="https://apps.apple.com/app/hytch/id1597740003"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="p_btn_inner">
                  <img src={AppleImage} alt="" />
                  <div>
                    <span>Available soon on</span> App Store
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className="hero_image">
            <img src={HeroImage} alt="" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Hero;
