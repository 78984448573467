/* eslint-disable @typescript-eslint/no-use-before-define */
import { Grid, TextField, IconButton } from "@mui/material";
import { SendRounded } from "@mui/icons-material";
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "store";
import {
  setCurrentMessage,
  sendNewMessage,
  fetchChatMessages,
} from "store/application/MessageThread";
import { MouseEventHandler } from "react";
import { ApiResponse } from "services/api/mobile/proxy";
import { setAppAlert } from "store/application";

type MessageTextInputProps = {
  interestId: string;
  messageViewRef: React.MutableRefObject<HTMLDivElement | null>;
};

const MessageTextInput = ({
  interestId,
  messageViewRef,
}: MessageTextInputProps) => {
  const dispatch = useAppDispatch();
  const { currentMessage } = useAppSelector((state) => state.messageThread);

  const handleSendMessage: MouseEventHandler<HTMLLabelElement> = (e) => {
    e.preventDefault();
    dispatch(sendNewMessage({ interestId, message: currentMessage })).then(
      (response) => {
        const payload = response.payload as ApiResponse;
        if (!payload.success) {
          dispatch(
            setAppAlert({
              severity: "error",
              message: "Unable to send message",
            }),
          );
          return;
        }
        dispatch(setCurrentMessage(""));
        dispatch(fetchChatMessages({ interestId, latestPage: true })).finally(
          () => {
            messageViewRef.current?.scrollTo({
              behavior: "auto",
              top: messageViewRef.current.scrollHeight,
            });
          },
        );
      },
    );
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      borderColor="#E5E4E2"
    >
      <Grid xs={10} item>
        <TextField
          rows={2}
          maxRows={3}
          multiline
          value={currentMessage}
          onChange={(e) => {
            e.preventDefault();
            dispatch(setCurrentMessage(e.target.value));
          }}
          fullWidth
          placeholder="Enter message"
          size="medium"
          inputProps={{ style: { fontSize: 15 } }}
          sx={{ paddingX: 1, backgroundColor: "white" }}
        />
      </Grid>
      <Grid xs={2} item>
        <IconButton
          onClick={handleSendMessage}
          sx={{ color: "#9575CD" }}
          aria-label="upload picture"
          component="label"
          disabled={isEmpty(currentMessage)}
          size="large"
        >
          <SendRounded />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default MessageTextInput;
