import { useEffect, useState, MouseEventHandler } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { fetchInterests } from "store/application/Interests";
import { submitForReview } from "store/application";
import { Match } from "models/match";
import FormResponder from "../../component/Button/FormResponder";
import Banner from "../../component/Banner";
import InterestCard from "./components/InterestCard";
import BottomNavigationScreen from "../../component/Screen/BottomNavigationScreen";
import ProfileReviewSubmission from "../../views/ProfileReviewSubmission";

const LikePage = () => {
  const dispatch = useAppDispatch();
  const { authProfile } = useAppSelector((state) => state.application);
  const { interests } = useAppSelector((state) => state.interests);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [mainInterest, setMainInterest] = useState<Match.Match | null>(null);
  const [secondaryInterests, setSecondaryInterests] = useState<
    Array<Match.Match>
  >([]);

  const handleMainInterestOnClick = () => {
    navigate(`${mainInterest?.id}`);
  };

  useEffect(() => {
    if (isEmpty(interests)) {
      setLoading(true);
      dispatch(fetchInterests()).finally(() => {
        setLoading(false);
      });
      return;
    }
    const [mainCardItem, ...secondaryItems] = interests;
    setMainInterest(mainCardItem);
    setSecondaryInterests(secondaryItems);
  }, []);

  if (loading) {
    return (
      <BottomNavigationScreen name="Likes">
        <Grid
          container
          direction="row"
          height={1}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={24} />
        </Grid>
      </BottomNavigationScreen>
    );
  }

  if (interests.length === 0) {
    return (
      <BottomNavigationScreen name="Likes">
        <Grid
          container
          direction="column"
          height={1}
          justifyContent="center"
          alignItems="center"
        >
          <Typography align="center">
            Likes from other users will appear here as they show interest in
            your profile
          </Typography>
        </Grid>
      </BottomNavigationScreen>
    );
  }

  return (
    <BottomNavigationScreen name="Likes">
      {!isEmpty(interests) && (
        <Grid
          container
          direction="column"
          height={1}
          justifyContent="space-between"
        >
          {mainInterest !== null && (
            <Grid item xs={6} sm={6} md={7}>
              <InterestCard
                type="detailed"
                interest={mainInterest}
                onClick={(e) => {
                  e.preventDefault();
                  handleMainInterestOnClick();
                }}
              />
            </Grid>
          )}
          {!isEmpty(secondaryInterests) && (
            <>
              <Grid item xs={1} sm={1} md={1} height={1} bgcolor="lightpink">
                <Banner
                  variant="info"
                  message="Take an action on your latest like to access the rest."
                />
              </Grid>

              <Grid item xs={2} sm={3} md={2}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="end"
                  columnSpacing={2}
                >
                  {secondaryInterests[0] !== undefined && (
                    <Grid item xs={5} md={4}>
                      <InterestCard
                        type="summery"
                        interest={secondaryInterests[0]}
                      />
                    </Grid>
                  )}
                  {secondaryInterests[1] !== undefined && (
                    <Grid item xs={5} md={4}>
                      <InterestCard
                        type="summery"
                        interest={secondaryInterests[1]}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </BottomNavigationScreen>
  );
};

export default LikePage;
