/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { NavigateFunction } from "react-router-dom";

import type { Match } from "models/match";
import { getRemoteMatches } from "services/api/mobile/match";
import {
  getAuthProfile,
  getAuthAvatarAPI,
  submitProfileReviewAPI,
} from "services/api/mobile/auth";
import { Profile } from "models/profile";
import type { RootState } from "store";
import {
  deleteProfileImagesAPI,
  updateProfileAPI,
} from "services/api/mobile/profile";

export type NaviagationScreen = {
  name: string;
  route: string;
};

namespace User {
  export type Role = "USER";

  export type Account = {
    created_at: string;
    password_login_enabled: boolean;
    phone_number: string;
    profile_created: boolean;
    profile_status: Status;
    push_id: string;
    role: Role;
  };

  export type Token = {
    created_at: string;
    description: string;
    id: string;
    is_new_user: boolean;
  };

  export type Status = "LIVE" | "EXPIRED";
}

export type AppAlert = {
  severity: "success" | "error" | "info" | "warning";
  message: string;
  duration?: number;
};

type ApplicationStates = {
  authToken: User.Token | null;
  authAccount: User.Account | null;
  authProfile: Profile.Profile | null;
  authAvatar: string | null;
  mainScreen: string | null;
  navigations: Array<NaviagationScreen>;
  showNavigation: boolean;
  matches: Array<Match.Match>;
  matchesLoading: boolean;
  showWebView: boolean;
  appAlert: AppAlert | null;
};

const initialState: ApplicationStates = {
  appAlert: null,
  authToken: null,
  authAccount: null,
  authProfile: null,
  authAvatar: null,
  mainScreen: "Home",
  navigations: [],
  matches: [],
  matchesLoading: false,
  showNavigation: false,
  showWebView: false,
};

export const fetchAuthProfile = createAsyncThunk(
  "application/fetchAuthProfile",
  async (arg, { dispatch }) => {
    const apiResponse = await getAuthProfile();
    if (!apiResponse.success) {
      dispatch(setAppAlert({ severity: "error", message: "Error Occurred" }));
      return null;
    }
    return apiResponse.data;
  },
);

export const checkLocalAuthentication = createAsyncThunk(
  "application/checkLocalAuthentication",
  async (navigate: NavigateFunction, { dispatch }) => {
    const authToken = JSON.parse(localStorage.getItem("token") || "null");
    const authAccount = JSON.parse(
      localStorage.getItem("authAccount") || "null",
    );
    if (isEmpty(authToken) || isEmpty(authAccount)) {
      navigate("/");
    } else {
      dispatch(fetchAuthProfile());
    }

    return { authToken, authAccount };
  },
);

export const fetchMatches = createAsyncThunk(
  "application/getmatches",
  async (arg, { dispatch }) => {
    const apiResponse = await getRemoteMatches();
    if (apiResponse.success) {
      const { data } = apiResponse;
      return data as Array<Match.Match>;
    }
    dispatch(
      setAppAlert({ severity: "error", message: "Unable to fetch Matches" }),
    );
    return [];
  },
);

export const fetchAuthAvatar = createAsyncThunk(
  "application/fetchAuthAvatar",
  async (arg, thunkAPI) => {
    const { application } = thunkAPI.getState() as RootState;
    const { authProfile } = application;
    if (authProfile != null && !isEmpty(authProfile.profileImages)) {
      const file =
        authProfile.profileImages.find((img) => img.isPreferred) ||
        authProfile.profileImages[0];
      const avatar = await getAuthAvatarAPI(file.id);
      return avatar;
    }
    return null;
  },
);

export const logoutAuth = createAsyncThunk(
  "application/logoutAuth",
  async (arg, { dispatch }) => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("authAccount");
    // localStorage.removeItem("authProfile");
  },
);

export const updateProfile = createAsyncThunk(
  "application/updateProfile",
  async (profile: Profile.UpdateProfilePayload) => {
    const apiResponse = await updateProfileAPI(profile);
    return apiResponse;
  },
);

export const deleteProfileImages = createAsyncThunk(
  "application/deleteProfileImages",
  async (imageId: string) => {
    const apiResponse = await deleteProfileImagesAPI(imageId);
    return apiResponse;
  },
);

export const submitForReview = createAsyncThunk(
  "application/submitForReview",
  async () => {
    const apiResponse = await submitProfileReviewAPI();
    return apiResponse;
  },
);

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setNavigations: (state, { payload }) => {
      state.navigations = payload;
    },
    setMainScreen: (state, { payload }) => {
      state.mainScreen = payload;
    },
    setMatches: (state, { payload }) => {
      state.mainScreen = payload;
    },
    setShowNavigation: (state, action) => {
      state.showNavigation = action.payload;
    },
    setShowWebView: (state, action) => {
      state.showWebView = action.payload;
    },
    setAppAlert: (state, { payload }: { payload: AppAlert | null }) => {
      state.appAlert = payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMatches.fulfilled, (state, action) => {
      state.matches = action.payload;
      state.matchesLoading = false;
    });
    builder.addCase(fetchMatches.pending, (state, ...arg) => {
      state.matchesLoading = true;
    });
    builder.addCase(fetchMatches.rejected, (state, action) => {
      state.matches = [];
      state.matchesLoading = false;
      // error handling here such as Error Aleart
    });
    builder.addCase(
      checkLocalAuthentication.fulfilled,
      (state, { payload }) => {
        state.authAccount = payload.authAccount;
        state.authToken = payload.authToken;
      },
    );
    builder.addCase(fetchAuthProfile.fulfilled, (state, action) => {
      state.authProfile = action.payload;
    });
    builder.addCase(fetchAuthAvatar.fulfilled, (state, action) => {
      state.authAvatar = action.payload;
    });
    builder.addCase(logoutAuth.fulfilled, (state, action) => {
      state.authAccount = null;
      state.authToken = null;
      state.authProfile = null;
      state.authAvatar = null;
    });
  },
});

const { reducer, actions } = applicationSlice;
export const { setShowNavigation, setShowWebView, setAppAlert } =
  applicationSlice.actions;
export const Actions = actions;
export default reducer;
