import { request } from "services/api";

const MasterData = {
  getMasterData: () =>
    request.get<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/generic/lists`,
    ),
  getAllCountryList: () =>
    request.get<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/generic/countries`,
    ),
  getDetailedCountryList: () =>
    request.get<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/generic/detailed_countries`,
    ),
  getMatchQuestionsList: () =>
    request.get<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/generic/match_questions`,
    ),
};

export default MasterData;
