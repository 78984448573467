import { useState, useEffect } from "react";

import { Grid, Paper } from "@mui/material";
import Application from "components/templates/Home/app";
import { useAppSelector } from "store";

type ContentRegionProps = {
  isMediumScreen: boolean;
};

const ContentRegion = ({ isMediumScreen }: ContentRegionProps) => {
  const { showWebView } = useAppSelector((state) => state.application);
  const [paddingY, setPaddingY] = useState(0);

  useEffect(() => {
    setPaddingY(showWebView ? 2 : 0);
  }, [showWebView]);

  return (
    <Grid
      container
      direction="row"
      alignItems="stretch"
      justifyContent="center"
      height={1}
    >
      <Grid item xs={12} sm={12} md={4} lg={3} height={1} paddingY={paddingY}>
        <Paper sx={{ height: "100%", overflowY: "auto" }} elevation={3}>
          <Application />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ContentRegion;
