export const heightsArr = [
  { name: "4ft (122cm)", value: 122 },
  { name: "4ft 1in (124cm)", value: 124 },
  { name: "4ft 2in (127cm)", value: 127 },
  { name: "4ft 3in (130cm)", value: 130 },
  { name: "4ft 4in (132cm)", value: 132 },
  { name: "4ft 5in (135cm)", value: 135 },
  { name: "4ft 6in (137cm)", value: 137 },
  { name: "4ft 7in (140cm)", value: 140 },
  { name: "4ft 8in (142cm)", value: 142 },
  { name: "4ft 9in (145cm)", value: 145 },
  { name: "4ft 10in (147cm)", value: 147 },
  { name: "4ft 11in (150cm)", value: 150 },
  { name: "5ft (152cm)", value: 152 },
  { name: "5ft 1in (155cm)", value: 155 },
  { name: "5ft 2in (157cm)", value: 157 },
  { name: "5ft 3in (160cm)", value: 160 },
  { name: "5ft 4in (163cm)", value: 163 },
  { name: "5ft 5in (165cm)", value: 165 },
  { name: "5ft 6in (168cm)", value: 168 },
  { name: "5ft 7in (170cm)", value: 170 },
  { name: "5ft 8in (173cm)", value: 173 },
  { name: "5ft 9in (175cm)", value: 175 },
  { name: "5ft 10in (178cm)", value: 178 },
  { name: "5ft 11in (180cm)", value: 180 },
  { name: "6ft (183cm)", value: 183 },
  { name: "6ft 1in (185cm)", value: 185 },
  { name: "6ft 2in (188cm)", value: 188 },
  { name: "6ft 3in (191cm)", value: 191 },
  { name: "6ft 4in (193cm)", value: 193 },
  { name: "6ft 5in (196cm)", value: 196 },
  { name: "6ft 6in (198cm)", value: 198 },
  { name: "6ft 7in (201cm)", value: 201 },
  { name: "6ft 8in (203cm)", value: 203 },
  { name: "6ft 9in (206cm)", value: 206 },
  { name: "6ft 10in (208cm)", value: 208 },
  { name: "6ft 11in (211cm)", value: 211 },
  { name: "7ft (213cm)", value: 213 },
  { name: "7ft 1in (216cm)", value: 216 },
  { name: "7ft 2in (218cm)", value: 218 },
  { name: "7ft 3in (221cm)", value: 221 },
  { name: "7ft 4in (224cm)", value: 224 },
  { name: "7ft 5in (226cm)", value: 226 },
  { name: "7ft 6in (229cm)", value: 229 },
];
