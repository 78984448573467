import { Grid, Typography } from "@mui/material";

type BannerProps = {
  variant: Varaint;
  message: string;
  loading?: boolean;
};

type Varaint = "info";

const VARIANT_COLOR: { [key in Varaint]: string } = {
  info: "#A7C7E7",
};

const Banner = ({ variant, message, loading = false }: BannerProps) => {
  return (
    <Grid
      container
      direction="row"
      height={1}
      bgcolor={VARIANT_COLOR[variant]}
      alignContent="center"
      borderRadius={2}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="body2" textAlign="center">
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Banner;
