import React from "react";
import { BiCheck } from "react-icons/bi";
import "./index.scss";

interface RadioProps {
  id: string;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  checked: boolean;
  value: string;
}

export const Radio = ({
  id,
  name,
  label,
  required,
  disabled,
  onChange,
  checked,
  value,
}: RadioProps) => {
  return (
    <div className="radio-btn-group">
      <div className="radio">
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          id={id}
          onChange={onChange}
        />
        <label
          htmlFor={id}
          style={checked ? { padding: "0.4rem 1.25rem 0.5rem 2rem" } : {}}
        >
          {label}
        </label>
        {checked && (
          <span className="redio-check">
            <BiCheck />
          </span>
        )}
      </div>
    </div>
  );
};
