import { Grid, Typography } from "@mui/material";

const ServiceUnavailablePage: React.FC = () => {
  return (
    <Grid
      container
      height="100vh"
      justifyContent="flex-start"
      alignItems="center"
      bgcolor="#F2F2F2"
      direction="column"
    >
      <Typography variant="h1" color="#DE3163">
        503
      </Typography>
      <Typography variant="h3">Service Unavailable</Typography>
    </Grid>
  );
};

export default ServiceUnavailablePage;
