import { Grid, Paper } from "@mui/material";
import CakeIcon from "@mui/icons-material/Cake";
import PersonIcon from "@mui/icons-material/Person";
import LineWeightIcon from "@mui/icons-material/LineWeight";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import EventIcon from "@mui/icons-material/Event";
import { Profile } from "models/profile";
import { convertCmToFeetAndInches } from "components/templates/Home/util/formatter";

type ProfileProps = {
  profile: Profile.Profile;
};

const Personalinfo = ({ profile }: ProfileProps) => {
  return (
    <Paper elevation={1}>
      <Grid container sx={{ padding: "12px" }}>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <CakeIcon sx={{ fontSize: 20, marginRight: 3 }} />
            </Grid>
            <Grid item xs={9}>
              {profile?.age}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <PersonIcon sx={{ fontSize: 20, marginRight: 3 }} />
            </Grid>
            <Grid item xs={9}>
              {profile?.ethnicity.name}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <LineWeightIcon sx={{ fontSize: 20, marginRight: 3 }} />
            </Grid>
            <Grid item xs={9}>
              {convertCmToFeetAndInches(profile.height)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <MenuBookIcon sx={{ fontSize: 20, marginRight: 3 }} />
            </Grid>
            <Grid item xs={9}>
              {profile?.religion.name}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <EventIcon sx={{ fontSize: 20, marginRight: 3 }} />
            </Grid>
            <Grid item xs={9}>
              {profile?.dob}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Personalinfo;
