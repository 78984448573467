import { useCallback, useEffect, useState } from "react";
import { Grid, Avatar, Typography, Button } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { Profile } from "models/profile";
import { getMatchThumbnail } from "services/api/mobile/match";
import { Match } from "models/match";
import { useNavigate } from "react-router-dom";

type PersonalInfoHeaderProps = {
  interest: Match.Match;
};

const PersonalInfoHeader = ({ interest }: PersonalInfoHeaderProps) => {
  const navigate = useNavigate();
  const [profileAvatar, setProfileAvatar] = useState<string | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { profile } = interest;

  const fetchProfileAvatar = useCallback(async () => {
    const avatar =
      (await getMatchThumbnail({
        matchId: interest.id,
        imageId: interest.thumbnail,
        thumbnail: true,
      })) || undefined;
    setProfileAvatar(avatar);
  }, [interest]);

  useEffect(() => {
    if (profileAvatar === undefined) {
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    if (loading) {
      // download profile avatar
      fetchProfileAvatar();
    }
  }, [loading]);

  const fullName = `${profile.firstName} ${profile.lastName}`;
  console.log(">>>> fullName ", fullName);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item>
        <Grid container direction="row" alignItems="center" columnSpacing={1}>
          <Grid item>
            <Avatar src={profileAvatar} />
          </Grid>
          <Grid item>
            <Typography variant="h6" color="#333333">
              {fullName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          variant="text"
          size="large"
          endIcon={<ArrowForwardIos />}
          sx={{ textTransform: "capitalize", color: "#9575CD" }}
          onClick={(e) => {
            e.preventDefault();
            const queryParams = new URLSearchParams();
            queryParams.append("interestId", interest.id);
            navigate(`/app/profile?${queryParams.toString()}`);
          }}
        >
          See Profile
        </Button>
      </Grid>
    </Grid>
  );
};

export default PersonalInfoHeader;
