import { useEffect } from "react";
import { NavBar, Typography } from "components";
import Copyright from "../Home/Copyright";

export const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <div className="container privacy-policy-container">
        <Typography
          variant="h1"
          style={{ marginBottom: "1rem", textAlign: "center" }}
        >
          TERMS & CONDITIONS
        </Typography>
        <Typography
          variant="h4"
          style={{ marginTop: 0, marginBottom: "3rem", textAlign: "center" }}
        >
          Last updated date: 16 November, 2023
        </Typography>

        <ol>
          <li className="p-title">
            <h2>AGREEMENT:</h2>
          </li>
          <p>
            These Terms and Conditions include Our Privacy Policy. They may be
            amended from time to time. They will govern your <b>[“You”]</b>{" "}
            access and use of the website Hytch.app <b>[“Site”/ “Hytch.app”]</b>
            , Hytch mobile apps <b>[“App”, “Hytch App”]</b> and its related
            services. By accessing and using Hytch and its related services, You
            agree to be legally bound by these Terms and Conditions.
          </p>

          <li className="p-title">
            <h2>SERVICES:</h2>
          </li>
          <p>
            <b>App:</b> Hytch is a matrimony services related mobile application
            created for Sri Lankans. You can create your account on Hytch and
            connect with other users using the application. The features of the
            app available to you will depend on the membership package you
            choose to select.
          </p>

          <li className="p-title">
            <h2>ABOUT US:</h2>
          </li>
          <p>
            The Hytch website and app are owned by Serendib Systems Pty Ltd,
            together with its affiliate partner, Serendib Systems Lanka (Pvt)
            Ltd, <b>[“We”, “Us”, or “Our”]</b> and third-party licensors provide
            the Services.
          </p>
          <p>
            Serendib Systems Pty Ltd is a company duly incorporated in Australia
            (ACN 637232038) and has its registered office at 2/15, McEwan Road,
            Heidelberg Heights, VIC3081, Australia.
          </p>

          <li className="p-title">
            <h2>ELIGIBILITY CRITERIA:</h2>
          </li>
          <p>To access and use Hytch, You:</p>
          <ol type="a">
            <li>Must not be less than 18 years of age.</li>
            <li>
              Must not be a minor under the laws and regulations applicable to
              You.
            </li>
          </ol>

          <li className="p-title">
            <h2>ACCOUNT CREATION:</h2>
          </li>
          <p>
            When creating an account on Hytch, you will be required to enter
            personal information and date in text and images. We reserve the
            right to edit or, if necessary, delete at any time any part of the
            Content, at our sole discretion and without warning or notice to
            You, in order to safeguard and preserve the integrity and ethical
            profile of Hytch.
          </p>
          <p>
            All images uploaded and made available on Hytch bear a watermark.
            This is to provide for easy identification and dissuade the images
            from being used for purposes other than those to which You, as an
            advertiser, have consented.
          </p>

          <li className="p-title">
            <h2>CONDITIONS GOVERNING USE OF THE SITE:</h2>
          </li>
          <ol type="a">
            <li>
              You are permitted to access and use Hytch only for the authorized
              purposes set out in Clause 2.
            </li>
            <li>
              You are solely responsible for ensuring that the Content provided
              and uploaded by You for inclusion on the Site complies with all
              applicable laws and regulations and that they do not violate any
              contractual rights with a third party or any privacy or data
              protection rights, as applicable.
            </li>
            <li>
              You will promptly update and correct any inaccuracies in the
              Content uploaded or provided by You, and such changes will be
              verified and changed by the Hytch team.
            </li>
          </ol>

          <li className="p-title">
            <h2>REPRESENTATIONS & WARRANTIES:</h2>
          </li>
          <p>By accessing and using Hytch, You represent and warrant that:</p>
          <ol type="a">
            <li>You satisfy the eligibility criteria in item 2 above;</li>
            <li>
              All personal information and data placed on your profile by You
              are true, accurate, current, complete and legally valid; and
            </li>
            <li>
              You are not prohibited by the laws and regulations applicable to
              you, to disclose, advertise, license, and or publish your personal
              information and data on Hytch.
            </li>
            <li>
              You are not prohibited by applicable laws and regulations and or
              by any contractual third-party rights to consent and authorize Us
              to collect, process, use, share and or disclose personal
              information and data.
            </li>
          </ol>

          <li className="p-title">
            <h2>CONFIDENTIALITY:</h2>
          </li>
          <p>
            Without your prior consent, Your personal information, data, and
            identity will be kept confidential except to the extent provided for
            in these Terms and Conditions and or if an obligation arises to make
            a disclosure:
          </p>
          <ol type="a">
            <li>to comply with the law or any regulations; and or</li>
            <li>
              to protect and enforce our rights or the rights of others who use
              Hytch
            </li>
          </ol>

          <li className="p-title">
            <h2>PRIVACY POLICY:</h2>
          </li>
          <p>
            The Privacy Policy explains how personal information and data are
            collected, used, shared, disclosed or otherwise processed. The Site
            uses cookies and similar technologies.
          </p>
          <p>
            By using Hytch, You consent to the collection, processing, using,
            sharing, and or disclosing of personal information and data provided
            by You in line with the Privacy Policy. Please read the Privacy
            Policy for details.
          </p>

          <li className="p-title">
            <h2>CANCELLATION OF YOUR ACCOUNT:</h2>
          </li>
          <p>
            You can delete your account at any time by yourself through the
            settings page on the app. If you wish to remove all the content from
            our databases, you can request so via email.
          </p>
          <p>
            You can unsubscribe from Hytch at any time to opt out from renewing
            your subscription but note that the subscriptions are managed by
            Google Play Store and Apple App Store policies therefore the current
            subscription fee is non-refundable in any case.
          </p>

          <li className="p-title">
            <h2>INTELLECTUAL PROPERTY:</h2>
          </li>
          <p>
            Hytch app, Hytch.app website and its databases, text, photographs,
            logos, trademarks and all other intellectual property created and or
            used in Hytch, including without limitation all software, design
            work, layout, appearance, graphics etc, are owned by Us or are
            licensed to Us and are protected by the Intellectual Property laws
            of Australia. No material on Hytch may be copied, downloaded,
            reproduced, republished, stored, photographed, transmitted,
            installed, posted, or distributed without Our written permission.
          </p>
          <li className="p-title">
            <h2>DISCLAIMER:</h2>
          </li>
          <p>
            We assume no responsibility for your use of Hytch. You agree that We
            shall not in any way be held liable to You for any loss, damage, or
            injury resulting from or arising out of or which is in any way
            related to:
          </p>
          <ol type="a">
            <li>
              Errors on Hytch or the Content including, but not limited to
              technical and typographical errors;
            </li>
            <li>
              Inability to access, or use, or the unavailability of Hytch;
            </li>
            <li>Your use of Hytch or the content; or</li>
            <li>Your use of any software in connection with Hytch</li>
          </ol>

          <li className="p-title">
            <h2>INDEMNITY:</h2>
          </li>
          <p>
            By accessing and using Hytch.app and Hytch mobile app You agree to
            indemnify and hold harmless Serendib Systems Pty Ltd, its officers,
            directors, employees and agents from and against all claims or
            actions by third parties and any losses, expenses, damages and costs
            including attorneys fees resulting from any violation by You of
            these Terms and Conditions including negligent or wrongful conduct
            by You in accessing and using Hytch.
          </p>

          <li className="p-title">
            <h2>TERMINATION / ITS CONSEQUENCES:</h2>
          </li>
          <p>
            This Agreement will terminate forthwith in the following situations:
          </p>
          <ol type="a">
            <li>On the expiry of the advertising term; or</li>
            <li>
              On your deletion of the account during the advertising term; or
            </li>
            <li>
              By a breach of any of these Terms and Conditions, by You including
              of any representation or warranty therein, or of any applicable
              law or regulation; or
            </li>
          </ol>
          <p>
            Consequences of termination include Our right to forthwith and
            without notice to you or liability terminate your access to and use
            of Hytch. and delete your Content from the Site.
          </p>

          <li className="p-title">
            <h2>AMENDMENTS TO THE TERMS AND CONDITIONS:</h2>
          </li>
          <p>
            These Terms and Conditions may be amended from time to time at our
            sole discretion. The amended Terms and Conditions will be effective
            immediately upon posting on the Hytch.app website, and your
            continued access and use of the Site and App after the posting date
            shall be deemed to constitute your acceptance of the amended Terms
            and Conditions.
          </p>

          <li className="p-title">
            <h2>GOVERNING LAW & JURISDICTION:</h2>
          </li>
          <p>
            These Terms and Conditions shall be construed and interpreted in
            accordance with the laws of Australia. The Courts of Australia, with
            the District Court of Victoria as the Court of first instance, shall
            have exclusive jurisdiction in relation to all disputes arising
            under or in relation to this Agreement.
          </p>
        </ol>
      </div>
      <Copyright />
    </>
  );
};
