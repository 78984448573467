import React from "react";
import "./index.scss";

interface IProps {
  size?: "small" | "medium" | "large";
}

export const Spinner = ({ size }: IProps) => {
  return (
    <div className={`lds-ring spinner-${size}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

Spinner.defaultProps = {
  size: "medium",
};
