import { Grid, Snackbar, Alert } from "@mui/material";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import AppConfig from "config/application";
import { useViewPort } from "hooks";
import { useAppDispatch, useAppSelector } from "store";
import { fetchGenericLists } from "store/application/generics";
import { setAppAlert } from "store/application";
import Navigation from "./Navigation";

const MobileApplication = () => {
  const viewport = useViewPort();
  const dispatch = useAppDispatch();
  const { mainScreen, showNavigation, showWebView, appAlert } = useAppSelector(
    (state) => state.application,
  );

  useEffect(() => {
    dispatch(fetchGenericLists());
  }, []);

  return (
    <Grid
      container
      direction="column"
      height={1}
      justifyContent="space-between"
      paddingX={0.2}
    >
      <Grid item xs height={1}>
        <Outlet />
      </Grid>
      {showNavigation && (
        <Grid item paddingY={1}>
          <Navigation />
        </Grid>
      )}
      <Snackbar
        open={appAlert !== null}
        anchorOrigin={{
          horizontal: showWebView ? "right" : "center",
          vertical: showWebView ? "top" : "bottom",
        }}
        autoHideDuration={
          appAlert?.duration || AppConfig.appAlertAutoHideDuration
        }
        sx={{
          ...(showWebView ? {} : { bottom: 60 }),
          width: { md: viewport.width * 0.3 },
        }}
        onClose={(e) => {
          dispatch(setAppAlert(null));
        }}
      >
        {appAlert !== null ? (
          <Alert severity={appAlert.severity} sx={{ width: "100%" }}>
            {appAlert.message}
          </Alert>
        ) : (
          <Alert severity="error" sx={{ width: "100%" }}>
            Error Occurred
          </Alert>
        )}
      </Snackbar>
    </Grid>
  );
};

export default MobileApplication;
