import { Grid, Paper, Typography } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import { Profile } from "models/profile";

type ProfileProps = {
  profile: Profile.Profile;
};

const EducationInfo = ({ profile }: ProfileProps) => {
  return (
    <Paper elevation={3} sx={{ width: "100%", height: "100%" }}>
      <Grid container sx={{ padding: "12px" }}>
        <Grid item xs={12}>
          <SchoolIcon sx={{ fontSize: 20, marginRight: 3 }} />
          {profile?.educationLevel.name}
        </Grid>
        <Grid item xs={12}>
          <WorkIcon sx={{ fontSize: 20, marginRight: 3 }} />
          {profile?.profession.name}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EducationInfo;
