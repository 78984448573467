import { useEffect, useState } from "react";
import { Select, Button, Spinner } from "components";
import { RootState, useAppDispatch, useAppSelector } from "store";
import {
  getCheckoutDetails,
  getUserAccount,
  getUserProfile,
  setSelectedMonths,
} from "store/features/User";
import { setToken } from "store/auth";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import "./index.scss";
import PageLoading from "components/molecules/PageLoading";

export const Payment = () => {
  const stripeId = process.env.REACT_APP_STRIPE_KEY;
  const stripePromise = loadStripe(stripeId!);

  const [months, setMonths] = useState<string>("1");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const savedToken = useAppSelector((state: RootState) => state.auth.token);
  const account = useAppSelector((state: RootState) => state.user.account);
  const profile = useAppSelector((state: RootState) => state.user.profile);
  const checkoutData = useAppSelector(
    (state: RootState) => state.user.checkoutData,
  );
  const loadingAccount = useAppSelector(
    (state: RootState) => state.user.loadingAccount,
  );
  const loadingProfile = useAppSelector(
    (state: RootState) => state.user.loadingProfile,
  );
  const loadingCheckout = useAppSelector(
    (state: RootState) => state.user.loadingCheckout,
  );

  useEffect(() => {
    let token = new URL(window.location.href).searchParams.get("t");
    if (token === null) {
      token = localStorage.getItem("auth_token");
    }
    if (token) {
      dispatch(setToken(token));
      dispatch(setSelectedMonths(months));
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (savedToken) {
      dispatch(getUserAccount());
      dispatch(getUserProfile());
      dispatch(getCheckoutDetails(months));
    }
  }, [savedToken]);

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMonths(e.target.value);
    dispatch(getCheckoutDetails(e.target.value));
    dispatch(setSelectedMonths(e.target.value));
  };

  const handlePay = async () => {
    if (checkoutData.ipg === "STRIPE") {
      const stripe = await stripePromise;
      stripe!.redirectToCheckout({
        sessionId: checkoutData.stripe_params.session_id,
      });
    }
  };

  return (
    <div className="payment_page">
      <div className="container">
        <div className="paymet_container">
          <h2 className="h2-title text-center">Renew Membership</h2>

          <div className="payment_section_wrapper">
            <div className="pay_user_details">
              <div className="payment_user_details">
                <h2>
                  {profile.first_name
                    ? `${profile.first_name} ${profile.last_name}`
                    : "User"}
                </h2>
                <h3>{account.phone_number}</h3>
              </div>

              <div className="payment_info">
                <div className="membership_details">
                  <div className="duration-text">
                    <h3>Membership duration</h3>
                  </div>

                  <div className="duration-select-wrapper">
                    <Select
                      id="duration-select"
                      onChange={(e) => handleMonthChange(e)}
                      label=""
                      value={months}
                      disabled={savedToken === ""}
                    >
                      <option value="1">1 Month</option>
                      <option value="3">3 Month</option>
                      <option value="6">6 Month</option>
                    </Select>
                  </div>
                </div>
                <p>
                  The selected membership period will begin once your current
                  membership ends. You will not lose your current active
                  membership days.
                </p>
                <div className="payment_amount">
                  <h3>Total</h3>
                  {loadingCheckout === "pending" ? (
                    <Spinner size="small" />
                  ) : (
                    <h3>
                      {checkoutData?.stripe_params
                        ? `${(
                            checkoutData.stripe_params.amount_cents / 100
                          ).toFixed(2)} ${checkoutData.stripe_params.currency}`
                        : "Amount"}
                    </h3>
                  )}
                </div>
              </div>

              <p className="text-center">
                By continuing you agree to the Hytch Terms of Services an
                Privacy Policy.
              </p>
            </div>

            <div className="payment_type">
              <div className="pay_button_wrapper">
                <Button text="Online Payment" onClick={handlePay} />
                <p>Credit/ Debit cards including Visa, Master, Amex.</p>
              </div>

              <div className="pay_button_wrapper">
                <Button
                  text="Bank Deposit/ Transfer"
                  buttonType="secondary"
                  onClick={() => navigate("/payment/bank-transfer")}
                />
                <p>
                  Deposit or transfer to a bank account. Account details will be
                  displayed.
                </p>
              </div>
            </div>
          </div>
        </div>

        {loadingAccount === "pending" || loadingProfile === "pending" ? (
          <PageLoading />
        ) : null}
      </div>
    </div>
  );
};
