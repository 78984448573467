import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "models/enum";
import { LoadingStatuses } from "models/models";
import agent from "services";

export const getMasterData = createAsyncThunk(
  "masterData/getMasterData",
  async (args, thunkAPI) => {
    try {
      const response = await agent.MasterData.getMasterData();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAllCountries = createAsyncThunk(
  "masterData/getAllCountryList",
  async (args, thunkAPI) => {
    try {
      const response = await agent.MasterData.getAllCountryList();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getDetailedCountries = createAsyncThunk(
  "masterData/getDetailedCountries",
  async (args, thunkAPI) => {
    try {
      const response = await agent.MasterData.getDetailedCountryList();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getMatchQuestions = createAsyncThunk(
  "masterData/getMatchQuestions",
  async (args, thunkAPI) => {
    try {
      const response = await agent.MasterData.getMatchQuestionsList();
      console.log("aaa response", response);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

interface MasterDataState {
  masterData: {
    education_levels: any[];
    ethnicities: any[];
    professions: any[];
    religions: any[];
  };
  location: {
    allCountryList: any[];
    detailedCountryList: any[];
  };
  matchQuestions: any[];
  loading: LoadingStatuses;
}

const initialState = {
  masterData: {
    education_levels: [],
    ethnicities: [],
    professions: [],
    religions: [],
  },
  location: {
    allCountryList: [],
    detailedCountryList: [],
  },
  matchQuestions: [],
  loading: LoadingStatus.IDLE,
} as MasterDataState;

const masterDataSlice = createSlice({
  name: "masterData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMasterData.pending, (state) => {
      state.loading = LoadingStatus.PENDING;
    });
    builder.addCase(getMasterData.fulfilled, (state, action) => {
      state.loading = LoadingStatus.SUCCEEDED;
      state.masterData = action.payload;
    });
    builder.addCase(getMasterData.rejected, (state) => {
      state.loading = LoadingStatus.FAILED;
    });

    builder.addCase(getAllCountries.fulfilled, (state, action) => {
      state.location.allCountryList = action.payload;
    });

    builder.addCase(getDetailedCountries.fulfilled, (state, action) => {
      state.location.detailedCountryList = action.payload;
    });

    builder.addCase(getMatchQuestions.fulfilled, (state, action) => {
      state.matchQuestions = action.payload;
    });
  },
});

const { reducer } = masterDataSlice;
export default reducer;
