import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateFlowScreen, LoadingStatus } from "models/enum";
import { CreateFlowScreens, LoadingStatuses, TempProfile } from "models/models";
import agent from "services";

export const getUserAccount = createAsyncThunk(
  "createFlow/getUserAccount",
  async (args, thunkAPI) => {
    try {
      const response = await agent.Payment.getUserAccount();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getTempProfile = createAsyncThunk(
  "createFlow/getTemporaryProfile",
  async (args, thunkAPI) => {
    try {
      const response = await agent.CreateFlow.savedTempProfileData();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const addPhoneNumber = createAsyncThunk(
  "createFlow/phoneNumber",
  async (args, thunkAPI) => {
    const { createFlow }: any = thunkAPI.getState();
    const { loginDetails } = createFlow;
    try {
      const phoneNumber = `+${loginDetails.countryCode}${loginDetails.phoneNumber}`;
      const response = await agent.CreateFlow.phoneNumber(phoneNumber);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

interface CreateFlow {
  loginDetails: {
    countryCode: string;
    phoneNumber: string;
    phoneID: string;
    otp: string | undefined;
    verifiedPhoneID: string;
  };
  profile: TempProfile;
  currentScreen: CreateFlowScreens;
  loading: LoadingStatuses;
  error: boolean;
}

const initialState = {
  loginDetails: {
    countryCode: "94",
    phoneNumber: "",
    phoneID: "",
    otp: "",
    verifiedPhoneID: "",
  },
  profile: {
    first_name: "",
    last_name: "",
    gender: "",
    dob: "",
    height: 0,
    ethnicity_id: "",
    religion_id: "",
    resident_country: "",
    other_resident_country: "",
    resident_city: "",
    resident_city_lat: 0,
    resident_city_lon: 0,
    education_level_id: 0,
    profession_id: 0,
    profession_other: "",
    drinking: "",
    smoking: "",
    food_preference: "",
    public_avatar: "",
    images: [""],
    match_question: "",
    region_id: "",
  },
  currentScreen: CreateFlowScreen.LOGIN_SIGNIN,
  loading: LoadingStatus.IDLE,
} as CreateFlow;

const createFlowSlice = createSlice({
  name: "createFlow",
  initialState,
  reducers: {
    setCurrentScreen: (state, { payload }) => {
      state.currentScreen = payload;
    },
    setLoginDetails: (state, { payload }) => {
      state.loginDetails = { ...state.loginDetails, ...payload };
    },
    setProfileDetails: (state, { payload }) => {
      state.profile = { ...state.profile, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addPhoneNumber.pending, (state) => {
      state.loading = LoadingStatus.PENDING;
    });
    builder.addCase(addPhoneNumber.fulfilled, (state, { payload }) => {
      state.loading = LoadingStatus.SUCCEEDED;
      state.loginDetails.phoneID = payload.id;
    });
    builder.addCase(addPhoneNumber.rejected, (state) => {
      state.loading = LoadingStatus.FAILED;
    });

    builder.addCase(getTempProfile.fulfilled, (state, { payload }) => {
      state.profile = { ...state.profile, ...payload };
    });
  },
});

const { actions, reducer } = createFlowSlice;
export const { setCurrentScreen, setLoginDetails, setProfileDetails } = actions;
export default reducer;
