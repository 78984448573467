import { Button, Grid, Typography } from "@mui/material";

type ButtonProps = {
  label: string;
  handler: () => void;
};
type Props = {
  postiveRespond: ButtonProps;
  negativeRespond?: ButtonProps;
  containerStyles?: React.CSSProperties;
};

const FormResponder = ({
  postiveRespond,
  negativeRespond,
  containerStyles,
}: Props) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={containerStyles !== undefined ? containerStyles : {}}
    >
      <Grid item width={1}>
        <Button
          variant="contained"
          fullWidth
          disableElevation
          sx={{ borderRadius: 10, backgroundColor: "#9575CD" }}
          onClick={(e) => {
            e.preventDefault();
            postiveRespond.handler();
          }}
        >
          {postiveRespond.label}
        </Button>
      </Grid>
      {negativeRespond !== undefined && (
        <Grid item paddingY={1}>
          <Typography
            variant="subtitle1"
            onClick={(e) => {
              e.preventDefault();
              negativeRespond.handler();
            }}
            style={{
              textAlign: "center",
              textDecoration: "underline",
              fontWeight: 500,
            }}
          >
            Discard Changes
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
export default FormResponder;
