import { Grid, Skeleton, Typography } from "@mui/material";

const ContactListItemSkeleton = () => {
  return (
    <Grid item xs={12}>
      <Grid container direction="row" sx={{ paddingY: 1 }}>
        <Grid item xs={2} alignItems="center">
          <Grid container alignItems="center" justifyContent="center">
            <Skeleton variant="circular" width={50} height={50} />
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Skeleton variant="text" width="45%" height={20} />
                <Skeleton variant="text" width="20%" height={20} />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Skeleton variant="text" width="60%" height={20} />
                <Skeleton variant="circular" height={15} width={15} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactListItemSkeleton;
