import { Grid, Skeleton } from "@mui/material";

const ProfileSkeleton = () => {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Skeleton variant="circular" width={80} height={80} />
      </Grid>
      <Grid item>
        <Skeleton variant="text" width={160} height={40} />
      </Grid>
      <Grid item>
        <Skeleton variant="rectangular" width={60} height={30} />
      </Grid>
    </Grid>
  );
};

export default ProfileSkeleton;
