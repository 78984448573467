import PoruwaImage from "../../../../assets/images/poruwa-logo.png";

const Poruwa = () => {
  return (
    <section className="section-common__2 poruwa-section">
      <div className="container">
        <div className="poruwa-wrapper">
          <p className="about-poruwa-main">Brought to you by</p>
          <a
            href="https://www.poruwa.lk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={PoruwaImage} alt="poruwa logo" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Poruwa;
