import "./index.scss";

interface SelectProps {
  label: string | number;
  id: string;
  name?: string;
  value?: string;
  onChange: (e: any) => void;
  children: React.ReactNode;
  disabled?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

export const Select = ({
  id,
  name,
  value,
  label,
  onChange,
  children,
  disabled,
  isError,
  errorMessage,
}: SelectProps) => {
  return (
    <div className="input-container">
      {label && <label htmlFor={id}>{label}</label>}
      <select
        className={`h-select ${isError && "input-error"}`}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {children}
      </select>
      {isError && <span className="input-error-msg">{errorMessage}</span>}
    </div>
  );
};

Select.defaultProps = {
  name: undefined,
  value: undefined,
  disabled: false,
};
