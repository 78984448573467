import { useEffect, useState } from "react";
import { Button, Typography, Select, BreadcrumbItem } from "components";
import { CreateFlowScreen } from "models/enum";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { setCurrentScreen, setProfileDetails } from "store/features/CreateFlow";
import agent from "services";
import { getMasterData } from "store/MasterData";
import { BiUser } from "react-icons/bi";
import "./index.scss";

export const PersonalBackground = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    ethnicityError: false,
    religionError: false,
    ethnicityErrorMsg: "",
    religionErrorMsg: "",
  });

  const profile = useAppSelector(
    (state: RootState) => state.createFlow.profile,
  );
  const masterData = useAppSelector(
    (state: RootState) => state.masterData.masterData,
  );

  const handleOnSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: string,
  ) => {
    dispatch(setProfileDetails({ [prop]: event.target.value }));
  };

  const handleBack = () => {
    dispatch(setCurrentScreen(CreateFlowScreen.ADD_BIRTHDAY));
  };

  const validate = () => {
    const status = {
      ethnicityError: false,
      religionError: false,
      ethnicityErrorMsg: "",
      religionErrorMsg: "",
    };

    if (profile.ethnicity_id === "") {
      status.ethnicityError = true;
      status.ethnicityErrorMsg = "Please select your ethnicity";
    } else {
      status.ethnicityError = false;
      status.ethnicityErrorMsg = "";
    }

    if (profile.religion_id === "") {
      status.religionError = true;
      status.religionErrorMsg = "Please select your religion";
    } else {
      status.religionError = false;
      status.religionErrorMsg = "";
    }

    if (status.ethnicityError || status.religionError) {
      setErrors(status);
      return false;
    }
    setErrors(status);
    return true;
  };

  const handleNext = async () => {
    if (validate()) {
      setIsLoading(true);
      const saveTempUserData = {
        first_name: profile.first_name,
        last_name: profile.last_name,
        gender: profile.gender,
        dob: profile.dob,
        height: profile.height,
        ethnicity_id: profile.ethnicity_id,
        religion_id: profile.religion_id,
      };

      await agent.CreateFlow.updateTempProfile(saveTempUserData)
        .then(() => {
          setIsLoading(false);
          dispatch(setCurrentScreen(CreateFlowScreen.EDUCATION));
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    dispatch(getMasterData());
  }, []);

  return (
    <div className="login-signup-screen flow-step">
      <div className="create-flow-content">
        <div className="breadcrum-container">
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem icon={<BiUser />} />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
        </div>
        <Typography variant="h1" style={{ marginBottom: "2rem" }}>
          What’s your <br /> background?
        </Typography>
        <div className="inputs-wrapper">
          <Select
            label="Ethnicity"
            id="ethnicity"
            name="ethnicity"
            value={profile.ethnicity_id}
            onChange={(event) => handleOnSelect(event, "ethnicity_id")}
            isError={errors.ethnicityError}
            errorMessage={errors.ethnicityErrorMsg}
          >
            <option value="" selected disabled>
              Please select
            </option>
            {masterData?.ethnicities
              ?.slice()
              .sort((a, b) => a.sort_order - b.sort_order)
              .map((ethnicity) => (
                <option key={ethnicity.id} value={ethnicity.id}>
                  {ethnicity.name}
                </option>
              ))}
          </Select>

          <Select
            label="Religion"
            id="religion"
            name="religion"
            value={profile.religion_id}
            onChange={(event) => handleOnSelect(event, "religion_id")}
            isError={errors.religionError}
            errorMessage={errors.religionErrorMsg}
          >
            <option value="" selected disabled>
              Please select
            </option>
            {masterData?.religions
              ?.slice()
              .sort((a, b) => a.sort_order - b.sort_order)
              .map((religion) => (
                <option key={religion.id} value={religion.id}>
                  {religion.name}
                </option>
              ))}
          </Select>
        </div>
      </div>

      <div className="action-button">
        <Button text="Next" onClick={handleNext} loading={isLoading} />
        <Button text="Back" buttonType="text" onClick={handleBack} />
      </div>
    </div>
  );
};
