import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch } from "store";

import { setShowNavigation } from "store/application";

type ModalNavigationScreenProps = {
  children: React.ReactNode;
  paddingY: number;
  paddingX: number;
};

const ModalNavigationScreen = ({
  children,
  paddingX = 1,
  paddingY = 2,
}: ModalNavigationScreenProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setShowNavigation(false));

    return () => {
      dispatch(setShowNavigation(true));
    };
  }, []);

  return (
    <Grid
      container
      direction="row"
      height={1}
      alignItems="stretch"
      paddingY={paddingY}
      paddingX={paddingX}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default ModalNavigationScreen;
