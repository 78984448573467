import { useEffect, useState, useCallback } from "react";
import { Grid, Avatar, Typography, Button } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { fetchAuthProfile, fetchAuthAvatar } from "store/application";
import { Profile } from "models/profile";
import { ProfileStatusMeta } from "config/profile";
import AppStoreBadge from "assets/images/appstore-download.png";
import PlayStoreBadge from "assets/images/playstore-download.png";
import { useViewPort } from "hooks";
import ProfileSkeleton from "./ProfileSkeleton";

const Navigation = () => {
  const { authAccount, authProfile, authAvatar } = useAppSelector(
    (state) => state.application,
  );
  const dispatch = useAppDispatch();
  const screen = useViewPort();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [profileStatus, setProfileStatus] =
    useState<Profile.ProfileStatus | null>(null);

  const getAuthData = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(fetchAuthProfile());
      await dispatch(fetchAuthAvatar());
    } catch (e) {
      console.log("Error ", e);
    } finally {
      setLoading(false);
    }
  }, [authAccount]);

  useEffect(() => {
    if (!isEmpty(authAccount) && !loading) {
      getAuthData();
    }
  }, [authAccount, dispatch]);

  useEffect(() => {
    if (!isEmpty(authProfile)) {
      setProfileStatus(authProfile.status);
    }
  }, [authProfile]);

  return (
    <Grid
      container
      direction="column"
      sx={{
        display: {
          xs: "none",
          sm: "none",
          md: "flex",
        },
      }}
      bgcolor="#F2F2F2"
      height={1}
      width={1}
      justifyContent="space-between"
      paddingY={3}
      paddingX={2}
    >
      {authProfile !== null ? (
        <Grid item container direction="column" alignItems="center">
          <Grid item>
            <Avatar
              variant="circular"
              src={authAvatar || undefined}
              sx={{ width: 90, height: 90 }}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              color="#333333"
              textAlign="center"
              width={1}
              fontSize={18}
            >{`${authProfile.firstName} ${authProfile.lastName}`}</Typography>
          </Grid>
          <Grid item container direction="row" justifyContent="center">
            <Grid item>
              <Typography
                variant="caption"
                bgcolor={ProfileStatusMeta[authProfile.status].tag.bgColor}
                color={ProfileStatusMeta[authProfile.status].tag.fontColor}
                paddingY={0.5}
                paddingX={1}
                borderRadius={3}
                fontSize={12}
              >
                {ProfileStatusMeta[authProfile.status].label}
              </Typography>
            </Grid>
            {authProfile.trialMode && (
              <Grid item>
                <Typography
                  variant="caption"
                  bgcolor="#007fbf"
                  color={ProfileStatusMeta[authProfile.status].tag.fontColor}
                  paddingY={0.5}
                  paddingX={1}
                  borderRadius={3}
                  fontSize={12}
                >
                  Trail Mode
                </Typography>
              </Grid>
            )}
          </Grid>
          {(authProfile.trialMode || authProfile.status === "EXPIRED") && (
            <Grid item paddingY={1}>
              <Button
                variant="contained"
                size="small"
                startIcon={<PaymentIcon />}
                sx={{ backgroundColor: "#9575CD" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/payment");
                }}
              >
                Pay Now
              </Button>
            </Grid>
          )}
        </Grid>
      ) : (
        <ProfileSkeleton />
      )}
      <Grid item>
        <Typography
          variant="body1"
          fontWeight={300}
          fontSize={13}
          color="#333333"
          marginY={1}
        >
          Download the Hytch app from Google Playstore and Apple Appstore
          (coming soon)
        </Typography>
        <Grid container direction="row" columnSpacing={1}>
          <Grid item sm={6}>
            <img
              src={PlayStoreBadge}
              alt="app-store"
              width="100%"
              style={{ objectFit: "contain" }}
            />
          </Grid>
          <Grid item sm={6}>
            <img
              src={AppStoreBadge}
              alt="playstore-store"
              width="100%"
              style={{
                objectFit: "cover",
                opacity: 0.5,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Navigation;
