import { Typography } from "@mui/material";
import { detectOS } from "components/templates/Home/util/platform";
import { useEffect } from "react";

const DownloadMobileApp = () => {
  useEffect(() => {
    const platform = detectOS();
    if (platform !== null) {
      switch (platform) {
        case "android":
          document.location =
            "https://play.google.com/store/apps/details?id=com.serendib.hytch";
          break;

        case "ios":
          document.location = "https://apps.apple.com/app/hytch/id1597740003";
          break;

        default:
          document.location = "https://www.hytch.app/";
          break;
      }
    }

    window.onload = detectOS;

    window.addEventListener("load", detectOS);
    return () => {
      window.removeEventListener("load", detectOS);
    };
  }, []);

  return (
    <>
      <Typography>hytch application</Typography>
      <Typography>Download mobile Application</Typography>
    </>
  );
};

export default DownloadMobileApp;
