/* eslint-disable react/button-has-type */
import classNames from "classnames";
import "./index.scss";

interface ButtonProps {
  type?: "button" | "submit" | "reset";
  text?: string | number;
  buttonType?: "primary" | "secondary" | "outline" | "text";
  onClick?: () => void;
  icon?: string;
  disabled?: boolean;
  loading?: boolean;
  shape?: "rounded" | "square";
}
export const Button = ({
  type,
  text,
  buttonType,
  onClick,
  icon,
  disabled,
  loading,
  shape,
}: ButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={classNames("h-button", buttonType, `shape-${shape}`)}
      onClick={onClick}
    >
      {loading ? (
        <div className="spinner">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      ) : (
        <>
          {icon && <i className={`h-button__icon ${icon}`} />}
          {text}
        </>
      )}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  buttonType: "primary",
  onClick: undefined,
  text: "",
  disabled: false,
  loading: false,
  icon: undefined,
  shape: "rounded",
};
