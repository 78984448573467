import React from "react";
import { RootState, useAppSelector } from "store";

export const BankTransfer = () => {
  const account = useAppSelector((state: RootState) => state.user.account);
  const profile = useAppSelector((state: RootState) => state.user.profile);
  const months = useAppSelector((state: RootState) => state.user.months);
  const checkoutData = useAppSelector(
    (state: RootState) => state.user.checkoutData,
  );

  return (
    <div className="payment_page">
      <div className="container">
        <div className="paymet_container bank-transfer">
          <h2 className="h2-title text-center">Renew Membership</h2>
          <h4 className="text-center">Bank Deposit/ Transfer</h4>

          <div className="payment_section_wrapper">
            <div className="pay_user_details">
              <div className="payment_user_details">
                <h2>
                  {profile.first_name
                    ? `${profile.first_name} ${profile.last_name}`
                    : "User"}
                </h2>
                <h3>{account.phone_number}</h3>
              </div>

              <div className="payment_info">
                <div className="membership_details">
                  <h3>Membership duration</h3>
                  <h3>{months} Month(s)</h3>
                </div>
                <p>
                  The selected membership period will begin once your current
                  membership ends. You will not lose your current active
                  membership days.
                </p>

                <div className="payment_amount">
                  <h3>Total</h3>
                  <h3>
                    {checkoutData?.stripe_params
                      ? `${(
                          checkoutData.stripe_params.amount_cents / 100
                        ).toFixed(2)} ${checkoutData.stripe_params.currency}`
                      : "Amount"}
                  </h3>
                </div>
              </div>
            </div>

            <div className="pay_bank_details">
              <h5>Bank Account </h5>

              <div className="bank_details_wrapper">
                <div className="bank_details_item">
                  <div className="bank_details">
                    <h6>Acc Name :</h6>
                  </div>

                  <div className="bank_details">
                    <h6>Serendib Systems Lanka Pvt Ltd</h6>
                  </div>
                </div>

                <div className="bank_details_item">
                  <div className="bank_details">
                    <h6>Acc No :</h6>
                  </div>

                  <div className="bank_details">
                    <h6>017510008154</h6>
                  </div>
                </div>

                <div className="bank_details_item">
                  <div className="bank_details">
                    <h6>Bank :</h6>
                  </div>

                  <div className="bank_details">
                    <h6>Sampath Bank Plc (7278)</h6>
                  </div>
                </div>

                <div className="bank_details_item">
                  <div className="bank_details">
                    <h6>Branch :</h6>
                  </div>

                  <div className="bank_details">
                    <h6>Colombo Super (175)</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="bank_pay_info">
              <p className="mb-0">
                Please deposit/transfer the due amount to the bank account
                mentioned above and send the receipt to pay@hytch.cc (mention
                your phone number as the email subject).{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
