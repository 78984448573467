import { useState } from "react";
import { Button, Typography, Input, Select, BreadcrumbItem } from "components";
import { CreateFlowScreen } from "models/enum";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { setCurrentScreen, setProfileDetails } from "store/features/CreateFlow";
import agent from "services";
import { MdOutlineShield } from "react-icons/md";
import "./index.scss";

export const PersonalDetails = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    firstNameError: false,
    lastNameError: false,
    genderError: false,
    firstNameErrorMsg: "",
    lastNameErrorMsg: "",
    genderErrorMsg: "",
  });

  const profile = useAppSelector(
    (state: RootState) => state.createFlow.profile,
  );

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: string,
  ) => {
    dispatch(setProfileDetails({ [prop]: event.target.value }));
  };

  const handleSelectGender = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setProfileDetails({ gender: event.target.value }));
  };

  const handleBack = () => {
    dispatch(setCurrentScreen(CreateFlowScreen.LOGIN_SIGNIN));
  };

  const validate = () => {
    const status = {
      firstNameError: false,
      lastNameError: false,
      genderError: false,
      firstNameErrorMsg: "",
      lastNameErrorMsg: "",
      genderErrorMsg: "",
    };

    if (profile.first_name === "") {
      status.firstNameError = true;
      status.firstNameErrorMsg = "Please enter your first name";
    } else {
      status.firstNameError = false;
      status.firstNameErrorMsg = "";
    }

    if (profile.last_name === "") {
      status.lastNameError = true;
      status.lastNameErrorMsg = "Please enter your last name";
    } else {
      status.lastNameError = false;
      status.lastNameErrorMsg = "";
    }

    if (profile.gender === "") {
      status.genderError = true;
      status.genderErrorMsg = "Please select your gender";
    } else {
      status.genderError = false;
      status.genderErrorMsg = "";
    }

    if (status.firstNameError || status.lastNameError || status.genderError) {
      setErrors(status);
      return false;
    }
    setErrors(status);
    return true;
  };

  const handleNext = async () => {
    const isFormValid = validate();
    if (isFormValid) {
      setIsLoading(true);
      const saveTempUserData = {
        first_name: profile.first_name,
        last_name: profile.last_name,
        gender: profile.gender,
      };

      await agent.CreateFlow.updateTempProfile(saveTempUserData)
        .then(() => {
          setIsLoading(false);
          dispatch(setCurrentScreen(CreateFlowScreen.ADD_BIRTHDAY));
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="login-signup-screen flow-step">
      <div className="create-flow-content">
        <div className="breadcrum-container">
          <BreadcrumbItem active />
          <BreadcrumbItem active />
          <BreadcrumbItem icon={<MdOutlineShield />} />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
          <BreadcrumbItem />
        </div>
        <Typography variant="h1" style={{ marginBottom: "2rem" }}>
          Add your <br /> personal details
        </Typography>
        <div className="inputs-wrapper">
          <Input
            label="First Name"
            name="first_name"
            type="text"
            value={profile.first_name}
            onChange={(event) => handleOnChange(event, "first_name")}
            isError={errors.firstNameError}
            errorMessage={errors.firstNameErrorMsg}
          />

          <Input
            label="Last Name"
            name="last_name"
            type="text"
            value={profile.last_name}
            onChange={(event) => handleOnChange(event, "last_name")}
            isError={errors.lastNameError}
            errorMessage={errors.lastNameErrorMsg}
          />
          <Typography
            variant="smallRegular"
            style={{ marginTop: "-5px", marginBottom: "0.6rem" }}
          >
            Your last name will not be shared with other users until you match.
          </Typography>

          <Select
            label="Gender"
            id="gender"
            name="gender"
            value={profile.gender}
            onChange={handleSelectGender}
            isError={errors.genderError}
            errorMessage={errors.genderErrorMsg}
          >
            <option value="" selected disabled>
              Please select
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </Select>
        </div>
      </div>

      <div className="action-button">
        <Button text="Next" onClick={handleNext} loading={isLoading} />
        <Button text="Back" buttonType="text" onClick={handleBack} />
      </div>
    </div>
  );
};
