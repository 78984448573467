import "./index.scss";

interface InputProps {
  label?: string | number;
  id?: string;
  name: string;
  value?: string | number;
  type?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isError?: boolean;
  errorMessage?: string;
}

export const Input = ({
  id,
  name,
  value,
  type,
  label,
  onChange,
  isError,
  errorMessage,
}: InputProps) => {
  return (
    <div className="input-container">
      <label htmlFor={id}>{label}</label>
      <input
        className={`h-input ${isError && "input-error"}`}
        type={type}
        id={id}
        value={value}
        name={name || id}
        onChange={onChange}
      />
      {isError && <span className="input-error-msg">{errorMessage}</span>}
    </div>
  );
};

Input.defaultProps = {
  label: "",
  type: "text",
  id: undefined,
  value: undefined,
  isError: false,
  errorMessage: "",
};
