import { useEffect } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useViewPort } from "hooks";
import { checkLocalAuthentication, setShowWebView } from "store/application";
import { useAppDispatch, useAppSelector } from "store";
import { useNavigate } from "react-router-dom";
import NavigationRegion from "./regions/Navigation";
import ContentRegion from "./regions/Content";
import AppTopBar from "./regions/TopBar";

export const HomeLayout = () => {
  const dispatch = useAppDispatch();
  const { showWebView } = useAppSelector((state) => state.application);
  const navigate = useNavigate();
  const viewport = useViewPort();
  const materialUITheme = useTheme();
  const isMediumScreen = useMediaQuery(materialUITheme.breakpoints.up("md"));

  useEffect(() => {
    dispatch(checkLocalAuthentication(navigate));
  }, []);

  useEffect(() => {
    dispatch(setShowWebView(isMediumScreen));
  }, [isMediumScreen]);

  return (
    <Grid
      container
      component="main"
      direction="column"
      alignItems="stretch"
      justifyContent="flex-start"
      bgcolor="#dddae3"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item md={1}>
        <AppTopBar />
      </Grid>
      <Grid
        item
        xs={12}
        md
        container
        direction="row"
        sx={{ overflowY: "auto", overflowX: "auto" }}
      >
        {showWebView && (
          <Grid
            item
            xs={false}
            sm={false}
            minWidth={250}
            width={250}
            maxWidth={250}
          >
            <NavigationRegion />
          </Grid>
        )}
        <Grid item xs>
          <ContentRegion isMediumScreen={isMediumScreen} />
        </Grid>
      </Grid>
    </Grid>
  );
};
