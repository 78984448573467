import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ImageListComp from "components/templates/Home/pages/Profile/components/ImageListComp";

import { useAppSelector } from "store";
import BottomNavigationScreen from "../../component/Screen/BottomNavigationScreen";
import { convertCmToFeetAndInches } from "../../util/formatter";
import Personalinfo from "./components/Personalinfo";
import EducationInfo from "./components/EducationInfo";
import OtherInfo from "./components/OtherInfo";
import FormResponder from "../../component/Button/FormResponder";

const Profile = () => {
  const navigate = useNavigate();
  const { authProfile: authprofile } = useAppSelector(
    (state) => state.application,
  );

  return (
    <BottomNavigationScreen name="profile">
      {authprofile !== null && (
        <Grid container direction="column" alignItems="stretch">
          {authprofile.status === "REVIEW_REJECTED" &&
            authprofile.rejectedReviews.length !== 0 && (
              <Grid
                item
                container
                direction="column"
                rowGap={1}
                padding={1}
                marginY={1}
                bgcolor="#ffb74d"
                borderRadius={2}
              >
                <Typography>
                  Your Profile was not published due to the following reasons.
                  Please update your profile according to the guidelines and
                  submit for review again
                </Typography>
                {authprofile.rejectedReviews.map((review) => (
                  <Grid
                    container
                    direction="row"
                    columnGap={1}
                    alignItems="center"
                  >
                    <Typography variant="body2">
                      {moment(review.createdAt).local().format("YYYY MMM DD")}
                    </Typography>
                    <Typography variant="body2" fontWeight={400}>
                      {review.reason}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            )}
          <Grid item xs={2} sm={5} height={1}>
            <Paper elevation={3} sx={{ marginBottom: "20px" }}>
              <ImageListComp profile={authprofile} />
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{ padding: "0px 12px 0 12px" }}
              >
                {authprofile?.age}{" "}
                <FiberManualRecordIcon sx={{ fontSize: 8 }} />{" "}
                {authprofile?.residentCity}{" "}
                <FiberManualRecordIcon sx={{ fontSize: 8 }} />{" "}
                {authprofile?.profession.name}{" "}
                <FiberManualRecordIcon sx={{ fontSize: 8 }} />{" "}
                {convertCmToFeetAndInches(authprofile.height)}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{ padding: "0px 12px 12px 12px" }}
              >
                Liked you on: 29 March 2022
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={1} mb={2}>
            <Personalinfo profile={authprofile} />
          </Grid>
          <Grid item xs={1} mb={1}>
            <EducationInfo profile={authprofile} />
          </Grid>
          <Grid item xs={2}>
            <OtherInfo profile={authprofile} />
          </Grid>
          <FormResponder
            postiveRespond={{
              label: "Edit Profile",
              handler: () => {
                navigate("/app/edit-profile");
              },
            }}
            containerStyles={{ marginTop: 20 }}
          />
        </Grid>
      )}
    </BottomNavigationScreen>
  );
};

export default Profile;
