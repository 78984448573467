import { Grid, Skeleton } from "@mui/material";

const PageSkeleton = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        paddingBottom={8}
      >
        <Skeleton variant="circular" height={150} width={150} />
        <Skeleton variant="text" width={200} height={40} />
      </Grid>
      <Grid item container direction="column" paddingX={2}>
        {Array.from(Array(5).keys()).map((idx) => (
          <Grid item key={`form-input-skeleton-${idx}`} marginY={1}>
            <Skeleton variant="text" width={100} height={20} />
            <Skeleton variant="rectangular" width="90%" height={60} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default PageSkeleton;
