import { Link } from "react-router-dom";
import messenger from "../../../../assets/images/fb-messenger.png";
import whatsapp from "../../../../assets/images/whatsapp.png";

const Footer = () => {
  return (
    <footer className="section-common__2">
      <div className="container">
        <div className="privacy-and-conditions">
          <Link to="privacy-policy">Privacy Policy</Link>
          <span className="divider" />
          <Link to="terms">Terms of Service</Link>
          <span className="divider" />
          <a
            href={`${process.env.REACT_APP_BLOG_URL}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Blogs
          </a>
        </div>
        <div className="supportive-links">
          <a
            href="https://m.me/hytch"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={messenger}
              alt="messsenger"
              width="18"
              style={{ verticalAlign: "bottom" }}
            />{" "}
            &nbsp; Messenger
          </a>
          <a
            href="https://wa.me/message/3QUCM2ERMXAQM1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={whatsapp}
              alt="whatsapp"
              width="18"
              style={{ verticalAlign: "bottom" }}
            />{" "}
            &nbsp; Whatsapp
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
