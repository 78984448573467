import { Profile } from "models/profile";
import adapter from "services/api/mobile/proxy";

export const getProfileAPI = async (profileId: string) => {
  const response = await adapter.doRequest({
    method: "GET",
    apiVersion: "v1",
    path: `profiles/${profileId}`,
    authenticated: true,
  });
  return response.data;
};

export const updateProfileAPI = async (
  profile: Profile.UpdateProfilePayload,
) => {
  const response = await adapter.doRequest({
    method: "PUT",
    apiVersion: "v1",
    path: `profile`,
    body: profile,
    contentType: "application/json",
    authenticated: true,
  });
  return response;
};

export const deleteProfileImagesAPI = async (imageId: string) => {
  const response = await adapter.doRequest({
    method: "DELETE",
    apiVersion: "v1",
    path: `profile/images/${imageId}`,
    authenticated: true,
  });
  return response.data;
};

type EditRequestPayload = {
  images: String[];
};

export const postEditProfileRequest = async (payload: EditRequestPayload) => {
  const response = await adapter.doRequest({
    method: "POST",
    path: "profile/edit_requests",
    apiVersion: "v1",
    authenticated: true,
    body: payload,
  });
  return response;
};

export const getPendingEditRequestsAPI = async () => {
  const response = await adapter.doRequest({
    method: "GET",
    path: "profile/edit_requests",
    apiVersion: "v1",
    authenticated: true,
  });
  return response;
};
