import { request } from "services/api";
import qs from "qs";

const Payment = {
  getUserAccount: () =>
    request.get<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/account`,
    ),
  getUserProfile: () =>
    request.get<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/profile`,
    ),
  checkoutSession: (month: string | number) => {
    const checkoutData = qs.stringify({
      months: parseInt(month.toString(), 10),
    });

    return request.post<any | null>(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/profile/checkout_session`,
      checkoutData,
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      },
    );
  },
};

export default Payment;
